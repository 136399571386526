<template>
    <div
        class="flex flex-col justify-center items-center text-center w-full h-full main-background relative"
        v-if="$store.state.storeEventData.ingame_state <= 2"
    >
        <h1 class="custom-color font-title text-6xl mb-4">
            {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{ questions.length }}
        </h1>
        <div class="flex flex-col items-center w-full" v-for="(question, index) in questions" :key="question">
            <div
                class="flex flex-col items-center bg-blue-dark rounded-3xl py-4 w-10/12 bg-opacity-80"
                v-if="index == $store.state.storeEventData.counter_game"
            >
                <img
                    class="object-cover max-w-3xl w-9/12 rounded-3xl sm:h-48 xl:h-64 3xl:h-96 mt-4"
                    :src="question.image"
                    alt="image"
                />
                <h2 class="w-11/12 text-3xl mb-3">{{ question.question }}</h2>
                <h4
                    class="w-11/12 my-2"
                    v-if="$store.state.storeEventData.ingame_state == 2 && question.answer_explanation != ''"
                >
                    {{ question.answer_explanation }}
                </h4>
                <hr class="w-11/12 bg-orange-intense border-none h-1" />
                <div
                    class="pb-4 grid gap-2 w-11/12"
                    :class="question.answers.length == 6 ? 'grid-cols-3' : 'grid-cols-2'"
                >
                    <div
                        class="flex flex-row items-center justify-center py-2 rounded-2xl game-btn my-1 relative"
                        :class="
                            answer.answer != $store.state.storeEventData.game_data.correct_anse &&
                            $store.state.storeEventData.ingame_state == 2
                                ? question_style[index].bordercolor
                                : 'border-white text-white ' + question_style[index].bgcolor
                        "
                        v-for="(answer, index) in question.answers"
                        :key="answer"
                    >
                        <div class="w-1/12">
                            <div
                                class="flex flex-col w-6 h-6 rounded-full border-2 border-solid p-1 items-center justify-center"
                                :class="
                                    answer.answer != $store.state.storeEventData.game_data.correct_anse &&
                                    $store.state.storeEventData.ingame_state == 2
                                        ? 'opacity-20'
                                        : 'opactiy-100 border-white text-white' + question_style[index].bgcolor
                                "
                            >
                                <span class="">{{ question_style[index].letter }}</span>
                            </div>
                        </div>
                        <div
                            class="w-11/12 text-left text-lg ml-4 flex flex-row"
                            :class="
                                answer.answer != $store.state.storeEventData.game_data.correct_anse &&
                                $store.state.storeEventData.ingame_state == 2
                                    ? 'opacity-20'
                                    : 'opactiy-100 border-white text-white' + question_style[index].bgcolor
                            "
                        >
                            <p>
                                {{ answer.answer }}
                            </p>
                            <p v-if="$store.state.storeEventData.ingame_state == 2" class="ml-auto mr-2">
                                {{ $store.getters.getQuizResult(answer.id) }} %
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <dir v-else-if="$store.state.storeEventData.ingame_state == 3" class="w-full h-full flex flex-col justify-center">
        <div class="flex flex-col items-center justify-center bg-blue-dark rounded-3xl w-11/12 py-6 bg-opacity-80">
            <div class="flex flex-row w-full">
                <div class="flex flex-col justify-center w-6/12">
                    <MainRankingFinal></MainRankingFinal>
                </div>
                <div class="flex flex-col justify-center w-6/12">
                    <MainRankingFinal :points="'Game'"></MainRankingFinal>
                </div>
            </div>
        </div>
    </dir>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Timer from "@/components/Timer.vue";
import MainRankingFinal from "@/components/MainRankingFinal.vue";

export default {
    props: {},
    components: { Timer, MainRankingFinal },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        /*const questions = ref(require("../../assets/dummydata/games.json"));
        questions.value = questions.value.quiz;*/
        const question_style = [
            { letter: "A", bgcolor: "bg-quiz-red", bordercolor: "border-quiz-red" },
            { letter: "B", bgcolor: "bg-quiz-green", bordercolor: "border-quiz-green" },
            { letter: "C", bgcolor: "bg-quiz-blue", bordercolor: "border-quiz-blue" },
            { letter: "D", bgcolor: "bg-quiz-yellow", bordercolor: "border-quiz-yellow" },
            { letter: "E", bgcolor: "bg-quiz-purple", bordercolor: "border-quiz-purple" },
            { letter: "F", bgcolor: "bg-quiz-orange", bordercolor: "border-quiz-orange" },
        ];
        const questions = ref(store.state.storeEventData.shared_content);

        return { questions, question_style };
    },
};
</script>

<style scoped></style>

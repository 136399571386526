<template>
    <span class="flex flex-col items-center justify-start w-full mt-20">
        <div class="flex flex-row fixed top-0 gap-6 items-center justify-center pb-2 w-full bg-gray-dark rounded-b-xl">
            <div
                @click="$emit('setCollapse')"
                class="flex flex-row cursor-pointer items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
            >
                <inline-svg
                    :src="require('@/assets/icons/left-long-solid.svg')"
                    class="w-6 h-6 fill-orange-intense mr-2 -z-10"
                />
                <span>Back</span>
            </div>
            <h1 class="font-title text-orange-intense">Game Content Data</h1>
            <div
                @click="addQuestion()"
                class="flex flex-row cursor-pointer items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
            >
                <span>Add Question</span>
                <inline-svg
                    :src="require('@/assets/icons/plus-solid.svg')"
                    class="w-6 h-6 fill-orange-intense ml-2 -z-10"
                />
            </div>
            <div
                @click="saveGameInformation()"
                class="flex flex-row cursor-pointer items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
            >
                <span>Save</span>
                <inline-svg
                    :src="require('@/assets/icons/floppy-disk-solid.svg')"
                    class="w-6 h-6 fill-orange-intense ml-2 -z-10"
                />
            </div>
            <div
                class="flex flex-row text-green-400 items-center justify-center gap-2"
                v-if="$store.state.storeContentData.dataContent.validated"
            >
                <img class="h-10 cursor-pointer" src="@/assets/icons/icons8-ok-96.png" alt="" />
                <span>Validated</span>
            </div>
            <div class="flex flex-row text-red-500 items-center justify-center gap-2" v-else>
                <img class="h-10 cursor-pointer" src="@/assets/icons/icons8-cancel-96.png" alt="" />
                <span>Not validated</span>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center w-full my-10" v-if="game_selected != ''">
            <ul class="flex flex-col gap-6 w-11/12 no-bullets h-full scroll-auto">
                <li v-for="(question, index) in contentData" :key="question.id" class="bg-blue-dark p-4 rounded-xl">
                    <div class="flex flex-row w-full cursor-pointer">
                        <div class="flex flex-col items-start justify-center" @click="toggleRow(question.id)">
                            <inline-svg
                                v-if="!question.toggled"
                                :src="require('@/assets/icons/chevron-down-solid.svg')"
                                class="w-10 h-10 fill-orange-intense cursor-pointer"
                            />
                            <inline-svg
                                :src="require('@/assets/icons/chevron-right-solid.svg')"
                                class="w-10 h-10 fill-orange-intense cursor-pointer"
                                v-else
                            />
                        </div>
                        <div
                            class="flex flex-col items-start justify-center w-full ml-4 font-title text-2xl"
                            @click="toggleRow(question.id)"
                        >
                            <span
                                >{{ index + 1 }}.
                                {{
                                    typeof $store.state.storeContentData.dataContent.data[index] !== "undefined"
                                        ? $store.state.storeContentData.dataContent.data[index].question
                                        : ""
                                }}</span
                            >
                        </div>
                        <div class="flex flex-row items-end justify-center ml-auto">
                            <inline-svg
                                :src="require('@/assets/icons/trash-solid.svg')"
                                class="w-6 h-6 ml-auto cursor-pointer my-auto fill-orange-intense"
                                v-on:click="deleteQuestion(question.id)"
                            />
                        </div>
                    </div>
                    <Quiz
                        :question_data="question"
                        :content_images="header_data.content_images"
                        v-if="!question.toggled && game_selected == 'quiz'"
                    />
                    <FairPrice
                        :question_data="question"
                        :content_images="header_data.content_images"
                        v-if="!question.toggled && game_selected == 'fair_price'"
                    />
                    <Timeline
                        :question_data="question"
                        :content_images="header_data.content_images"
                        v-if="!question.toggled && game_selected == 'timeline'"
                    />
                </li>
            </ul>
        </div>
    </span>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import Quiz from "@/components/dashboard/forms/Quiz.vue";
import FairPrice from "@/components/dashboard/forms/FairPrice.vue";
import Timeline from "@/components/dashboard/forms/Timeline.vue";
import { v4 as uuidv4 } from "uuid";
import { notify } from "notiwind";

export default {
    props: {
        game_selected: { type: String, default: "" },
    },
    components: { Quiz, FairPrice, Timeline },
    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload = process.env.VUE_APP_API_URL + "gamecontent/content_game/header_image";
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const header_data = store.state.storeContentData.dataContent;

        const contentData = ref(store.state.storeContentData.dataContent.data);

        const generateRow = () => {
            if (props.game_selected == "quiz") {
                return {
                    id: uuidv4(),
                    others: [""],
                    correct: "",
                    question: "",
                    toggled: false,
                };
            } else if (props.game_selected == "fair_price") {
                return {
                    id: uuidv4(),
                    correct: "",
                    interval: "",
                    question: "",
                    max_slider: "",
                    min_slider: "",
                };
            } else if (props.game_selected == "timeline") {
                return {
                    id: uuidv4(),
                    question: "",
                    order: [
                        {
                            id: uuidv4(),
                            name: "",
                        },
                        {
                            id: uuidv4(),
                            name: "",
                        },
                        {
                            id: uuidv4(),
                            name: "",
                        },
                        {
                            id: uuidv4(),
                            name: "",
                        },
                    ],
                };
            }
        };

        const addQuestion = () => {
            console.log(contentData.value);
            if (!Array.isArray(contentData.value)) {
                contentData.value = [];
            }
            contentData.value.push(generateRow());
            console.log(contentData.value);
        };

        const deleteQuestion = (id) => {
            if (contentData.value.length > 1) {
                contentData.value.splice(contentData.value.indexOf(contentData.value.find((el) => el.id == id)), 1);
            }
        };

        const addAnswer = (id) => {
            contentData.value.find((el) => el.id == id).others.push("");
        };
        const deleteAnswer = (id) => {
            var index = contentData.value.indexOf((el) => el.id == id);
            if (index > -1) {
                contentData.value.splice(index, 1);
            }
        };

        const toggleRow = (id) => {
            console.log(contentData.value);
            contentData.value.find((el) => el.id == id).toggled = !contentData.value.find((el) => el.id == id).toggled;
        };

        const saveGameInformation = async () => {
            const contentToSave = new FormData();
            contentToSave.append("data", JSON.stringify(contentData.value));
            await axiosToken({
                method: "put",
                url: "gamecontent/content_game/" + route.params.id,
                body: contentToSave,
                authorization: true,
            }).then((response) => {
                if (response.data) {
                    store.commit("loadContentData", response.data.id);
                    notify(
                        {
                            group: "success",
                            title: "Success",
                            text: "Content updated",
                        },
                        4000
                    );
                } else {
                    notify(
                        {
                            group: "error",
                            title: "Error",
                            text: "There was a updating the content!!",
                        },
                        4000
                    );
                }
            });
        };

        const setImage = (id, response) => {
            if (props.content_images.find((el) => (el.key = id))) {
                props.content_images.find((el) => (el.key = id)).img = response.img;
            } else {
                props.content_images.push(response);
            }
        };

        const getImage = (id) => {
            if (props.content_images.find((e) => e.key == id)) {
                return props.content_images.find((e) => e.key == id).img;
            } else {
                return "";
            }
        };

        onMounted(() => {
            if (!store.state.storeContentData.dataContent.data.length > 0) {
                addQuestion();
            }
        });

        return {
            contentData,
            generateRow,
            urlUpload,
            Token,
            addAnswer,
            deleteAnswer,
            toggleRow,
            addQuestion,
            deleteQuestion,
            saveGameInformation,
            getImage,
            setImage,
            header_data,
        };
    },
};
</script>

<style scoped>
ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}
/*img {
    image-rendering: pixelated;
}*/
</style>

<template>
    <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        appear
        mode="out-in"
        :key="$store.state.storeEventData.ingame_state"
    >
        <span class="flex flex-row items-center justify-center h-full w-full relative">
            <span class="absolute top-24 right-24 z-50"
                ><Timer2
                    :timeLeft="$store.getters.getTimer"
                    :initTime="$store.getters.getTotalTimer"
                    :lastTime="$store.state.storeEventData.start_timer"
                    :type="'circle'"
            /></span>
            <!--<span class="absolute bottom-0 left-5"><Booster /></span>-->
            <component id="main-game-manager" :is="comp"></component>
        </span>
    </transition>
</template>

<script>
const components = ["Quiz", "FairPrice", "Timeline"];
const gameIndex = ["quiz", "fair_price", "timeline"];
import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Quiz from "@/components/games/MainQuiz.vue";
import FairPrice from "@/components/games/MainFairPrice.vue";
import Timeline from "@/components/games/MainTimeline.vue";
import Timer2 from "@/components/Timer2.vue";
import Booster from "@/components/Booster.vue";

export default {
    props: {},
    components: { Quiz, FairPrice, Timeline, Timer2, Booster },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const componentSelected = ref(gameIndex.indexOf(store.state.storeEventData.current_game.name_id));
        const comp = components[componentSelected.value];

        onMounted(() => {
            console.log("store.state.storeEventData.current_game_content.background");
            console.log(store.state.storeEventData.current_game_content.background);
            if (
                store.state.storeEventData.current_game_content.background ||
                store.state.storeEventData.current_game.background
            ) {
                document.documentElement.style.setProperty(
                    "--bg-game-image",
                    store.state.storeEventData.current_game_content.background
                        ? "url(" + store.state.storeEventData.current_game_content.background + ")"
                        : "url(" + store.state.storeEventData.current_game.background + ")"
                );
            }
        });

        /*
        FULLSCREEN CODE
        
        onMounted(() => {
            // element which needs to enter full-screen mode
            var element = document.querySelector("#game-manager");

            // make the element go to full-screen mode
            element
                .requestFullscreen()
                .then(function () {
                    // element has entered fullscreen mode successfully
                })
                .catch(function (error) {
                    // element could not enter fullscreen mode
                });
        });*/
        return { componentSelected, comp };
    },
};
</script>

<style scoped></style>

<template>
    <div class="flex flex-col items-center justify-center">
        <div>Active Modifiers</div>
        <div class="rounded-full border-2 border-solid border-gray-dark h-52 w-52 bg-blue-dark"></div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped></style>

<template>
    <span>
        <div
            v-for="reaction in $store.state.storeEventData.reactionsSend"
            :key="reaction"
            class="fix hidden md:inline"
            :style="'left:' + reaction.place + '%'"
            id="float"
        >
            <img class="reaction" :src="$store.getters.getReactionById(reaction.id).image" alt="" />
        </div>
    </span>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.fix {
    position: fixed;
    left: 50%;
    bottom: 0px;
}

#float {
    -webkit-animation: floatBubble 3s infinite normal ease-out;
    animation: floatBubble 3s infinite normal ease-out;
}
@-webkit-keyframes floatBubble {
    0% {
        top: 100%;
        opacity: 1;
    }
    100% {
        top: 35%;
        opacity: 0;
    }
}
@keyframes floatBubble {
    0% {
        top: 100%;
        opacity: 1;
    }
    100% {
        top: 35%;
        opacity: 0;
    }
}
</style>

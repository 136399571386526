<template>
    <div class="flex flex-col">
        <div class="star-rating">
            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-j' + name" value="10" />
            <label :for="'star-j' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-i' + name" value="9" />
            <label :for="'star-i' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-h' + name" value="8" />
            <label :for="'star-h' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-g' + name" value="7" />
            <label :for="'star-g' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-f' + name" value="6" />
            <label :for="'star-f' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-e' + name" value="5" />
            <label :for="'star-e' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-d' + name" value="4" />
            <label :for="'star-d' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-c' + name" value="3" />
            <label :for="'star-c' + name"></label>

            <input type="radio" :name="name" @change="changeRate($event)" :id="'star-b' + name" value="2" />
            <label :for="'star-b' + name"></label>

            <input
                type="radio"
                checked="true"
                :name="name"
                @change="changeRate($event)"
                :id="'star-a' + name"
                value="1"
            />
            <label :for="'star-a' + name"></label>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {
        name: { type: String, default: "stars" },
    },
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        const changeRate = (event) => {
            store.commit("setRating", { name: props.name, value: parseInt(event.target.value) });
        };

        return { changeRate };
    },
};
</script>

<style scoped>
.star-rating {
    display: flex;
    align-items: center;
    width: 240px;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
/* hide the inputs */
.star-rating input {
    display: none;
}
/* set properties of all labels */
.star-rating > label {
    width: 30px;
    height: 30px;
    font-family: Arial;
    font-size: 30px;
    transition: 0.2s ease;
    color: #ffa724;
}
/* give label a hover state */
.star-rating label:hover {
    color: #ff6d24;
    transition: 0.2s ease;
}
.star-rating label:active::before {
    transform: scale(1.1);
}

/* set shape of unselected label */
.star-rating label::before {
    content: "\2606";
    position: absolute;
    top: 0px;
    line-height: 26px;
}
/* set full star shape for checked label and those that come after it */
.star-rating input:checked ~ label:before {
    content: "\2605";
}

@-moz-document url-prefix() {
    .star-rating input:checked ~ label:before {
        font-size: 36px;
        line-height: 21px;
    }
}
</style>

<template>
    <transition name="fade">
        <div v-if="isModalVisible" class="w-full h-full absolute bg-black bg-opacity-70 inset-0 z-0">
            <div class="w-full max-w-lg p-3 mx-auto my-auto rounded-xl shadow-lg background-div center opacity-100">
                <div>
                    <slot> </slot>
                    <div class="p-3 mt-2 text-center space-x-4 md:block">
                        <button
                            v-on:click="isModalVisibleEdit(false)"
                            class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100"
                        >
                            Close
                        </button>
                        <button
                            class="mb-locationName2 md:mb-0 bg-blue-700 border border-blue-700 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-md hover:shadow-lg hover:bg-blue-800"
                            v-on:click="
                                isModalVisibleEdit(false);
                                saveFunction();
                            "
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        isModalVisible: Boolean,
        isModalVisibleEdit: Function,
        saveFunction: Function,
    },
    components: {},
    setup(props) {},
};
</script>

<style scoped>
.center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.background-div {
    background-color: #5c6067;
}
</style>

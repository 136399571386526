<template>
    <div class="flex flex-col items-center justify-center my-4">
        <div class="flex flex-col rounded-xl items-center bg-silver-light text-center w-10/12">
            <img
                class="object-cover w-full h-36 3xl:h-48"
                :src="element.image"
                alt="image"
                :class="element.title ? 'rounded-t-xl' : 'rounded-xl h-48'"
            />
            <div class="div-title flex flex-col items-center text-xl w-full justify-center rounded-b-xl">
                <div v-if="element.title != ''" class="font-title-btn p-2 font-title text-lg">{{ element.title }}</div>
                <div v-if="correct >= 0" class="font-title-btn p-2 font-title text-lg">
                    {{ correct }}&nbsp;{{ $t("timeline.guessed") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        element: { type: Object, default: {} },
        correct: { type: Number, default: -1 },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
.div-title {
    background-color: #26282b;
}
</style>

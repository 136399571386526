<template>
    <div class="flex flex-col justify-start min-h-screen">
        <div class="flex flex-col justify-center items-center h-full">
            <h1 class="font-title text-orange-intense text-6xl">Common Sense events</h1>
            <div class="flex flex-row font-title gap-2 items-start justify-start w-10/12">
                <div
                    class="flex flex-col pt-3 px-6 border-white border-solid border-b-0 rounded-t-xl cursor-pointer"
                    :class="tab == 0 ? 'bg-orange-intense' : ''"
                    @click="setTab(0)"
                >
                    Events
                </div>
                <div
                    class="flex flex-col pt-3 px-6 border-white border-solid border-b-0 rounded-t-xl cursor-pointer"
                    :class="tab == 1 ? 'bg-orange-intense' : ''"
                    @click="setTab(1)"
                >
                    Themes
                </div>
                <div
                    class="flex flex-col pt-3 px-6 border-white border-solid border-b-0 rounded-t-xl cursor-pointer"
                    :class="tab == 2 ? 'bg-orange-intense' : ''"
                    @click="setTab(2)"
                >
                    Content
                </div>
            </div>
            <hr class="w-10/12 mt-0 bg-white" />
            <div class="flex flex-col w-full items-center justify-center relative">
                <GridEvents v-if="tab == 0" />
                <GridThemes v-if="tab == 1" />
                <GridContent v-if="tab == 2" />
            </div>
        </div>
    </div>
</template>

<script>
const components = ["Scoreboard", "Quiz", "FairPrice", "Timeline"];

import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import GridEvents from "@/components/dashboard/grids/GridEvents.vue";
import GridThemes from "@/components/dashboard/grids/GridThemes.vue";
import GridContent from "@/components/dashboard/grids/GridContent.vue";

export default {
    props: {},
    components: { GridEvents, GridThemes, GridContent },
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const tab = ref(0);

        const setTab = (num) => {
            tab.value = num;
            localStorage.tab = num;
        };

        onMounted(() => {
            if (localStorage.tab) {
                tab.value = localStorage.tab;
            }
        });

        return { tab, setTab };
    },
};
</script>

<style scoped></style>

<template>
    <div class="flex flex-col h-full w-full relative bottom-4 right-0">
        <h1 class="flex flex-col items-center custom-color">
            <template v-if="points == 'Total'">
                {{ $t("ranking.title") }}
            </template>
            <template v-else-if="points == 'Game'">
                {{ $t("ranking.title_partial") }}
            </template>
        </h1>
        <div class="flex flex-col items-center justify-end gap-6">
            <template v-if="points == 'Total'">
                <div
                    class="flex flex-row gap-2 w-11/12"
                    v-for="(team, index) in $store.state.storeEventData.event_team_points_sorted.slice(0, 5)"
                    :key="team"
                >
                    <RankingCard :custom_color="true" :team="team" :position="index + 1" :points="points" />
                </div>
            </template>
            <template v-else-if="points == 'Game'">
                <div
                    class="flex flex-row gap-2 w-11/12"
                    v-for="(team, index) in $store.state.storeEventData.event_team_points_game_sorted.slice(0, 5)"
                    :key="team"
                >
                    <RankingCard :custom_color="true" :team="team" :position="index + 1" :points="points" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, defineAsyncComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import RankingCard from "@/components/cards/RankingCard.vue";

export default {
    props: {
        points: { type: String, default: "Total" },
    },
    components: { RankingCard },
    setup(props) {},
};
</script>

<style scoped>
.div-ranking {
    background: #0d1015;
    border: 1px solid #2d303c;
    border-radius: 10px;

    @apply pr-2;
}
</style>

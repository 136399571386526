<template>
    <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        appear
        mode="out-in"
    >
        <div class="flex flex-col items-center justify-start h-full relative">
            <h1 class="text-orange-intense font-title">{{ $t("ranking.title") }}</h1>
            <div
                class="flex flex-row gap-2 w-11/12"
                v-if="
                    $store.state.storeEventData.event_team_points_sorted.findIndex((team) => {
                        return team.id == $store.state.storeEventData.playerId.team;
                    }) != 0
                "
            >
                <RankingCard
                    :team="
                        $store.state.storeEventData.event_team_points_sorted.find(
                            (t) => t.id == $store.state.storeEventData.playerId.team
                        )
                    "
                    :position="
                        $store.state.storeEventData.event_team_points_sorted.findIndex((team) => {
                            return team.id == $store.state.storeEventData.playerId.team;
                        }) + 1
                    "
                />
            </div>
            <hr
                class="w-11/12 my-4"
                v-if="
                    $store.state.storeEventData.event_team_points_sorted.findIndex((team) => {
                        return team.id == $store.state.storeEventData.playerId.team;
                    }) != 0
                "
            />
            <div class="flex flex-col h-full w-full items-center justify-start overflow-auto gap-6">
                <div
                    class="flex flex-row gap-2 w-11/12"
                    v-for="(team, index) in $store.state.storeEventData.event_team_points_sorted"
                    :key="team"
                >
                    <RankingCard
                        :class="index == 0 ? 'mt-2' : ''"
                        :team="team"
                        :position="index + 1"
                        :highlight="true"
                    />
                </div>
            </div>
            <Reactions class="mt-auto" />
        </div>
    </transition>
</template>

<script>
import { ref, onMounted, onUnmounted, defineAsyncComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Reactions from "@/components/misc/Reactions.vue";
import RankingCard from "@/components/cards/RankingCard.vue";

export default {
    props: {},
    components: { Reactions, RankingCard },
    setup(props) {},
};
</script>

<style scoped>
.div-ranking {
    background: #0d1015;
    border: 1px solid #2d303c;
    border-radius: 10px;

    @apply pr-2;
}
.team-player {
}
</style>

<template>
    <div class="base-timer timer" v-if="type == 'circle'">
        <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
                <!-- <circle class="base-timer__path-elapsed" cx="50" cy="50" r="46.5" /> -->
                <path
                    v-if="currentTime > 0"
                    class="base-timer__path-remaining"
                    :stroke-dasharray="circleDasharray()"
                    d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
                ></path>
            </g>
        </svg>
        <span v-if="currentTime > 0" class="base-timer__label"> {{ formattedTimeLeft() }} </span>
        <inline-svg
            v-else
            :src="require('@/assets/icons/loading_circle.svg')"
            class="bg-transparent"
            v-on:click="removeLocation(loc.id)"
        />
    </div>
    <div v-if="type == 'simple'">
        <div class="flex flex-col p-1 bg-orange-intense font-title rounded-xl w-24 h-10">
            <p class="mt-0 mx-auto" v-if="currentTime > 0">
                {{ formattedTimeLeft() }}
            </p>
            <inline-svg
                v-else
                :src="require('@/assets/icons/loading.svg')"
                class="bg-transparent h-12 w-12 rotate-90 top-0 left-2"
                v-on:click="removeLocation(loc.id)"
            />
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {
        timeLeft: {
            type: Number,
            required: true,
        },
        initTime: {
            type: Number,
            required: true,
        },
        lastTime: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
    },
    components: {},
    setup(props) {
        const currentTime = ref();
        const formattedTimeLeft = () => {
            if (currentTime.value < 0) {
                return "0:00";
            }
            const minutes = Math.floor(currentTime.value / 60);

            let seconds = currentTime.value % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            // The output in MM:SS format
            console.log(`${minutes}:${seconds}`);

            return `${minutes}:${seconds}`;
        };

        const circleDasharray = () => {
            return `${(timeFraction() * 283).toFixed(0)} 283`;
        };

        const timeFraction = () => {
            const rawTimeFraction = currentTime.value / props.initTime;

            return rawTimeFraction - (1 / props.initTime) * (1 - rawTimeFraction);
        };
        watch(
            () => props.lastTime,
            () => {
                console.log("changinng");
                currentTime.value = props.timeLeft;
            }
        );
        onMounted(() => {
            currentTime.value = props.timeLeft;
            setInterval(
                () => {
                    if (currentTime.value > 0) {
                        currentTime.value -= 1;
                    }
                },

                1000
            );
        });

        return {
            formattedTimeLeft,
            circleDasharray,
            currentTime,
        };
    },
};
</script>

<style lang="scss" scoped>
svg {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-90deg);
    .circle {
        stroke: #eba300;
        stroke-width: 12;
        fill: transparent;
        stroke-dashoffset: 500;
        stroke-dasharray: 0;
        animation: v-bind(animationTime) circletimer linear;
    }
}
.timer {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 42px;
    font-weight: 700;
}
/* Sets the containers height and width */
.base-timer {
    width: 300px;
    height: 300px;

    /* Removes SVG styling that would hide the time label */
    &__circle {
        fill: none;
        stroke: none;
    }

    /* The SVG path that displays the timer's progress */
    &__path-elapsed {
        stroke-width: 7px;
        stroke: grey;
    }
    &__label {
        position: absolute;

        /* Size should match the parent container */
        width: 300px;
        height: 300px;

        /* Keep the label aligned to the top */
        top: 0;

        /* Create a flexible box that centers content vertically and horizontally */
        display: flex;
        align-items: center;
        justify-content: center;

        /* Sort of an arbitrary number; adjust to your liking */
        font-size: 48px;
    }
    &__path-remaining {
        /* Just as thick as the original ring */
        stroke-width: 7px;

        /* Rounds the line endings to create a seamless circle */
        stroke-linecap: round;

        /* Makes sure the animation starts at the top of the circle */
        transform: rotate(90deg);
        transform-origin: center;

        /* One second aligns with the speed of the countdown timer */
        transition: 1s linear all;

        /* Allows the ring to change color when the color value updates */
        stroke: #eba300; // green
    }
}
</style>

<template>
    <h4>
        {{ $store.state.storeEventData.current_game.name_id }}
    </h4>
    <h4 v-if="$store.state.storeEventData.current_boost">
        {{ $store.state.storeEventData.current_boost }}
    </h4>
    <p>Next time: {{ nextPhaseClock }}</p>
    <div v-if="$store.state.storeEventData.current_game.name_id == 'quiz'">
        <p>Ronda : {{ $store.state.storeEventData.counter_game }}</p>
        <p v-if="$store.state.storeEventData.ingame_state == 1">Question</p>
        <p v-if="$store.state.storeEventData.ingame_state == 2">Show answer</p>
        <p v-if="$store.state.storeEventData.ingame_state == 3">Result</p>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
    props: {},
    components: {},
    setup(props) {
        const store = useStore();
        const finishTime = computed(() => store.state.storeEventData.end_timer);
        const nextPhase = ref();
        const nextPhaseClock = ref("");
        onMounted(() => {
            const now = new Date();
            const finishTimeDate = new Date(finishTime.value);
            console.log(finishTimeDate);
            nextPhase.value = finishTimeDate.getTime() - now.getTime();
            updateClock(nextPhase.value);
            setTimeout(updateTimer, 1000);
        });

        watch(finishTime, () => {
            const now = new Date();
            const finishTimeDate = new Date(finishTime.value);
            console.log(finishTimeDate);
            nextPhase.value = finishTimeDate.getTime() - now.getTime();
            updateClock(nextPhase.value);
            setTimeout(updateTimer, 1000);
        });

        const updateClock = (ms) => {
            if (ms <= 0) {
                nextPhaseClock.value = "00:00";
            } else {
                nextPhaseClock.value = new Date(ms).toISOString().slice(14, 19);
            }
        };

        const updateTimer = () => {
            nextPhase.value = nextPhase.value - 1000;
            updateClock(nextPhase.value);
            if (nextPhase.value > 1000) {
                setTimeout(updateTimer, 1000);
            }
        };

        return {
            nextPhaseClock,
        };
    },
};
</script>

<style></style>

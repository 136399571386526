<template>
    <div class="flex flex-col gap-2 text-center items-center w-full justify-center h-full">
        <h2 class="text-orange-intense">Avatar</h2>
        <div class="flex flex-row w-full items-center justify-center" v-if="avatars.length > 0">
            <inline-svg
                :src="require('@/assets/icons/circle-arrow-left-solid.svg')"
                class="cursor-pointer w-1/12 fill-orange-intense"
                v-on:click="nextIcon('left')"
            />
            <div class="w-3/12 rounded-full items-center flex flex-col aspect-square mx-6">
                <img :src="avatars[selectAvatar].image" alt="" class="w-full h-full" />
            </div>
            <inline-svg
                :src="require('@/assets/icons/circle-arrow-right-solid.svg')"
                class="cursor-pointer w-1/12 fill-orange-intense"
                v-on:click="nextIcon('right')"
            />
        </div>
    </div>
</template>

<script>
import { watch, ref, onMounted, onUnmounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import Reactions from "@/components/misc/Reactions.vue";

export default {
    props: {},
    components: { Reactions },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const avatars = ref([]);
        const selectAvatar = computed(() => store.state.storeEventData.selectedAvatar);
        const nextIcon = (arrow) => {
            if (arrow == "left") {
                if (selectAvatar.value == 0) {
                    store.commit("setAvatar", avatars.value.length - 1);
                } else {
                    store.commit("setAvatar", selectAvatar.value - 1);
                }
            }
            if (arrow == "right") {
                if (selectAvatar.value == avatars.value.length - 1) {
                    store.commit("setAvatar", 0);
                } else {
                    store.commit("setAvatar", selectAvatar.value + 1);
                }
            }
        };

        onMounted(() => {
            fetchAvatar();
        });

        const fetchAvatar = async () => {
            const avatarData = await axiosToken({
                method: "get",
                url: "event/avatar/" + store.state.storeEventData.url,
            });
            console.log("avatarData");
            console.log(avatarData);
            store.commit("setAvatarArray", avatarData.data);
            avatars.value = avatarData.data;
        };
        return { nextIcon, selectAvatar, avatars };
    },
};
</script>

<style scoped></style>

<template>
    <div class="">
        <GameManager />
    </div>
</template>

<script>
// @ is an alias to /src

import GameManager from "@/components/GameManager.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

export default {
    name: "GameView",
    components: {
        GameManager,
    },
    setup(props) {
        const router = useRouter();

        onMounted(() => {});

        return {};
    },
};
</script>

<template>
    <div class="flex flex-col">
        <div class="flex flex-row box-container">
            <img
                class="rounded-xl hidden lg:flex absolute top-0 left-0 ml-2 mt-2 z-50 w-48"
                :src="$store.state.storeEventData.theme.logo_no_bg"
                alt="logo"
            />
            <transition
                enter-active-class="animate__animated animate__fadeInUp"
                leave-active-class="animate__animated animate__fadeOutDown"
                appear
                mode="out-in"
                :key="setAnimationMain($store.state.storeEventData.game_state)"
            >
                <div
                    class="hidden lg:flex relative flex-col justify-center items-center bg-no-repeat bg-cover overflow-y-auto overflow-x-hidden"
                    :style="{ 'background-image': 'url(' + $store.state.storeEventData.theme.main_background + ')' }"
                    :class="$store.state.storeEventData.gmView ? ' w-full' : ' w-9/12'"
                >
                    <MainSurvey v-if="$store.state.storeEventData.event_state == 3" />
                    <template
                        v-else-if="
                            $store.state.storeEventData.game_state <= 1 ||
                            $store.state.storeEventData.game_state == 4 ||
                            !$store.state.storeEventData.playerId
                        "
                    >
                        <Wheel />
                        <TinyWheel />
                        <MainRanking
                            class="flex flex-col"
                            v-if="
                                $store.state.storeEventData.event_state >= 1 &&
                                ($store.state.storeEventData.game_state <= 1 ||
                                    $store.state.storeEventData.game_state == 4) &&
                                $store.state.storeEventData.playerId
                            "
                        />
                    </template>

                    <MainGameRules v-else-if="$store.state.storeEventData.game_state == 2" />
                    <MainGameManager v-else-if="$store.state.storeEventData.game_state == 3" />
                </div>
            </transition>
            <transition
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                appear
                mode="out-in"
            >
                <div
                    class="flex flex-col w-full lg:w-3/12 border-2 gap-2 overflow-y-auto relative"
                    v-if="!$store.state.storeEventData.gmView"
                >
                    <Login
                        v-if="$store.state.storeEventData.event_state == 0 || !$store.state.storeEventData.playerId"
                    />
                    <Team v-else-if="$store.state.storeEventData.event_state == 1" />
                    <GameRules
                        v-else-if="
                            $store.state.storeEventData.event_state == 2 && $store.state.storeEventData.game_state == 2
                        "
                    />
                    <GameManager
                        v-else-if="
                            $store.state.storeEventData.event_state == 2 &&
                            $store.state.storeEventData.game_state == 3 &&
                            $store.state.storeEventData.ingame_state <= 2
                        "
                    />
                    <Ranking
                        v-else-if="
                            $store.state.storeEventData.event_state == 2 &&
                            ($store.state.storeEventData.game_state == 4 || $store.state.storeEventData.game_state <= 1)
                        "
                    />
                    <Ranking v-else-if="$store.state.storeEventData.ingame_state == 3" />
                    <Survey v-else-if="$store.state.storeEventData.event_state == 3" />
                    <PointsAnimation />
                </div>
            </transition>
        </div>
        <MainCSS />
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http.js";
import Wheel from "@/components/wheels/Wheel.vue";
import TinyWheel from "@/components/wheels/tinyWheel.vue";
import Login from "@/components/Login.vue";
import Team from "@/components/Team.vue";
import GameRules from "@/components/GameRules.vue";
import MainGameRules from "@/components/MainGameRules.vue";
import GameManager from "@/components/GameManager.vue";
import MainGameManager from "@/components/MainGameManager.vue";
import Ranking from "@/components/Ranking.vue";
import MainCSS from "@/components/MainCSS.vue";
import MainRanking from "@/components/MainRanking.vue";
import Survey from "@/components/Survey.vue";
import MainSurvey from "@/components/MainSurvey.vue";
import { startSocketEvent } from "@/libs/ws.js";
import PointsAnimation from "@/components/misc/PointsAnimation.vue";
import { useI18n } from "vue-i18n";
export default {
    props: {},
    components: {
        TinyWheel,
        Wheel,
        Login,
        GameRules,
        Team,
        GameManager,
        Ranking,
        MainRanking,
        MainGameManager,
        MainGameRules,
        PointsAnimation,
        MainCSS,
        Survey,
        MainSurvey,
    },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const state = ref(0);
        const { locale } = useI18n();

        const changeState = () => {
            console.log(state);
            store.commit("setEventState", state.value);
            if (state.value == 1) {
                // startSocketEvent(localStorage.player_id, store.state.storeEventData.url);
            }
        };

        onMounted(() => {
            locale.value = store.state.storeEventData.language;
            if (localStorage.player_id != null) {
                console.log(store.state.storeEventData.url, localStorage.player_id);
                startSocketEvent(localStorage.player_id, store.state.storeEventData.url);
            }
        });

        const setAnimationMain = (id) => {
            if (id == 0 || id == 1) {
                return 0;
            }
            return id;
        };

        return { changeState, state, setAnimationMain };
    },
};
</script>

<style scoped>
.box-container {
    height: 100vh;
}
</style>

<template>
    <div class="flex flex-col tiny-wheel fixed">
        <!--<button id="spin">Party Games</button>-->
        <div class="flex flex-col relative justify-center items-center">
            <div class="flex flex-col tiny-container-2">
                <div
                    class="flex flex-col absolute w-full h-full justify-center items-center"
                    v-for="portion in wheelPortions"
                    :key="portion"
                    :class="'portion-' + portion.id"
                >
                    <span class="flex flex-col absolute top-1 bg-white point"></span>
                </div>
            </div>
            <img
                id="tiny-arrow"
                class="tiny-arrow flex flex-col fixed z-50 w-16 h-16"
                :src="require('@/assets/icons/trianglet_gran.svg')"
                alt="vergvrth"
            />

            <div class="tiny-container cursor-pointer">
                <div
                    class="tiny-container-div"
                    v-for="portion in wheelPortions"
                    :key="portion"
                    :class="'portion-' + portion.id"
                >
                    <img class="tiny-container-div-img w-8/12" :src="portion.img" alt="" />
                    <!--<span class="tiny-container-div-span">{{ portion.name }}</span>-->
                    <inline-svg
                        :src="require('@/assets/images/tros_ruleta.svg')"
                        class="w-10/12 fill-silver-light fixed mt-2 -z-10"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
    name: "HomeView",
    components: {
        Home,
    },

    setup(props) {
        const router = useRouter();
        const store = useStore();
        let currentPostion = 0;
        let currentArrayPos = 0;

        const wheelPortions = [
            { id: 1, name_id: "no_boost", name: "No Boost", img: require("../../assets/icons/no.svg") },
            {
                id: 2,
                name_id: "double_points",
                name: "Double Points",
                img: require("../../assets/icons/x2_score.svg"),
            },
            { id: 3, name_id: "half_time", name: "Half Time", img: require("../../assets/icons/time_fast.svg") },
            {
                id: 4,
                name_id: "shuffel_letters",
                name: "Shuffel Words",
                img: require("../../assets/icons/shuffle_letters.svg"),
            },
            {
                id: 5,
                name_id: "hidden_letters",
                name: "Hidden Words",
                img: require("../../assets/icons/hidden_letters.svg"),
            },
            { id: 6, name_id: "no_boost", name: "No Boost", img: require("../../assets/icons/no.svg") },
            {
                id: 7,
                name_id: "double_points",
                name: "Double Points",
                img: require("../../assets/icons/x2_score.svg"),
            },
            { id: 8, name_id: "half_time", name: "Half Time", img: require("../../assets/icons/time_fast.svg") },
            {
                id: 9,
                name_id: "shuffel_letters",
                name: "Shuffel Words",
                img: require("../../assets/icons/shuffle_letters.svg"),
            },
            {
                id: 10,
                name_id: "hidden_letters",
                name: "Hidden Words",
                img: require("../../assets/icons/hidden_letters.svg"),
            },
            { id: 11, name_id: "no_boost", name: "No Boost", img: require("../../assets/icons/no.svg") },
            {
                id: 12,
                name_id: "double_points",
                name: "Double Points",
                img: require("../../assets/icons/x2_score.svg"),
            },
        ];

        onMounted(() => {
            let container = document.querySelector(".tiny-container");
            if (container != null) {
                container.style.transform = "rotate(180deg)";
                currentPostion = 180;
                currentArrayPos = 0;
            }
        });

        const spinWheel = () => {
            if (store.state.storeEventData.game_state != 1) {
                return;
            }
            let target = store.state.storeEventData.current_boost;
            if (target) {
                target = target.name_id;
            } else {
                target = "no_boost";
            }
            const targetPos = wheelPortions.findIndex((e) => e.name_id == target);
            const spinDegre = (targetPos - currentArrayPos) * -30;
            currentArrayPos = targetPos;
            currentPostion += 360 * 5 + spinDegre;
            let container = document.querySelector(".tiny-container");
            let container2 = document.querySelector(".tiny-container-2");
            container.style.transform = "rotate(" + currentPostion + "deg)";
            container2.style.transform = "rotate(" + currentPostion + 15 + "deg)";
            addShaking();
            setTimeout(() => {
                removeShaking();
            }, 5000);

            console.log(target, store.state.storeEventData.roulette, targetPos, spinDegre, currentPostion);
        };

        const addShaking = () => {
            document.getElementById("tiny-arrow").classList.add("arrow-spinning");
        };
        const removeShaking = () => {
            document.getElementById("tiny-arrow").classList.remove("arrow-spinning");
        };

        watch(
            () => store.state.storeEventData.game_state == 1,
            () => spinWheel()
        );

        return { wheelPortions };
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #34495e;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.tiny-container {
    @media (max-width: 1300px) {
        width: 400px;
        height: 400px;
    }
    width: 500px;
    height: 500px;
    background-color: #171c27;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: 5s;
    top: -50vh;
    transform: rotate(15deg);
}
.tiny-container-2 {
    @media (max-width: 1300px) {
        width: 440px;
        height: 440px;
    }
    width: 540px;
    height: 540px;
    background-color: #171c27;
    border-radius: 50%;
    position: absolute;
    transition: 5s;
    top: -52vh;
    z-index: -1;
    transform: rotate(15deg);
}
.tiny-container-div {
    height: 50%;
    @media (max-width: 1300px) {
        width: 105px;
        left: 148px;
    }
    width: 130px;
    left: 185px;
    position: absolute;
    clip-path: polygon(100% 0, 50% 100%, 0 0);
    transform: translateX(-50%);
    transform-origin: bottom;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;

    @apply flex flex-col justify-start;
}

.tiny-container-div-img {
    @apply mt-3;
}

//COLOR VARIABLES
/*$blue_light: #0090ff;
$blue_dark: #004287;
$yellow_light: #ffe24d;
$yellow_dark: #cbaa00;
$red_light: #ff7070;
$red_dark: #c72e31;
$white_light: #fdffef;
$white_dark: #d9d9d9;
$pink_light: #ff3790;
$pink_dark: #aa0a4b;
$green_light: #48c50e;
$green_dark: #108700;

$list_blue: $blue_light 50.04%, $blue_dark 91.9%;
$list_2: $yellow_light 50.05%, $yellow_dark 93.32%;
$list_3: $red_light 50.04%, $red_dark 91.9%;
$list_4: $white_light 50.05%, $white_dark 93.32%;
$list_5: $pink_light 50.04%, $pink_dark 91.9%;
$list_6: $green_light 50.04%, $green_dark 91.9%;*/

.tiny-container-2 .portion-1 {
}
.tiny-container-2 .portion-2 {
    transform: rotate(30deg);
}
.tiny-container-2 .portion-3 {
    transform: rotate(60deg);
}
.tiny-container-2 .portion-4 {
    transform: rotate(90deg);
}
.tiny-container-2 .portion-5 {
    transform: rotate(120deg);
}
.tiny-container-2 .portion-6 {
    transform: rotate(150deg);
}
.tiny-container-2 .portion-7 {
    transform: rotate(180deg);
}
.tiny-container-2 .portion-8 {
    transform: rotate(210deg);
}
.tiny-container-2 .portion-9 {
    transform: rotate(240deg);
}
.tiny-container-2 .portion-10 {
    transform: rotate(270deg);
}
.tiny-container-2 .portion-11 {
    transform: rotate(300deg);
}
.tiny-container-2 .portion-12 {
    transform: rotate(330deg);
}

.tiny-container .portion-1 {
    background: linear-gradient(0deg, #0090ff 50.04%, #004287 91.9%);
    left: 50%;
}
.tiny-container .portion-2 {
    background: linear-gradient(0deg, #ffe24d 50.05%, #cbaa00 93.32%);
    transform: rotate(30deg);
}
.tiny-container .portion-3 {
    background: linear-gradient(0deg, #ff7070 50.04%, #c72e31 91.9%);
    transform: rotate(60deg);
}
.tiny-container .portion-4 {
    background: linear-gradient(0deg, #fdffef 50.05%, #d9d9d9 93.32%);
    transform: rotate(90deg);
}
.tiny-container .portion-5 {
    background: linear-gradient(0deg, #ff3790 50.04%, #aa0a4b 91.9%);
    transform: rotate(120deg);
}
.tiny-container .portion-6 {
    background: linear-gradient(0deg, #48c50e 50.04%, #108700 91.9%);
    transform: rotate(150deg);
}
.tiny-container .portion-7 {
    background: linear-gradient(0deg, #0090ff 50.04%, #004287 91.9%);
    transform: rotate(180deg);
}
.tiny-container .portion-8 {
    background: linear-gradient(0deg, #ffe24d 50.05%, #cbaa00 93.32%);
    transform: rotate(210deg);
}
.tiny-container .portion-9 {
    background: linear-gradient(0deg, #ff7070 50.04%, #c72e31 91.9%);
    transform: rotate(240deg);
}
.tiny-container .portion-10 {
    background: linear-gradient(0deg, #fdffef 50.05%, #d9d9d9 93.32%);
    transform: rotate(270deg);
}
.tiny-container .portion-11 {
    background: linear-gradient(0deg, #ff3790 50.04%, #aa0a4b 91.9%);
    transform: rotate(300deg);
}
.tiny-container .portion-12 {
    background: linear-gradient(0deg, #48c50e 50.04%, #108700 91.9%);
    transform: rotate(330deg);
}

.tiny-arrow {
    rotate: 90deg;
    top: 260px;
}

.tiny-arrow::before {
    content: "\1F817";
    font-size: 50px;
}

#spin {
    position: relative;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: rgb(46, 46, 46);
    text-transform: uppercase;
    border: 8px solid #a2a2a2;
    font-weight: bold;
    font-size: 23px;
    color: #a2a2a2;
    width: 125px;
    height: 125px;
    font-family: sans-serif;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    letter-spacing: 1px;
}
.point {
    @apply absolute h-3 w-3 rounded-full bg-white z-10;
}

@keyframes slideInFromMiddle {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-30vw);
    }
}
.arrow-spinning {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: rotate(15deg);
    }

    30% {
        transform: rotate(-15deg);
    }
    40% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-15deg);
    }
    60% {
        transform: rotate(15deg);
    }
    70% {
        transform: rotate(-15deg);
    }
    80% {
        transform: rotate(15deg);
    }
    90% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(15deg);
    }
}
</style>

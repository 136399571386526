const fairPriceData = {
    answer: []
}

const fairPriceMutations = {
    setFairPriceAnswer(state, data) {
        state.fairPriceData.answer = parseInt(data.answer);
    },
    eraseFairPriceAnswer(state) {
        state.fairPriceData.answer = [];
    }
}

const fairPriceResults = ({ commit, state, rootState }, data) => {
    commit("fairPriceSetResults", data.val())
}

export { fairPriceData, fairPriceResults, fairPriceMutations }
<template>
    <FormTheme :id="$route.params.id" />
</template>

<script>
// @ is an alias to /src

import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted, onUnmounted } from "vue";
import FormTheme from "@/components/dashboard/forms/Theme.vue";
import { axiosToken } from "@/libs/http";

export default {
    name: "GameView",
    components: { FormTheme },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        onMounted(() => {});

        return {};
    },
};
</script>

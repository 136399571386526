<template>
    <div class="flex flex-row items-center justify-center ml-4 div-ranking gap-2 w-8/12 mt-3">
        <div class="flex flex-col items-center relative justify-center ml-2 w-2/12">
            <div
                class="shadow-md flex flex-col bg-white absolute -left-3 rounded-full border-4 border-solid items-center justify-center text-center align-middle w-12 h-12"
                :class="'border-team-' + $store.getters.getTeamById($store.state.storeEventData.playerId.team).color_id"
            >
                <img
                    :src="$store.getters.getTeamById($store.state.storeEventData.playerId.team).avatar.image"
                    class="w-10 h-10 fill-silver-light"
                />
            </div>
        </div>
        <div class="ml-3 xl:ml-0 py-2 flex flex-col justify-center text-start w-10/12">
            Team {{ $store.getters.getTeamById($store.state.storeEventData.playerId.team).color_id }} -
            {{ $store.getters.getTeamById($store.state.storeEventData.playerId.team).nameShow }}
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {
        team: { type: Object, default: {} },
        position: { type: Number, default: 0 },
        highlight: { type: Boolean, default: false },
        points: { type: String, default: "Total" },
        custom_color: { type: Boolean, default: false },
    },
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped>
.div-ranking {
    background: #0d1015;
    border: 1px solid #2d303c;
    border-radius: 10px;

    @apply pr-2;
}
.team-player {
}
</style>

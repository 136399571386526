const quizData = {
    answer: null
}

const quizMutations = {
    setQuizAnswer(state, data) {
        state.quizData = data;
    },
    eraseQuizAnswer(state) {
        state.quizData = {};
    }

}

const quizResults = ({ commit, state, rootState }, data) => {
    commit("quizSetResults", data.val())
}

export { quizData, quizResults, quizMutations }
<template>
    <div class="flex flex-col gap-4">
        <h1>Entra el teu nom</h1>
        <input type="text" v-model="name" />
        <h1>Selecciona el mode:</h1>
        <div class="flex flex-row">
            <button @click="saveMode('online')">Online</button>
            <button @click="saveMode('fisic')">Fisic</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped></style>

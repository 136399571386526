<template>
    <div class="flex flex-col w-full justify-center items-start gap-6">
        <div class="flex flex-col gap-1 w-full">
            <label class="w-full font-title text-lg text-orange-intense" :for="question_data.question">Question</label>
            <input
                class="p-2 text-xl rounded-xl border-4"
                type="text"
                v-model="question_data.question"
                :class="question_data.question ? 'border-green-400' : 'border-red-500'"
            />
        </div>
        <div class="gap-4 items-center justify-center w-full grid grid-cols-2">
            <div class="flex flex-col w-full" v-for="(element, index) in question_data.order" :key="element">
                <div class="flex flex-col gap-1 w-full">
                    <label class="w-full font-title text-lg text-orange-intense" :for="element.name"
                        >Name {{ index + 1 }}</label
                    >
                    <div class="flex flex-row gap-1">
                        <input
                            class="p-2 text-xl rounded-xl border-4"
                            :class="question_data.order.length != index + 1 ? 'w-full' : 'w-10/12'"
                            type="text"
                            placeholder="(optional)"
                            v-model="element.name"
                        />
                        <div
                            class="flex flex-row cursor-pointer items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white w-1/12"
                            v-if="question_data.order.length < 6 && question_data.order.length == index + 1"
                            @click="addAnswer(question_data.id)"
                        >
                            <span>
                                <inline-svg
                                    :src="require('@/assets/icons/plus-solid.svg')"
                                    class="w-8 h-8 fill-orange-intense -z-10"
                                />
                            </span>
                        </div>
                        <div
                            class="flex flex-row cursor-pointer items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white w-1/12"
                            v-if="
                                question_data.order.length <= 6 &&
                                question_data.order.length > 4 &&
                                question_data.order.length == index + 1
                            "
                            @click="deleteAnswer(question_data.id)"
                        >
                            <span>
                                <inline-svg
                                    :src="require('@/assets/icons/trash-solid.svg')"
                                    class="w-6 h-6 ml-auto cursor-pointer my-auto fill-orange-intense"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-1 w-full">
                    <label class="w-full font-title text-orange-intense text-lg" :for="element.id"
                        >Image {{ index + 1 }}</label
                    >
                    <div class="grid grid-cols-2 gap-3 w-full">
                        <img
                            :key="element.id"
                            v-if="getImage(element.id)"
                            :src="getImage(element.id)"
                            alt=""
                            class="object-cover w-full h-24 rounded-xl border-4 border-solid border-green-400"
                        />
                        <img
                            v-else
                            :key="1"
                            class="w-full h-24 object-cover rounded-xl border-4 border-solid border-red-500"
                            :src="require('@/assets/images/default.png')"
                            alt=""
                        />
                        <Dropzone
                            :options="{
                                acceptedFiles: '.jpeg,.jpg,.png,.gif,.svg',
                                url: urlUpload,
                                method: 'put',
                                thumbnailWidth: 150,
                                maxFilesize: 50,
                                maxFiles: 1,
                                headers: {
                                    'Cache-Control': '',
                                    'Authorization': Token,
                                },
                            }"
                            id="uploadImage"
                            class="object-cover w-full h-[6.25rem] rounded-xl flex items-center justify-center"
                            :check_class="true"
                            :success="(x) => setImage(element.id, x)"
                        >
                            <input type="hidden" v-model="element.id" name="id_image" />
                            <input type="hidden" v-model="$route.params.id" name="id_content" />
                            <div class="text-lg font-medium text-center text-text-100">Drop image</div>
                        </Dropzone>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        question_data: { type: Object, default: {} },
        content_images: { type: Array, default: [] },
    },
    components: {},
    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload = process.env.VUE_APP_API_URL + "gamecontent/content_game/header_image";
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        const addAnswer = () => {
            props.question_data.order.push({
                id: uuidv4(),
                name: "",
            });
        };
        const deleteAnswer = () => {
            props.question_data.order.pop();
        };

        const setImage = (id, response) => {
            if (props.content_images.find((el) => (el.key = id))) {
                props.content_images.find((el) => (el.key = id)).img = response.img;
            } else {
                props.content_images.push(response);
            }
        };

        const getImage = (id) => {
            if (props.content_images.find((e) => e.key == id)) {
                return props.content_images.find((e) => e.key == id).img;
            } else {
                return "";
            }
        };
        watch(
            () => props.question_data,
            () => () => {
                if (props.question_data.length > 0) {
                    props.question_data = props.question_data;
                }
            }
        );
        onMounted(() => {
            /*if (!props.quiz_data > 0) {
                props.quiz_data.push(generateRow());
            }*/
            console.log("mounted");
            console.log(props.question_data);
            console.log(props.content_images);
        });

        return {
            urlUpload,
            Token,
            getImage,
            setImage,
            addAnswer,
            deleteAnswer,
        };
    },
};
</script>

<style scoped>
ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}
img {
    image-rendering: pixelated;
}
</style>

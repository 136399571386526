import store from '@/store/index'

var ws_type = location.protocol == 'https:' ? 'wss://' : 'ws://';
console.log(ws_type)

const ip_port = process.env.VUE_APP_IP_PORT


const startSocketEvent = (player_key, url_event) => {
    let reconect = 0;
    let socketEvent = new WebSocket(ws_type + ip_port + "ws/" + player_key + "/" + url_event);
    socketEvent.onopen = function (e) {
        console.log("[open] Conexión establecida");
        console.log("Enviando al servidor");
        //socketEvent.send("Mi nombre es John");
    };

    socketEvent.onclose = function (event) {
        setTimeout(function () {

            startSocketEvent(player_key, url_event)
            if (reconect == 0) {


            }
            reconect++
            if (reconect == 9) {
                reconect = 0
            }

        }, 1000);
    };

    socketEvent.onmessage = function (event) {
        const dataWs = JSON.parse(event.data)
        console.log(dataWs)
        if (dataWs.accion == "reaction") {

            store.commit("setAddReaction", dataWs.reaction)
        }
        if (dataWs.accion == "new_game_information") {

            store.dispatch("setUpdateEvent", dataWs.event_data)
        }
        if (dataWs.show_points) {
            console.log("assdasd")
            store.commit("setAnimationPoints")
        }
    };

    socketEvent.onerror = function (error) {
        console.log(`[error]`);
    };

    store.commit("setWebSocket", socketEvent)
    return socketEvent;
}

const startSocketTeam = (player_key, team) => {
    let reconect = 0;
    let socketTeam = new WebSocket(ws_type + ip_port + "team/" + player_key + "/" + team);
    socketTeam.onopen = function (e) {
        console.log("[open] Conexión establecida");
        console.log("Enviando al servidor");
        //socketTeam.send("Mi nombre es John");
        socketTeam.send(JSON.stringify({
            "action": "requestData",
        }))
    };

    socketTeam.onmessage = function (event) {
        console.log(`[message] Datos recibidos del servidor: ${event.data}`);
        const dataWs = JSON.parse(event.data)
        if (!store.state.storeEventData.current_game) {
            return;
        }
        if (dataWs.accion == "updateGameData") {
            switch (store.state.storeEventData.current_game.name_id) {
                case 'quiz':
                    store.commit("setQuizAnswer", JSON.parse(dataWs.data), { root: true })
                    break;
                case 'timeline':
                    store.commit("setTimelineAnswer", JSON.parse(dataWs.data), { root: true })
                    break;
                case 'fair_price':
                    store.commit("setFairPriceAnswer", JSON.parse(dataWs.data), { root: true })
                    break;

            }
        }

    };

    socketTeam.onclose = function (event) {
        setTimeout(function () {

            startSocketTeam(player_key, team)
            if (reconect == 0) {


            }
            reconect++
            if (reconect == 9) {
                reconect = 0
            }

        }, 1000);
    };

    socketTeam.onerror = function (error) {
        console.log(`[error]`);
    };
    store.commit("setWebSocketTeam", socketTeam)
    return socketTeam;
}


const startSocketEventGameMaster = (player_key, url_event) => {
    let reconect = 0;
    let socketEvent = new WebSocket(ws_type + ip_port + "ws/" + player_key + "/" + url_event);
    socketEvent.onopen = function (e) {
        console.log("[open] Conexión establecida");
        console.log("Enviando al servidor");
        //socketEvent.send("Mi nombre es John");
    };

    socketEvent.onmessage = function (event) {
        const dataWs = JSON.parse(event.data)
        console.log(dataWs)
        if (dataWs.accion == "new_game_information") {

            store.commit("setUpdateEvent", dataWs.event_data)
        }
        if (dataWs.accion == "new_players") {
            // convert json again
            const online_players = JSON.parse(dataWs.message)
            console.log(online_players)
            store.commit("setOnlinePlayers", online_players)
        }
    };

    socketEvent.onclose = function (event) {
        setTimeout(function () {

            startSocketEventGameMaster(player_key, url_event)
            if (reconect == 0) {


            }
            reconect++
            if (reconect == 9) {
                reconect = 0
            }

        }, 1000);
    };

    socketEvent.onerror = function (error) {
        console.log(`[error]`);
    };

    return socketEvent;
}

const startSocketTest = () => {
    let reconect = 0;
    let socketTest = new WebSocket(ws_type + ip_port + "ws/test");
    socketTest.onopen = function (e) {
        store.commit("setWebSocketTest", true);
    };

    socketTest.onclose = function (event) {
        setTimeout(function () {

            startsocketTest()
            if (reconect == 0) {


            }
            reconect++
            if (reconect == 9) {
                reconect = 0
            }

        }, 1000);
    };

    socketTest.onerror = function (error) {
        store.commit("setWebSocketTest", false)
    };

    return socketTest;
}

export { startSocketEvent, startSocketTeam, startSocketEventGameMaster, startSocketTest }
<template>
    <DashboardGameMaster />
</template>

<script>
// @ is an alias to /src

import { useRouter } from "vue-router";
import { onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import DashboardGameMaster from "@/components/DashboardGameMaster.vue";
import { startSocketEventGameMaster } from "@/libs/ws";
export default {
    name: "GameView",
    components: { DashboardGameMaster },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        onMounted(async () => {
            console.log(location.pathname.split("/")[2], ".-------------");
            store.commit("loadStoreEventData", "/" + location.pathname.split("/")[2]);
            startSocketEventGameMaster(localStorage.user, location.pathname.split("/")[2]);
        });

        return {};
    },
};
</script>

<template>
    <span class="flex flex-col w-10/12 relative" v-if="dataContent">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <div class="flex flex-col w-3/12 font-title text-orange-intense">Name Id</div>
                <div class="flex flex-col w-4/12 font-title text-orange-intense">Name</div>
                <div class="flex flex-col w-1/12 font-title text-orange-intense">Lang</div>
                <div class="flex flex-row justify-end gap-2 w-4/12 items-end">
                    <div class="flex flex-col">
                        <select class="p-2 text-xl rounded-xl" v-model="categorySelected">
                            <option
                                v-for="categoryElement in categorySelect"
                                :key="categoryElement"
                                :value="categoryElement.key"
                            >
                                {{ categoryElement.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex flex-col text-2xl cursor-pointer" @click="addContent">
                        <div
                            class="flex flex-row items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
                        >
                            <span>Add Content</span>
                            <inline-svg
                                :src="require('@/assets/icons/plus-solid.svg')"
                                class="w-6 h-6 fill-orange-intense ml-2"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="w-full" />
        <div class="flex flex-col text-2xl scroll-auto" v-if="dataContent">
            <div
                class="flex flex-row pr-2 mt-1"
                v-for="content in dataContent
                    .filter((c) => c.category == categorySelected || categorySelected == 0)
                    .sort((a, b) => {
                        return new Date(a.date_time) - new Date(b.date_time) || b.id - a.id;
                    })"
                :key="content"
            >
                <div class="hidden">{{ content.id }}</div>
                <div class="flex flex-col w-3/12">{{ content.name_id.substring(0, 20) }}</div>
                <div class="flex flex-col w-4/12">{{ content.name.substring(0, 20) }}</div>
                <div class="flex flex-col w-1/12">{{ content.language }}</div>
                <div class="flex flex-row w-4/12 items-center justify-end gap-2">
                    <div class="flex flex-row gap-2 items-center accent-orange-intense">
                        <input
                            class="h-6 w-6"
                            type="checkbox"
                            v-model="content.active"
                            :checked="content.active"
                            @change="saveGridContent(content.id)"
                        />
                    </div>
                    <div class="flex flex-col">
                        <img class="h-8" src="@/assets/icons/icons8-ok-96.png" alt="" v-if="content.validated" />
                        <img class="h-8" src="@/assets/icons/icons8-cancel-96.png" alt="" v-else />
                    </div>
                    <div
                        class="flex flex-col justify-center items-end text-2xl cursor-pointer"
                        @click="editContent(content.id)"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/pen-to-square-regular.svg')"
                            class="w-6 h-6 fill-orange-intense"
                        />
                    </div>
                    <div class="flex flex-col cursor-pointer" @click="deleteContent(content.name, content.id)">
                        <inline-svg
                            :src="require('@/assets/icons/trash-solid.svg')"
                            class="w-6 h-6 fill-orange-intense"
                        />
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

export default {
    props: {},
    components: {},
    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload = process.env.VUE_APP_API_URL + "gamecontent";
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const categorySelect = ref([]);
        const categoryData = ref(null);
        const categorySelected = ref(0);
        const dataContent = ref([]);
        const dataNewContent = ref({
            name: "",
            name_id: "",
            language: "en",
            description: "",
            game: 1,
            data: [],
            selected_default: false,
            icon: "",
            image: "",
            background: "",
            active: false,
            category: 1,
        });

        const getCategorySelect = async () => {
            await getCategoryData();
            categorySelect.value.push({
                key: 0,
                name: "",
            });
            categoryData.value.forEach((category) => {
                categorySelect.value.push({
                    key: category.id,
                    name: category.name,
                });
            });
        };

        const getCategoryData = async () => {
            categoryData.value = await axiosToken({
                method: "get",
                url: "gamecontent/category",
                authorization: true,
            });

            categoryData.value = categoryData.value.data;
        };

        const getContentHeaders = async () => {
            dataContent.value = await axiosToken({
                method: "get",
                url: "gamecontent/content_game",
                authorization: true,
            });
            dataContent.value = dataContent.value.data;
            console.log(dataContent.value);
        };

        const createFormToSave = () => {
            const contentToSave = new FormData();
            contentToSave.append("name", dataNewContent.value.name);
            contentToSave.append("name_id", dataNewContent.value.name_id);
            contentToSave.append("language", dataNewContent.value.language);
            contentToSave.append("description", dataNewContent.value.description);
            contentToSave.append("game", dataNewContent.value.game);
            contentToSave.append("selected_default", dataNewContent.value.selected_default);
            if (
                typeof dataNewContent.value.icon === "object" &&
                dataNewContent.value.icon != null &&
                dataNewContent.value.icon != ""
            ) {
                contentToSave.append("icon", dataNewContent.value.icon);
            }
            if (
                typeof dataNewContent.value.image === "object" &&
                dataNewContent.value.image != null &&
                dataNewContent.value.image != ""
            ) {
                contentToSave.append("image", dataNewContent.value.image);
            }
            if (
                typeof dataNewContent.value.background === "object" &&
                dataNewContent.value.background != null &&
                dataNewContent.value.background != ""
            ) {
                contentToSave.append("background", dataNewContent.value.background);
            }
            console.log(contentToSave);

            return contentToSave;
        };

        const addContent = async () => {
            await axiosToken({
                method: "post",
                url: "gamecontent/content_game",
                body: createFormToSave(),
                authorization: true,
            }).then((response) => {
                router.push({ name: "dashboardContentUpsert", params: { id: response.data.id } });
            });
        };

        const editContent = (id) => {
            router.push("/dashboard/content/upsert/" + id);
        };

        const deleteContent = async (name, id) => {
            var ok = window.confirm("Are you sure you want to delete the content " + name + "?");
            if (ok) {
                var result = await axiosToken({
                    method: "delete",
                    url: "gamecontent/content_game/" + id,
                    authorization: true,
                });
            }
            getContentHeaders();
        };

        const createOnGridDataSave = (id_content) => {
            const contentToSave = new FormData();
            const content = dataContent.value.find((dc) => dc.id == id_content);
            console.log(content);
            contentToSave.append("active", content.active);
            return contentToSave;
        };

        const saveGridContent = async (id_content) => {
            await axiosToken({
                method: "put",
                url: "gamecontent/content_game/" + id_content,
                body: createOnGridDataSave(id_content),
                authorization: true,
            }).then((response) => {
                if (response.data) {
                    store.commit("loadContentData", response.data.id);
                    if (showAlert) {
                        notify(
                            {
                                group: "success",
                                title: "Success",
                                text: "Content updated",
                            },
                            4000
                        );
                    }
                } else {
                    if (showAlert) {
                        notify(
                            {
                                group: "error",
                                title: "Error",
                                text: "There was a updating the content!!",
                            },
                            4000
                        );
                    }
                }
            });
        };

        onMounted(() => {
            store.commit("clearDataContent");
            getContentHeaders();
            getCategorySelect();
        });

        return {
            Token,
            urlUpload,
            dataContent,
            addContent,
            editContent,
            deleteContent,
            categorySelect,
            categorySelected,
            saveGridContent,
        };
    },
};
</script>

<style scoped></style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SettingsView from '../views/SettingsView.vue'
import GameView from '../views/GameView.vue'
import DashboardGameMaster from '../views/DashboardGameMaster.vue'
import LoginGm from '../views/LoginGm.vue'
import NoUrlDashboard from '../views/NoUrlDashboard.vue'
import DashboardEventUpsert from '../views/DashboardEventUpsert.vue'
import DashboardThemeUpsert from '../views/DashboardThemeUpsert.vue'
import DashboardContentUpsert from '../views/DashboardContentUpsert.vue'
import TestView from '../views/TestView.vue'
import store from '@/store';
import { nextTick } from 'vue'

const routes = [
  {
    path: '/:url',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Common Sense'
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      title: 'Common Sense'
    }
  },
  {
    path: '/game',
    name: 'game',
    component: GameView,
    meta: {
      title: 'Common Sense'
    }
  },

  {
    path: '/dashboard/',
    name: 'dashboardNOurl',
    component: NoUrlDashboard,
    meta: {
      title: 'Common Sense',
      requiresAuth: true
    },

  },
  {
    path: '/dashboard/event/upsert/:url',
    name: 'dashboardEventUpsert',
    component: DashboardEventUpsert,
    meta: {
      title: 'Common Sense',
      requiresAuth: true
    },

  },
  {
    path: '/dashboard/theme/upsert/:id',
    name: 'dashboardThemeUpsert',
    component: DashboardThemeUpsert,
    meta: {
      title: 'Common Sense',
      requiresAuth: true
    },

  },
  {
    path: '/dashboard/content/upsert/:id',
    name: 'dashboardContentUpsert',
    component: DashboardContentUpsert,
    meta: {
      title: 'Common Sense',
      requiresAuth: true
    },

  },
  {
    path: '/dashboard/:url',
    name: 'dashboard',
    component: DashboardGameMaster,
    meta: {
      title: 'Common Sense',
      requiresAuth: true
    },

  }
  ,
  {
    path: '/login',
    name: 'loginGM',
    component: LoginGm,
    meta: {
      title: 'Common Sense'
    }


  },
  {
    path: '/test',
    name: 'test',
    component: TestView,
    meta: {
      title: 'Common Sense'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in 
    // if not, redirect to login page.


    if (!store.state.storeAuth.status.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()! 
  }
})

const DEFAULT_TITLE = 'Common Sense';
router.afterEach((to, from) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router

<template>
    <div class="flex flex-col gap-2 h-screen">
        <div class="dashboard-card p-5 h-1/2 flex flex-col overflow-scroll overflow-x-hidden">
            <h2 class="text-center">Ranking</h2>
            <div class="flex flex-col gap-2">
                <div
                    class="bg-black rounded-lg my-2 p-2"
                    v-for="(team, index) in $store.state.storeEventData.event_team_points_sorted"
                    :key="team"
                >
                    {{ index + 1 }} -- {{ team.nameShow }} -- {{ team.points }} points
                </div>
            </div>
        </div>
        <div class="dashboard-card p-5 h-1/2 flex flex-col overflow-scroll overflow-x-hidden">
            <h2 class="text-center">Ranking Current Game</h2>
            <div class="flex flex-col gap-2">
                <div
                    class="bg-black rounded-lg my-2 p-2"
                    v-for="(team, index) in $store.state.storeEventData.event_team_points_game_sorted"
                    :key="team"
                >
                    {{ index + 1 }} -- {{ team.nameShow }} -- {{ team.points_current_game }} points
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
    props: {},
    components: {},
    setup(props) {},
};
</script>

<style lang="scss" scoped>
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

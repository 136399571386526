<template>
    <span>
        <div v-if="$store.state.storeEventData.event_state == 0" class="">
            <h1 class="text-center">Waiting Players To join</h1>
            <div class="flex flex-row w-full gap-2 flex-wrap m-1">
                <div class="dashboard-card-2 p-2">
                    <span>Total Players: {{ $store.state.storeEventData.event_player.length }}</span>
                </div>
                <div
                    class="flex flex-col items-center s p-5 w-full dashboard-card-2"
                    v-for="loc in $store.state.storeEventData.event_location"
                    :key="loc"
                >
                    <h4>
                        {{ loc.name }}
                    </h4>
                    <p class="text-center">Total Players: {{ $store.getters.getPlayerLocation(loc.id) }}</p>
                    <div class="flex flex-row flex-wrap gap-2">
                        <span v-for="player in $store.state.storeEventData.event_player" :key="player" class="">
                            <div
                                class="bg-gray-dark p-2 rounded-xl flex flex-row items-center gap-1"
                                v-if="player.location == loc.id"
                            >
                                <p class="m-0">
                                    {{ player.name }}
                                </p>
                                <div v-if="checkUserOnline(player.key) == 0" class="circle-red"></div>
                                <div v-if="checkUserOnline(player.key) >= 1" class="circle-green"></div>
                                <p class="m-0">
                                    {{ checkUserOnline(player.key) }}
                                </p>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$store.state.storeEventData.event_state > 0" class="w-full h-screen overflow-auto overflow-x-hidden">
            <h1 class="text-center">Teams</h1>
            <div class="grid grid-cols-3 w-full gap-2 m-1 mb-4 items-start justify-center">
                <div
                    class="flex flex-col items-center gap-3 p-5 dashboard-card-2 flex-1"
                    v-for="team in teamsOrderedById"
                    :key="team"
                >
                    <div class="flex flex-row gap-2 items-center">
                        <div class="flex flex-col">
                            <h4 class="m-0">
                                {{ team.name }}
                            </h4>
                            <h4 class="m-0">
                                {{ team.nameShow }}
                            </h4>
                        </div>
                        <font-awesome-icon
                            icon="fa-solid fa-pen-to-square"
                            class="cursor-pointer w-6 h-6"
                            v-on:click="
                                setIsModalVisible(true);
                                setEditTeamname(team.name);
                                setIdEditTeam(team.id);
                            "
                        />
                        <img
                            :src="require('@/assets/icons/refree.png')"
                            class="cursor-pointer w-6 h-6"
                            title="Arbitro, que sabemó que coshe trae!!"
                            @click="
                                setModalTeamPoints(true);
                                setActualTeamPoints(team.points);
                                setEditTeamname(team.name);
                                setIdEditTeam(team.id);
                            "
                        />
                    </div>
                    <div class="flex flex-row flex-wrap gap-2">
                        <template v-for="player in $store.state.storeEventData.event_player" :key="player" class="">
                            <div
                                class="bg-gray-dark p-2 rounded-xl flex flex-row items-center gap-1 w-full"
                                v-if="player.team == team.id"
                            >
                                <p class="m-0">
                                    {{ player.name }}
                                </p>

                                <div v-if="checkUserOnline(player.key) == 0" class="circle-red"></div>
                                <div v-if="checkUserOnline(player.key) >= 1" class="circle-green"></div>
                                <p class="m-0">
                                    {{ checkUserOnline(player.key) }}
                                </p>
                                <font-awesome-icon
                                    icon="fa-solid fa-pen-to-square"
                                    class="cursor-pointer ml"
                                    v-on:click="
                                        setModalPlayer(true);
                                        setPlayerEdit(player.id);
                                    "
                                />
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <ModalDashboard
            :isModalVisible="isModalVisible"
            :isModalVisibleEdit="setIsModalVisible"
            :saveFunction="editTeam"
        >
            <h4 class="text-black text-center">Cambiar nombre de equipo</h4>
            <input class="dashboard-input" v-model="editTeamname" />
        </ModalDashboard>
        <ModalDashboard
            :isModalVisible="modalTeamPoints"
            :isModalVisibleEdit="setModalTeamPoints"
            :saveFunction="editSaveTeamPoints"
        >
            <div class="flex flex-col items-center justify-center">
                <h2 class="text-white text-center m-2">
                    Sumar o restar puntos al equipo: <br />
                    <span class="text-orange-intense font-title">{{ editTeamname }}</span>
                </h2>
                <h3 class="text-white m-2">
                    Puntos actuales: <span class="text-orange-intense font-title">{{ actualTeamPoints }}</span>
                </h3>
                <input type="number" class="p-2 px-6 w-4/12 dashboard-input m-2" v-model="editTeamPoints" />
                <h3 class="text-white m-2">
                    Puntos despues de guardar:
                    <span class="text-orange-intense font-title">{{
                        isNaN(actualTeamPoints + editTeamPoints) ? actualTeamPoints : actualTeamPoints + editTeamPoints
                    }}</span>
                </h3>
            </div>
        </ModalDashboard>
        <ModalDashboard :isModalVisible="modalPlayer" :isModalVisibleEdit="setModalPlayer" :saveFunction="editPlayer">
            <h4 class="text-black text-center">Editar Jugador</h4>
            <input class="dashboard-input" v-model="playerEdit.name" />
            <label for="">Equipo</label>
            <select class="dashboard-selector" id="" v-model="playerEdit.team">
                <option v-for="team in $store.state.storeEventData.event_team" :key="team" :value="team.id">
                    {{ team.name }}
                </option>
            </select>
            <button
                class="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-md hover:shadow-lg hover:bg-gray-100"
                v-on:click="copyLinkPlayer(playerEdit.key)"
            >
                Copy Link Player
            </button>
        </ModalDashboard>
    </span>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import ModalDashboard from "@/components/dashboard/ModalDashboard.vue";
import { useState } from "@/hooks/state";
import { axiosToken } from "@/libs/http";

export default {
    props: {},
    components: { ModalDashboard },
    setup(props) {
        const store = useStore();
        const onlinePlayers = computed(() => store.state.storeEventData.onlinePlayers);
        const [isModalVisible, setIsModalVisible] = useState(false);
        const checkUserOnline = (key) => {
            if (key in onlinePlayers.value) {
                return onlinePlayers.value[key].online;
            }
            return 0;
        };
        const teamsOrderedById = computed(() => store.state.storeEventData.event_team_id_sorted);

        // edit team
        const [editTeamname, setEditTeamname] = useState();
        const [idEditTeam, setIdEditTeam] = useState();
        const editTeam = async () => {
            const dataEvent = await axiosToken({
                url: "event/team/" + idEditTeam.value,
                body: {
                    nameShow: editTeamname.value,
                    points: 0,
                },
                method: "put",
                authorization: true,
            });
            store.commit("editTeam", dataEvent.data);
        };

        //edit team points
        const [modalTeamPoints, setModalTeamPoints] = useState(false);
        const [editTeamPoints, setEditTeamPoints] = useState();
        const [actualTeamPoints, setActualTeamPoints] = useState();
        const editSaveTeamPoints = async () => {
            const dataEvent = await axiosToken({
                url: "event/team/" + idEditTeam.value,
                body: {
                    points: editTeamPoints.value,
                },
                method: "put",
                authorization: true,
            });
            store.commit("editTeamPoints", dataEvent.data);
        };

        // edit player
        const [modalPlayer, setModalPlayer] = useState(false);

        const playerEdit = ref({
            name: "",
            team: "",
            id: "",
            key: "",
        });

        const setPlayerEdit = (id) => {
            const player = store.state.storeEventData.event_player.find((e) => e.id == id);
            console.log(player.key);
            playerEdit.value.name = player.name;
            playerEdit.value.id = player.id;
            playerEdit.value.team = player.team;
            playerEdit.value.key = player.key;
        };
        const editPlayer = async () => {
            const dataEvent = await axiosToken({
                url: "event/player/" + playerEdit.value.key,
                body: {
                    name: playerEdit.value.name,
                    team: playerEdit.value.team,
                },
                method: "put",
                authorization: true,
            });
            store.commit("editPlayer", dataEvent.data);
        };

        const copyLinkPlayer = (key) => {
            // Copy the text inside the text field
            navigator.clipboard.writeText(window.location.host + "/" + store.state.storeEventData.url + "?key=" + key);

            // Alert the copied text
            alert("Copied the text: ");
        };

        return {
            checkUserOnline,
            isModalVisible,
            editTeam,
            setIsModalVisible,
            setEditTeamname,
            editTeamname,
            setIdEditTeam,
            setModalPlayer,
            modalPlayer,
            editPlayer,
            playerEdit,
            setPlayerEdit,
            copyLinkPlayer,
            teamsOrderedById,
            setEditTeamPoints,
            editTeamPoints,
            editSaveTeamPoints,
            modalTeamPoints,
            setModalTeamPoints,
            actualTeamPoints,
            setActualTeamPoints,
        };
    },
};
</script>

<style lang="scss" scoped>
.circle-red {
    background: #f00;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
.circle-green {
    background: green;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>

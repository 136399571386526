<template>
    <div class="flex flex-col text-center w-full" v-if="$store.state.storeEventData.ingame_state <= 2">
        <h1 class="custom-color font-title text-6xl mb-4">
            {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{ questions.length }}
        </h1>
        <div class="flex flex-col items-center justify-center" v-for="(question, index) in questions" :key="question">
            <div
                class="flex w-10/12 flex-col items-center justify-center bg-blue-dark rounded-3xl p-8 bg-opacity-80"
                v-if="index == $store.state.storeEventData.counter_game"
            >
                <img class="object-cover w-9/12 rounded-3xl max-w-4xl mb-1" :src="question.image" alt="image" />
                <h4 class="w-9/12 text-3xl my-3">{{ question.question }}</h4>
                <h4
                    class="w-11/12 my-2"
                    v-if="$store.state.storeEventData.ingame_state == 2 && question.answer_explanation != ''"
                >
                    {{ question.answer_explanation }}
                </h4>
                <div class="flex flex-col w-full">
                    <div class="flex flex-row">
                        <div class="flex flex-col w-full text-left font-title">{{ question.min_slider }}</div>
                        <div class="flex flex-col w-full text-right font-title">{{ question.max_slider }}</div>
                    </div>
                    <div class="flex flex-row w-full items-center justify-center relative">
                        <input
                            type="range"
                            :min="question.min_slider"
                            :max="question.max_slider"
                            :step="question.interval"
                            v-model="slider"
                            class="slider"
                            :class="$store.state.storeEventData.ingame_state >= 2 ? '' : ''"
                            :disabled="true"
                        />
                        <span
                            :class="$store.state.storeEventData.ingame_state >= 2 ? 'visible' : 'invisible'"
                            class="flex flex-col items-center justify-center absolute w-10/12 ml-6"
                        >
                            <span
                                class="flex flex-col absolute slider-value rounded-full h-6 w-6 p-2 items-center text-black justify-center"
                                :class="'bg-team-' + team.color_id"
                                :id="'slider-value-' + team.id + '-' + index"
                                v-for="team in $store.state.storeEventData.event_team"
                                :key="team"
                                :style="'left:' + getLeftValue(team.id)"
                            >
                                {{ parseFloat(team.rt_data.answer).toFixed(0) }}
                            </span>
                            <span
                                class="flex flex-col absolute slider-value text-2xl rounded-full h-10 w-10 p-2 bg-quiz-green border-2 border-solid border-white items-center justify-center"
                                :id="'slider-value-mainresult-' + $store.state.storeEventData.counter_game"
                            >
                                {{
                                    $store.state.storeEventData.game_data.correct
                                        ? $store.state.storeEventData.game_data.correct
                                        : 0
                                }}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        v-else-if="$store.state.storeEventData.ingame_state == 3"
        class="w-full h-full flex flex-col justify-center items-center"
    >
        <div class="flex flex-col items-center justify-center bg-blue-dark rounded-3xl w-11/12 py-6 bg-opacity-80">
            <div class="flex flex-row w-full">
                <div class="flex flex-col justify-center w-6/12">
                    <MainRankingFinal></MainRankingFinal>
                </div>
                <div class="flex flex-col justify-center w-6/12">
                    <MainRankingFinal :points="'Game'"></MainRankingFinal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import MainRankingFinal from "@/components/MainRankingFinal.vue";

export default {
    props: {},
    components: { MainRankingFinal },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const currentQuestion = ref(1);
        const slider = ref(0);
        let correctSlider = null;
        const maxSlider = computed(
            () => store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].max_slider
        );
        const minSlider = computed(
            () => store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].min_slider
        );
        const teamAnswers = ref(store.state.storeEventData.event_team);

        onMounted(() => {
            if (store.state.storeEventData.ingame_state == 3) {
                return;
            }
            correctSlider = document.querySelector(
                "#slider-value-mainresult-" + store.state.storeEventData.counter_game
            );
            showCorrectValue();
        });

        watch(
            () => store.state.storeEventData.ingame_state,

            () => {
                if (store.state.storeEventData.ingame_state == 3) {
                    return;
                }
                console.log("watch");
                console.log(store.state.storeEventData.ingame_state);
                if (store.state.storeEventData.ingame_state == 2) {
                    correctSlider = document.querySelector(
                        "#slider-value-mainresult-" + store.state.storeEventData.counter_game
                    );
                    showCorrectValue();
                }
            }
        );

        function showCorrectValue() {
            console.log("showCorrectValue");
            var range_value = maxSlider.value - minSlider.value;
            var range_selected = maxSlider.value - store.state.storeEventData.game_data.correct;
            var sliderPosition = 1 - range_selected / range_value;
            correctSlider.style.left = sliderPosition * 100 + "%";
            console.log(correctSlider);
        }
        const getLeftValue = (tema_id) => {
            const team = store.getters.getTeamById(tema_id);
            var range_value = maxSlider.value - minSlider.value;
            var range_selected = maxSlider.value - team.rt_data.answer;
            var sliderPosition = 1 - range_selected / range_value;
            return sliderPosition * 100 + "%";
        };

        const questions = ref(store.state.storeEventData.shared_content);
        console.log(questions.value);

        return { currentQuestion, questions, slider, teamAnswers, getLeftValue };
    },
};
</script>
<style lang="scss" scoped>
$bg: #eee;
$slider-bg: #fff;
$slider-thumb: #ffa724;

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 85%;
    height: 25px;
    background: $slider-bg;
    outline: none;
    border-radius: 20px;
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
    }
    &::-moz-range-thumb {
        width: 12px;
        height: 40px;
        background: $slider-thumb;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transition: 0.1s ease-in-out;
    }
    &::-moz-range-track {
        background: $slider-bg;
    }
    &:active {
        &::-webkit-slider-thumb {
            background: darken($slider-thumb, 10%);
        }
        &::-moz-range-thumb {
            background: darken($slider-thumb, 10%);
        }
        + #slider-value {
            opacity: 1;
            background: darken($slider-thumb, 10%);
        }
    }
}
.slider-container {
    position: relative;
    width: 85%;
    -webkit-appearance: none;
    & #slider-value {
        position: relative;
        display: block;
        transform: scale(1, 1) translateY(50%) translateX(20%) rotate(90deg);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 60px;
        background-color: $slider-thumb;
        color: #fff;
        text-align: center;
        font-size: 1.5em;
        font-weight: bolder;
        opacity: 1;
        top: -30px;
        transition: 0.15s top ease-in-out, 0.15s opacity ease-in-out 0.05s, 0.15s scale ease-in-out;
    }
}

@media screen and (min-width: 1900px) {
    img {
        height: 500px;
    }
}
@media screen and (min-width: 1024px) and (max-width: 1899) {
    img {
        height: 400px;
    }
}
</style>

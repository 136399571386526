<template>
    <span class="flex flex-col w-10/12" v-if="events">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <div class="flex flex-col w-1/12 font-title text-orange-intense">Id</div>
                <div class="flex flex-col w-1/12 font-title text-orange-intense">Lang</div>
                <div class="flex flex-col w-5/12 font-title text-orange-intense">Name</div>
                <div class="flex flex-col w-3/12 font-title text-orange-intense">Date time</div>
                <div class="flex flex-row justify-end w-2/12 items-center text-2xl cursor-pointer">
                    <div
                        class="flex flex-row items-center justify-center"
                        title="Real Events"
                        @click="changeShowRealEvents()"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/circle-check-regular.svg')"
                            class="w-6 h-6 ml-2 -z-10"
                            :class="showRealEvents ? 'fill-orange-intense' : 'fill-silver-light'"
                        />
                    </div>
                    <div
                        class="flex flex-row items-center justify-center mr-2"
                        title="Past Events"
                        @click="changeShowPastEvents()"
                    >
                        <inline-svg
                            :src="require('@/assets/icons/icons8-restart.svg')"
                            class="w-6 h-6 ml-2 -z-10"
                            :class="showPastEvents ? 'fill-orange-intense' : 'fill-silver-light'"
                        />
                    </div>
                    <div
                        @click="addEvent"
                        class="flex flex-row items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
                    >
                        <span>Add Event</span>
                        <inline-svg
                            :src="require('@/assets/icons/plus-solid.svg')"
                            class="w-6 h-6 fill-orange-intense ml-2 -z-10"
                        />
                    </div>
                </div>
            </div>
        </div>
        <hr class="w-full" />
        <div class="flex flex-col text-2xl" v-if="events">
            <div
                class="flex flex-row"
                v-for="event in events.sort((a, b) => {
                    return new Date(a.date_time) - new Date(b.date_time) || b.id - a.id;
                })"
                :key="event"
            >
                <div class="flex flex-col w-1/12">{{ event.id }}</div>
                <div class="flex flex-col w-1/12">{{ event.language }}</div>
                <div class="flex flex-col w-5/12">{{ event.name }}</div>
                <div class="flex flex-col w-3/12">
                    {{
                        new Date(event.date_time).toLocaleString("es-ES", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                        })
                    }}
                </div>
                <div
                    class="flex flex-col justify-end w-2/12 items-end text-2xl mr-2 cursor-pointer"
                    @click="editEvent(event.url)"
                >
                    <inline-svg
                        :src="require('@/assets/icons/pen-to-square-regular.svg')"
                        class="w-6 h-6 fill-orange-intense mt-2 -z-10"
                    />
                </div>
                <div class="flex flex-col mr-2 cursor-pointer" @click="goDashboard(event.url)">
                    <inline-svg
                        :src="require('@/assets/icons/arrow-up-right-from-square-solid.svg')"
                        class="w-6 h-6 fill-orange-intense mt-2 -z-10"
                    />
                </div>
                <div class="flex flex-col cursor-pointer" @click="deleteEvent(event.name, event.url)">
                    <inline-svg
                        :src="require('@/assets/icons/trash-solid.svg')"
                        class="w-6 h-6 fill-orange-intense mt-2 -z-10"
                    />
                </div>
            </div>
        </div>
    </span>
</template>

<script>
const components = ["Scoreboard", "Quiz", "FairPrice", "Timeline"];

import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

export default {
    props: {},
    components: {},
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const user = ref({
            email: "",
            password: "",
        });
        const showRealEvents = ref(false);
        const showPastEvents = ref(false);
        const login = () => {
            store.dispatch("login", user.value);
        };
        const events = ref(null);
        const eventsDefault = ref(null);

        const getEvents = async (allEvents = false) => {
            events.value = await axiosToken({
                method: "get",
                body: { allEvents: allEvents },
                url: "event/event_basic",
                authorization: true,
            });
            events.value = events.value.data;
            eventsDefault.value = events.value;
            console.log(events.value);
        };

        const filterUpdate = async () => {
            await getEvents(showPastEvents.value);
            console.log("here");
            if (showRealEvents.value) {
                events.value = eventsDefault.value.filter((ed) => ed.real_event == true);
            } else {
                events.value = eventsDefault.value;
            }
        };

        const changeShowRealEvents = () => {
            showRealEvents.value = !showRealEvents.value;
            showRealEvents.value;
            filterUpdate();
        };

        const changeShowPastEvents = () => {
            showPastEvents.value = !showPastEvents.value;
            filterUpdate();
        };

        const goDashboard = (url) => {
            router.push("/dashboard/" + url);
        };

        const deleteEvent = async (name, url) => {
            var ok = window.confirm("Are you sure you want to delete the event " + name + "?");
            if (ok) {
                var result = await axiosToken({
                    method: "delete",
                    url: "event/event/" + url,
                    authorization: true,
                });
            }
            getEvents();
        };

        const editEvent = (url) => {
            router.push("/dashboard/event/upsert/" + url);
        };

        const addEvent = () => {
            router.push("/dashboard/event/upsert/0");
        };

        onMounted(() => {
            getEvents();
        });

        return {
            events,
            goDashboard,
            editEvent,
            deleteEvent,
            addEvent,
            showRealEvents,
            showPastEvents,
            changeShowPastEvents,
            changeShowRealEvents,
        };
    },
};
</script>

<style scoped>
.main-background {
    background-image: url("@/assets/images/Purple Abstract Background.png");
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

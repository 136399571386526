<template>
    <span class="flex flex-col w-10/12" v-if="themes">
        <div class="flex flex-col">
            <div class="flex flex-row">
                <div class="flex flex-col w-1/12 font-title text-orange-intense">Id</div>
                <div class="flex flex-col w-2/12 font-title text-orange-intense">Code</div>
                <div class="flex flex-col w-7/12 font-title text-orange-intense">Name</div>
                <div class="flex flex-col justify-end w-2/12 items-end text-2xl cursor-pointer" @click="addTheme">
                    <div
                        class="flex flex-row items-center justify-center border-2 border-solid border-orange-intense p-1 px-2 rounded-xl hover:border-white"
                    >
                        <span>Add Theme</span>
                        <inline-svg
                            :src="require('@/assets/icons/plus-solid.svg')"
                            class="w-6 h-6 fill-orange-intense ml-2 -z-10"
                        />
                    </div>
                </div>
            </div>
        </div>
        <hr class="w-full" />
        <div class="flex flex-col text-2xl" v-if="themes">
            <div
                class="flex flex-row"
                v-for="theme in themes.sort((a, b) => {
                    return b.id - a.id;
                })"
                :key="theme"
            >
                <div class="flex flex-col w-1/12">{{ theme.id }}</div>
                <div class="flex flex-col w-2/12">{{ theme.code }}</div>
                <div class="flex flex-col w-7/12">{{ theme.name }}</div>
                <div
                    class="flex flex-col justify-end w-2/12 items-end text-2xl mr-2 cursor-pointer"
                    @click="editTheme(theme.id)"
                >
                    <inline-svg
                        :src="require('@/assets/icons/pen-to-square-regular.svg')"
                        class="w-6 h-6 fill-orange-intense mt-2 -z-10"
                    />
                </div>
            </div>
        </div>
    </span>
</template>

<script>
const components = ["Scoreboard", "Quiz", "FairPrice", "Timeline"];

import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

export default {
    props: {},
    components: {},
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const themes = ref(null);

        const getThemes = async () => {
            themes.value = await axiosToken({
                method: "get",
                url: "event/theme",
                authorization: true,
            });

            themes.value = themes.value.data;
        };

        const editTheme = (url) => {
            router.push("/dashboard/theme/upsert/" + url);
        };

        const addTheme = () => {
            router.push("/dashboard/theme/upsert/0");
        };

        onMounted(() => {
            getThemes();
        });

        return { themes, editTheme, addTheme };
    },
};
</script>

<style scoped>
.main-background {
    background-image: url("@/assets/images/Purple Abstract Background.png");
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

import { createStore } from 'vuex'
import storeUserData from './storeUserData.js'
import storeGamesData from './storeGamesData.js'
import storeEventData from './storeEventData.js'
import storeContentData from './storeContentData.js'
import storeAuth from './storeAuth.js'
const store = createStore({
    modules: {
        storeUserData,
        storeGamesData,
        storeEventData,
        storeContentData,
        storeAuth,
    }
})

export function useStore() {
    return store
}

export default store

<template>
    <div class="dashboard-card p-5 h-full">
        <h1 class="text-center">{{ $store.state.storeEventData.name }}</h1>
        <SelectGames />
        <button v-on:click="openInNewTab()" class="dashboard-btn my-2">GM VIEW</button>
        <button v-on:click="openInNewTabPlayer()" class="dashboard-btn mb-2">Player VIEW</button>

        <div v-if="!changeImages">
            <h4 class="cursor-pointer" @click="changeImages = !changeImages">Change images</h4>
        </div>
        <div v-else>
            <h4 class="cursor-pointer" @click="changeImages = !changeImages">Close change images</h4>
            <h4 class="text-center">Change image with bg</h4>
            <input
                @change="uploadImage('bg')"
                ref="img_bg"
                type="file"
                id="img_bg"
                name="img_bg"
                accept="image/png, image/jpeg"
            />
            <h4 class="text-center">Change image no bg</h4>
            <input
                @change="uploadImage('no_bg')"
                ref="img_no_bg"
                type="file"
                id="img_no_bg"
                name="img_no_bg"
                accept="image/png, image/jpeg"
            />
        </div>

        <br />
        <hr class="m-2" />
        <h4 class="text-center" v-if="$store.state.storeEventData.event_state == 0">Waiting Players</h4>
        <h4 class="text-center" v-if="$store.state.storeEventData.event_state == 1">Lobby Team</h4>
        <h4 class="text-center" v-if="$store.state.storeEventData.event_state == 2">Playing</h4>
        <div
            class="dashboard-card-2 p-2 text-center my-3 flex flex-col gap-2"
            v-if="$store.state.storeEventData.event_state == 0"
        >
            <h2>Locations</h2>
            <div v-if="$store.state.storeEventData.event_location.length > 0" class="flex flex-col gap-1">
                <div
                    v-for="loc in $store.state.storeEventData.event_location"
                    :key="loc"
                    class="dashboard-card-3 flex flex-row gap-2 items-start p-1"
                >
                    <p>
                        {{ loc.name }}
                    </p>
                    <inline-svg
                        :src="require('@/assets/icons/trash-solid.svg')"
                        class="w-6 h-6 ml-auto cursor-pointer my-auto"
                        v-on:click="removeLocation(loc.id)"
                    />
                </div>
            </div>
            <div v-else>
                <p>NO locations</p>
            </div>
            <input class="dashboard-input" v-model="locationName" />
            <button v-on:click="newLocation()" class="dashboard-btn">Crear Localizacion</button>
            <div class="flex flex-col gap-2 mb-5">
                <h4>Team size</h4>
                <div
                    class="flex flex-row m-0 gap-2"
                    v-for="loc in $store.state.storeEventData.event_location"
                    :key="loc"
                >
                    <p class="w-full">
                        {{ loc.name }}
                    </p>
                    <input class="dashboard-input w-1/4" type="number" v-model="teamSize[loc.id]" />
                </div>
                <p>
                    {{ teamSize }}
                </p>

                <!-- <button class="dashboard-btn">Crear Equipo</button> -->
                <button class="dashboard-btn" v-on:click="createTeams()" :disabled="waitingTeams">
                    Crear los Equipos
                </button>
            </div>
        </div>
        <div
            class="flex flex-col gap-3"
            v-if="$store.state.storeEventData.event_state >= 1 && $store.state.storeEventData.event_state <= 2"
        >
            <button class="dashboard-btn" v-on:click="startEvent()" v-if="$store.state.storeEventData.event_state == 1">
                Start Game
            </button>
            <div class="dashboard-card-2 p-2 flex flex-col gap-2">
                <button class="dashboard-btn" v-on:click="setIsModalVisibleTeam(true)">Create Team</button>
                <button class="dashboard-btn" v-on:click="setIsModalVisiblePlayer(true)">Add User</button>
            </div>
        </div>
        <div v-else-if="$store.state.storeEventData.event_state == 3" class="flex flex-col gap-3">
            <h4 class="my-1">Event finished</h4>
        </div>
        <div class="flex flex-col gap-1 relative overflow-auto" v-if="$store.state.storeEventData.event_state == 2">
            <!-- game estate -->
            <h2 class="my-1 mt-3">Game State</h2>
            <h4 class="my-1" v-if="$store.state.storeEventData.game_state == 0">Waiting Select Game</h4>
            <h4 class="my-1" v-if="$store.state.storeEventData.game_state == 1">Spinning</h4>
            <h4 class="my-1" v-if="$store.state.storeEventData.game_state == 2">Waiting Game to start</h4>
            <button
                v-if="$store.state.storeEventData.game_state == 2"
                v-on:click="StartGamePlay()"
                class="dashboard-btn"
            >
                Start Game
            </button>
            <button v-if="$store.state.storeEventData.game_state == 1" v-on:click="nextState()" class="dashboard-btn">
                Show Game Rules
            </button>
            <h4 class="my-1" v-if="$store.state.storeEventData.game_state == 3">Playing</h4>
            <GameInfo v-if="$store.state.storeEventData.game_state == 3" />
            <button class="dashboard-btn" v-on:click="returnLobby()" v-if="$store.state.storeEventData.game_state > 0">
                Return Lobby
            </button>

            <h2>Start new game</h2>
            <label for="">Select new game</label>
            <select class="dashboard-selector" id="" v-model="gameSelected">
                <option value="none">Select</option>
                <option v-for="game in $store.state.storeEventData.roulette_games" :key="game" :value="game.id">
                    {{ game.name }}
                </option>
            </select>
            <label for="">Select Boost</label>
            <select class="dashboard-selector" id="" v-model="boostSelected">
                <option value="none">None</option>
                <option v-for="boost in boosts" :key="boost" :value="boost.id">{{ boost.name_id }}</option>
            </select>
            <button v-on:click="SelectGame()" class="dashboard-btn mt-2">Start New Game</button>
            <button v-on:click="finishEvent()" class="dashboard-btn mt-12">Finish Event</button>
        </div>
    </div>
    <ModalDashboard
        :isModalVisible="isModalVisiblePlayer"
        :isModalVisibleEdit="setIsModalVisiblePlayer"
        :saveFunction="addNewPlayer"
    >
        <h4 class="text-black text-center">Añadir Jugador</h4>
        <input class="dashboard-input" v-model="newPlayerName.name" />
        <label for="">Equipo</label>
        <select class="dashboard-selector" id="" v-model="newPlayerName.team">
            <option v-for="team in $store.state.storeEventData.event_team" :key="team" :value="team.id">
                {{ team.name }}
            </option>
        </select>
    </ModalDashboard>
    <ModalDashboard
        :isModalVisible="isModalVisibleTeam"
        :isModalVisibleEdit="setIsModalVisibleTeam"
        :saveFunction="addNewTeam"
    >
        <h4 class="text-black text-center">New Team</h4>
        <input class="dashboard-input" v-model="newTeam.name" />
        <label for="">Equipo</label>
        <select class="dashboard-selector" id="" v-model="newTeam.location">
            <option v-for="loc in $store.state.storeEventData.event_location" :key="loc" :value="loc.id">
                {{ loc.name }}
            </option>
        </select>
    </ModalDashboard>
</template>

<script>
import { ref } from "@vue/reactivity";
import { axiosToken } from "@/libs/http";
import { useStore } from "vuex";
import { computed, onMounted, watch } from "@vue/runtime-core";
import { notify } from "notiwind";
import GameInfo from "@/components/dashboard/GameInfo.vue";
import SelectGames from "@/components/dashboard/SelectGames.vue";
import ModalDashboard from "./ModalDashboard.vue";
import { useState } from "@/hooks/state";

export default {
    props: {},
    components: { GameInfo, SelectGames, ModalDashboard },
    setup(props) {
        const locationName = ref("");
        const store = useStore();
        const games = ref([]);
        const boosts = ref([]);
        const content = ref([]);
        const boostSelected = ref("none");
        const gameSelected = ref("none");
        const contentSelected = ref("none");
        const teamSize = ref({});
        const locations = computed(() => store.state.storeEventData.event_location);
        const waitingTeams = ref(false);
        const changeImages = ref(false);
        const img_bg = ref(null);
        const img_no_bg = ref(null);
        // watch(locations, () => {
        //     console.log("hola");
        //     for (var x = 0; x < store.state.storeEventData.event_location.length; x++) {
        //         teamSize.value[store.state.storeEventData.event_location[x].id] = 0;
        //     }
        // });

        onMounted(() => {
            getBoost();
            console.log(locations);
            for (var x = 0; x < store.state.storeEventData.event_location.length; x++) {
                teamSize.value[store.state.storeEventData.event_location[x].id] = 0;
            }
        });
        const newLocation = async () => {
            if (locationName.value == "") {
                // add eerror
                return;
            }
            const dataEvent = await axiosToken({
                url: "event/location",
                body: {
                    name: locationName.value,
                    event: store.state.storeEventData.id,
                },
                method: "post",
                authorization: true,
            });
            locationName.value = "";
            teamSize.value[dataEvent.data.id] = 0;
            store.commit("setAddLocation", dataEvent.data);
        };

        const removeLocation = async (id) => {
            const dataEvent = await axiosToken({
                url: "event/location/" + id,
                method: "delete",
                authorization: true,
            });
            locationName.value = "";
            console.log(dataEvent);
            store.commit("setRemoveLocation", id);
            delete teamSize.value[id];
        };

        const getBoost = async () => {
            const data = await axiosToken({
                url: "gamecontent/boost",
                method: "get",
                authorization: true,
            });
            boosts.value = data.data;
        };

        const uploadImage = async (type) => {
            var file = null;
            var formData = new FormData();

            if (type == "bg") {
                file = img_bg.value.files;
            } else {
                file = img_no_bg.value.files;
            }

            formData.append("file", file[0]);
            formData.append("theme", store.state.storeEventData.theme.id);
            formData.append("type", type);

            const dataEvent = await axiosToken({
                url: "event/theme",
                body: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                method: "post",
                authorization: true,
            });
        };

        const createTeams = () => {
            waitingTeams.value = true;
            console.log("iiin");
            for (var key in teamSize.value) {
                if (teamSize.value[key] == 0) {
                    notify(
                        {
                            group: "error",
                            title: "Error",
                            text: "Los equipos no pueden ser de 0 jugadores",
                        },
                        2000
                    );
                    waitingTeams.value = false;
                    return;
                }
            }
            console.log("iiin");
            const data = axiosToken({
                url: "event/createTeams",
                method: "post",
                authorization: true,
                body: {
                    team_players: teamSize.value,
                    id_event: store.state.storeEventData.id,
                },
            });
        };

        const startEvent = () => {
            const data = axiosToken({
                url: "gamemanager/startEvent/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
            });
        };

        const addNewTeam = async () => {
            if (newTeam.value.name == "") {
                notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "Introduce nombre de equipo",
                    },
                    2000
                );

                return;
            }

            const dataEvent = await axiosToken({
                url: "event/team",
                body: {
                    name: newTeam.value.name,
                    event: store.state.storeEventData.id,
                    location: newTeam.value.location,
                },
                method: "post",
                authorization: true,
            });

            store.commit("setNewTeam", dataEvent.data);
            newTeam.value = {
                location: "",
                name: "",
            };
        };

        // Start Game
        const SelectGame = async () => {
            if (gameSelected.value == "none") {
                notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "Ningun juego selecionado o contenido",
                    },
                    2000
                );

                return;
            }

            let body = {
                content_id: gameSelected.value,
            };
            if (boostSelected.value != "none") {
                body.boost_id = boostSelected.value;
            }

            const dataEvent = await axiosToken({
                url: "gamemanager/chooseGame/" + store.state.storeEventData.url,
                body,
                method: "post",
                authorization: true,
            });
        };

        const finishEvent = async () => {
            const dataEvent = await axiosToken({
                url: "gamemanager/finishEvent/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
            });
        };

        const StartGamePlay = async () => {
            const dataEvent = await axiosToken({
                url: "gamemanager/startGame/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
            });
        };

        const nextState = async () => {
            const dataEvent = await axiosToken({
                url: "gamemanager/updateGame/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
            });
        };

        const returnLobby = async () => {
            const dataEvent = await axiosToken({
                url: "gamemanager/returnLobby/" + store.state.storeEventData.url,
                method: "post",
                authorization: true,
            });
        };

        // add new player
        const [isModalVisibleTeam, setIsModalVisibleTeam] = useState(false);
        const newTeam = ref({
            location: "",
            name: "",
        });

        const [isModalVisiblePlayer, setIsModalVisiblePlayer] = useState(false);
        const newPlayerName = ref({
            team: "",
            name: "",
        });
        const addNewPlayer = async () => {
            if (newPlayerName.value.name == "" || newPlayerName.value.team == "") {
                notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "Faltan datos",
                    },
                    2000
                );
            }

            const dataEvent = await axiosToken({
                url: "event/player_gm",
                method: "post",
                authorization: true,
                body: {
                    name: newPlayerName.value.name,
                    team: newPlayerName.value.team,
                    event: store.state.storeEventData.id,
                },
            });
            newPlayerName.value = {
                team: "",
                name: "",
            };
        };

        function openInNewTab() {
            window.open(window.location.origin + "/" + store.state.storeEventData.url + "?view=gm", "_blank");
        }

        function openInNewTabPlayer() {
            window.open(window.location.origin + "/" + store.state.storeEventData.url, "_blank");
        }

        return {
            locationName,
            newLocation,
            removeLocation,
            getBoost,
            createTeams,
            games,
            boosts,
            content,
            boostSelected,
            contentSelected,
            gameSelected,
            teamSize,
            waitingTeams,
            startEvent,
            addNewTeam,
            SelectGame,
            StartGamePlay,
            returnLobby,
            isModalVisiblePlayer,
            setIsModalVisiblePlayer,
            addNewPlayer,
            newPlayerName,
            openInNewTab,
            openInNewTabPlayer,
            isModalVisibleTeam,
            setIsModalVisibleTeam,
            newTeam,
            nextState,
            uploadImage,
            changeImages,
            img_bg,
            img_no_bg,
            finishEvent,
        };
    },
};
</script>

<style></style>

<template>
    <div class="flex flex-col items-center justify-center my-auto h-full xl:h-screen gap-6">
        <h1 class="font-title text-orange-intense" v-if="$route.params.id == '0'">Create new theme</h1>
        <h1 class="font-title text-orange-intense" v-else>Update theme {{ themeData.name }}</h1>
        <div class="flex flex-col xl:flex-row w-full xl:items-start items-center justify-center gap-10">
            <div class="flex flex-col w-10/12 xl:w-5/12 justify-center items-center gap-6">
                <div class="flex flex-row w-full gap-6">
                    <div class="flex flex-col gap-4 w-6/12">
                        <label class="w-full font-title text-orange-intense text-xl" :for="theme.name">Name </label>
                        <input class="p-1 text-lg rounded-xl" type="text" v-model="theme.name" />
                    </div>
                    <div class="flex flex-col gap-4 w-6/12">
                        <label class="w-full font-title text-orange-intense text-xl" :for="theme.code">Code </label>
                        <input
                            class="p-1 text-lg rounded-xl"
                            type="text"
                            v-model="theme.code"
                            :readonly="$route.params.id != 0"
                        />
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_font_color"
                        >Main font color (big screen)
                    </label>
                    <div class="flex flex-row items-center justify-start gap-2">
                        <input class="p-1 text-lg rounded-xl w-4/12" type="text" v-model="theme.main_font_color" />
                        <input class="h-10 text-lg rounded-xl w-2/12" type="color" v-model="theme.main_font_color" />
                        <span class="w-6/12 text-center font-title" :style="'color: ' + theme.main_font_color">
                            Example
                        </span>
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_shadow_color"
                        >Main shadow color (big screen)
                    </label>
                    <div class="flex flex-row items-center justify-start gap-2">
                        <input class="p-1 text-lg rounded-xl w-4/12" type="text" v-model="theme.main_shadow_color" />
                        <input class="h-10 text-lg rounded-xl w-2/12" type="color" v-model="theme.main_shadow_color" />
                        <span
                            class="w-6/12 text-center font-title"
                            :style="
                                'color: ' +
                                theme.main_font_color +
                                '; text-shadow: 1px 1px 2px ' +
                                theme.main_shadow_color +
                                ';'
                            "
                        >
                            Example
                        </span>
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.font_color"
                        >Font color (small screen)
                    </label>
                    <div class="flex flex-row items-center justify-start gap-2">
                        <input class="p-1 text-lg rounded-xl w-4/12" type="text" v-model="theme.font_color" />
                        <input class="h-10 text-lg rounded-xl w-2/12" type="color" v-model="theme.font_color" />
                        <span class="w-6/12 text-center font-title" :style="'color: ' + theme.font_color">Example</span>
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.shadow_color"
                        >Shadow color (small screen)
                    </label>
                    <div class="flex flex-row items-center justify-start gap-2">
                        <input class="p-1 text-lg rounded-xl w-4/12" type="text" v-model="theme.shadow_color" />
                        <input class="h-10 text-lg rounded-xl w-2/12" type="color" v-model="theme.shadow_color" />
                        <span
                            class="w-6/12 text-center font-title"
                            :style="
                                'color: ' + theme.font_color + '; text-shadow: 1px 1px 2px ' + theme.shadow_color + ';'
                            "
                            >Example</span
                        >
                    </div>
                </div>

                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.background"
                        >Background (small screen)
                        <inline-svg
                            :src="require('@/assets/icons/circle-question-solid.svg')"
                            class="bg-transparent h-5 w-5 fill-orange-intense cursor-pointer"
                            alt="Linear gradient generator"
                            @:click="gradientHelp()"
                        />
                    </label>
                    <div class="flex flex-row items-center justify-start gap-2">
                        <input class="p-1 text-lg rounded-xl w-6/12" type="text" v-model="theme.background" />
                        <span class="flex flex-col w-6/12 rounded-xl items-center justify-center">
                            <span
                                class="h-8 w-8/12 rounded-xl border-4 border-solid border-white"
                                :style="'background: ' + theme.background + ';'"
                            ></span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-10/12 xl:w-5/12 justify-center items-center gap-6">
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_bg">
                        Main background
                    </label>
                    <div class="flex flex-row w-full items-center justify-center xl:justify-start gap-6">
                        <input
                            @change="loadImage($event, 'main_bg')"
                            type="file"
                            id="main_bg"
                            name="main_bg"
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        <img
                            v-if="theme.main_bg"
                            class="border-dashed border-2 border-white h-32 w-6/12 cursor-pointer"
                            id="main_bg_img"
                            :src="theme.main_bg"
                            alt=""
                            @click="inputClick('main_bg')"
                        />
                        <div
                            v-else
                            class="border-dashed border-2 border-white h-32 w-6/12 cursor-pointer"
                            @click="inputClick('main_bg')"
                        ></div>
                    </div>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_bg">
                        Logo with background
                    </label>
                    <div class="flex flex-row w-full items-center justify-center xl:justify-start gap-6">
                        <input
                            @change="loadImage($event, 'logo_bg')"
                            type="file"
                            id="logo_bg"
                            name="logo_bg"
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        <div class="flex flex-row items-center justify-center w-6/12">
                            <img
                                v-if="theme.logo_bg"
                                class="border-dashed border-2 border-white h-20 w-40 cursor-pointer"
                                id="logo_bg_img"
                                :src="theme.logo_bg"
                                alt=""
                                @click="inputClick('logo_bg')"
                            />
                            <div
                                v-else
                                class="border-dashed border-2 border-white h-20 w-40 cursor-pointer"
                                @click="inputClick('logo_bg')"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_bg">
                        Logo without background
                    </label>
                    <div class="flex flex-row w-full items-center justify-center xl:justify-start gap-6">
                        <input
                            @change="loadImage($event, 'logo_no_bg')"
                            type="file"
                            id="logo_no_bg"
                            name="logo_no_bg"
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        <div class="flex flex-row items-center justify-center w-6/12">
                            <img
                                v-if="theme.logo_no_bg"
                                class="border-dashed border-2 border-white h-20 w-40 cursor-pointer"
                                id="logo_no_bg_img"
                                :src="theme.logo_no_bg"
                                alt=""
                                @click="inputClick('logo_no_bg')"
                            />
                            <div
                                v-else
                                class="border-dashed border-2 border-white h-20 w-40 cursor-pointer"
                                @click="inputClick('logo_no_bg')"
                            />
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense text-xl" :for="theme.main_bg">
                        Points icon
                    </label>
                    <div class="flex flex-row w-full items-center justify-center xl:justify-start gap-6">
                        <input
                            @change="loadImage($event, 'points_icon')"
                            type="file"
                            id="points_icon"
                            name="points_icon"
                            accept="image/png, .svg"
                        />
                        <div class="flex flex-row items-center justify-center w-6/12">
                            <img
                                v-if="theme.points_icon"
                                class="border-dashed border-2 border-white h-16 w-16 cursor-pointer"
                                id="points_icon_img"
                                :src="theme.points_icon"
                                alt=""
                                @click="inputClick('points_icon')"
                            />
                            <div
                                v-else
                                class="border-dashed border-2 border-white h-16 w-16 cursor-pointer"
                                @click="inputClick('points_icon')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full items-center justify-center gap-6">
            <button
                class="btn-outlined hover:bg-orange-intense hover:border-white w-4/12 xl:w-2/12"
                @click="$router.push({ name: 'dashboardNOurl' })"
            >
                Back
            </button>
            <button class="btn w-4/12 xl:w-2/12 hover:border-white" @click="saveTheme">Save</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

export default {
    props: {
        data: { type: Object, default: {} },
        url: { type: String, default: "" },
    },
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const languageSelect = ref([
            { name: "es", key: "es" },
            { name: "en", key: "en" },
            //{ name: "ca", key: "ca" },
        ]);
        const themeSelect = ref([]);
        const themeData = ref([]);

        const theme = ref({
            name: "",
            code: "",
            main_font_color: "#FFA724",
            main_shadow_color: "#132457",
            font_color: "#FFA724",
            shadow_color: "#132457",
            background: "linear-gradient(to bottom, #132457, #252b3b)",
            main_bg: null,
            logo_bg: null,
            logo_no_bg: null,
            points_icon: null,
        });

        onMounted(async () => {
            if (route.params.id != 0) {
                await getThemeData();
                console.log(themeData.value);
                theme.value.name = themeData.value.name;
                theme.value.code = themeData.value.code;
                theme.value.main_bg = themeData.value.main_background;
                theme.value.logo_bg = themeData.value.logo;
                theme.value.logo_no_bg = themeData.value.logo_no_bg;
                theme.value.main_font_color = themeData.value.main_font_color;
                theme.value.main_shadow_color = themeData.value.main_shadow_color;
                theme.value.font_color = themeData.value.font_color;
                theme.value.shadow_color = themeData.value.shadow_color;
                theme.value.background = themeData.value.background_right;
                theme.value.points_icon = themeData.value.points_icon;
            }
            getThemeData();
        });

        const getThemeData = async () => {
            themeData.value = await axiosToken({
                method: "get",
                url: "event/theme/" + route.params.id,
                authorization: true,
            });

            themeData.value = themeData.value.data;
            console.log(themeData.value);
        };

        const saveTheme = async () => {
            console.log("savetheme");
            var formData = new FormData();

            if (route.params.id != 0) {
                formData.append("id", route.params.id);
            }
            formData.append("name", theme.value.name);
            formData.append("code", theme.value.code);
            console.log(typeof theme.value.main_bg === "object");
            if (typeof theme.value.main_bg === "object") {
                formData.append("main_background", theme.value.main_bg);
            }
            if (typeof theme.value.logo_bg === "object") {
                formData.append("logo", theme.value.logo_bg);
            }
            if (typeof theme.value.logo_no_bg === "object") {
                formData.append("logo_no_bg", theme.value.logo_no_bg);
            }
            formData.append("main_font_color", theme.value.main_font_color);
            formData.append("main_shadow_color", theme.value.main_shadow_color);
            formData.append("font_color", theme.value.font_color);
            formData.append("shadow_color", theme.value.shadow_color);
            formData.append("background_right", theme.value.background);
            if (typeof theme.value.points_icon === "object") {
                formData.append("points_icon", theme.value.points_icon);
            }
            const dataEvent = await axiosToken({
                url: "event/theme".concat(route.params.id != 0 ? "/".concat(route.params.id) : ""),
                body: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                method: route.params.id != 0 ? "put" : "post",
                authorization: true,
            });
            console.log(dataEvent);
        };

        const gradientHelp = () => {
            window.open("https://cssgradient.io/");
        };

        const loadImage = async (event, type) => {
            var files = event.target.files;
            if (type == "main_bg") {
                theme.value.main_bg = files[0];
            } else if (type == "logo_bg") {
                theme.value.logo_bg = files[0];
            } else if (type == "logo_no_bg") {
                theme.value.logo_no_bg = files[0];
            } else if (type == "points_icon") {
                theme.value.points_icon = files[0];
            }

            console.log(theme.value);

            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    document.getElementById(type + "_img").src = fr.result;
                };
                fr.readAsDataURL(files[0]);
            } else {
            }
        };

        const inputClick = (input_id) => {
            document.getElementById(input_id).click();
        };

        return { languageSelect, themeSelect, theme, themeData, saveTheme, gradientHelp, loadImage, inputClick };
    },
};
</script>

<style scoped></style>

import { axiosToken } from "@/libs/http";
import { startSocketTeam } from "@/libs/ws";
import store from ".";
const storeEvent = {
    state() {

        return {
            id: null,
            name: null,
            datetime: null,
            url: null,
            language: null,
            theme: null,
            game_state: null,
            event_state: null,
            event_team: [],
            event_team_id_sorted: [],
            event_team_points_sorted: [],
            event_team_points_game_sorted: [],
            event_player: [],
            event_location: [],
            onlinePlayers: {},
            ingame_state: 0,
            game_state: 0,
            counter_game: 0,
            start_timer: null,
            end_timer: null,
            game_data: {},
            shared_content: {},
            current_game: {},
            current_game_content: {},
            current_boost: {},
            roulette_games: [],
            roulette: [],
            playerId: null,
            loadedEventInfo: false,
            errorEventInfo: false,

            websocket: null,
            reactionsSend: [],
            reactions: [],
            websocketTeam: null,
            websocketTest: null,
            selectedAvatar: 0,
            avatarArray: [],
            showAnimationPoint: false,

            gmView: false,

            rating: {
                game: 0,
                fun: 0,
                gm: 0,
                repeat: 0,
                comments: ''
            }

        }
    },
    getters: {
        getGameSelected: (state) => (id) => {
            const selectedGame = state.roulette_games.filter(e => e.id == id)
            if (selectedGame.length > 0) {
                return true
            }
            return false
        },
        getGameInfoRoulette(state) {
            var gamesRoulette = []
            for (var x = 0; x < state.roulette.length; x++) {
                console.log(state.roulette[x])
                gamesRoulette.push(state.roulette_games.find(e => e.id == state.roulette[x]))
            }
            console.log(gamesRoulette)
            return gamesRoulette
        },
        getRanking(state) {
            return state.event_team.sort(function (a, b) {
                return b.points - a.points || a.name.localeCompare(b.name);
            });
        },
        getRankingLimited(state) {
            return state.event_team.sort(function (a, b) {
                return b.points - a.points || a.name.localeCompare(b.name);
            }).slice(0, 5);
        },
        getRankingPartialLimited(state) {
            return state.event_team.sort(function (a, b) {
                return b.points_current_game - a.points_current_game || a.name.localeCompare(b.name);
            }).slice(0, 5);
        },
        getReactionById: (state) => (id) => {
            return state.reactions.find(e => e.id == id)
        },
        getTeamPlayer: (state) => (id) => {
            console.log(state.event_team, id)
            console.log(state.event_team.find(e => e.id == id))
            return state.event_team.find(e => e.id == id)
        },
        getTotalTimer(state) {
            const total_timer = new Date(state.end_timer).getTime() - new Date(state.start_timer).getTime();
            return Math.ceil(total_timer / 1000)

        }
        ,
        getTimer(state) {
            const current_timer = new Date(state.end_timer).getTime() - new Date().getTime();
            return Math.ceil(current_timer / 1000)
        },
        getShowPoints(state) {
            return state.event_team.find(e => e.id == state.playerId.team).current_state

        },
        getQuizResult: (state) => (id) => {
            if (state.game_data.results_show[id] == 0) {
                return 0;
            }
            const sumResponses = Object.values(state.game_data.results_show).reduce((a, b) => a + b, 0);
            return ((state.game_data.results_show[id] / sumResponses) * 100).toFixed(2)
        }
        , getTeamById: (state) => (id) => {
            return state.event_team.find(e => e.id == id)
        },
        getPlayerLocation: (state) => (id) => {
            return state.event_player.filter(e => e.location == id).length
        },
        getGmView(state) {
            return state.gmView;
        },
        getOrderTeamById(state) {
            const team_order = state.event_team
            console.log(team_order)
            return team_order.sort((a, b) => a.id - b.id)
        },

    },
    mutations: {
        setGmView(state, data) {
            state.gmView = data;
        },
        setRating(state, data) {
            console.log(data)
            state.rating[data.name] = data.value;
        },
        setAnimationPoints(state) {
            state.showAnimationPoint = true
            setTimeout(() => {
                state.showAnimationPoint = false
            }, 6000)
        },
        setAvatarArray(state, data) {
            state.avatarArray = data
        },
        setAvatar(state, data) {
            state.selectedAvatar = data
        },
        addReactions(state, data) {
            state.reactions = data
        },
        setAddReaction(state, data) {
            state.reactionsSend.push({
                id: data,
                place: parseInt(Math.random() * (75 - 25) + 25),

            })

            setTimeout(() => {
                state.reactionsSend.shift();
            }, 3000)
        },
        setWebSocket(state, data) {
            state.websocket = data
        },
        setName(state, data) {
            console.log(data)
            state.name = data.value;
        },
        setTeamName(state, data) {
            console.log(data)
            const team = data.team
            const new_name = data.new_name
            state.event_team.find((t) => (t.id == team.id)).nameShow = new_name
            //TODO send it to the DB or/and Socket
        },
        async loadStoreEventData(state, url) {
            return await axiosToken({
                method: "get",
                url: "event/event" + url,
            }).then((response) => {
                const data = response.data;

                console.log("loadStoreEventData")
                console.log(data)
                state.id = data.id;
                state.name = data.name;
                state.datetime = data.datetime;
                state.url = data.url;
                state.language = data.language;
                state.theme = data.theme;
                state.game_state = data.game_state;
                state.event_state = data.event_state;
                state.event_team = data.event_team.slice().sort(function (a, b) {
                    return a.id - b.id || a.name.localeCompare(b.name);
                });
                state.event_team_id_sorted = data.event_team.slice().sort(function (a, b) {
                    return a.id - b.id || a.name.localeCompare(b.name);
                });
                state.event_player = data.event_player.slice().sort(function (a, b) {
                    return a.id - b.id || a.name.localeCompare(b.name);
                });
                state.event_location = data.event_location.slice().sort(function (a, b) {
                    return a.id - b.id || a.name.localeCompare(b.name);
                });
                state.event_team_points_sorted = data.event_team.slice().sort(function (a, b) {
                    return b.points - a.points || a.name.localeCompare(b.name);
                });
                state.event_team_points_game_sorted = data.event_team.slice().sort(function (a, b) {
                    return b.points_current_game - a.points_current_game || a.name.localeCompare(b.name);
                });




                state.ingame_state = data.ingame_state;
                state.game_state = data.game_state;
                state.counter_game = data.counter_game;
                state.start_timer = data.start_timer;
                state.end_timer = data.end_timer;
                state.game_data = data.game_data;
                state.shared_content = data.shared_content;
                state.current_game = data.current_game;
                state.current_boost = data.current_boost;
                state.loadedEventInfo = true;
                state.roulette_games = data.roulette_games;
                state.roulette = data.roulette;
                state.current_game_content = data.current_game_content;
                console.log("----------------------")
                //save player id
                if (localStorage.player_id) {
                    console.log(data.event_player.find(e => e.key == localStorage.player_id))
                    state.playerId = data.event_player.find(e => e.key == localStorage.player_id)
                    if (!state.playerId) {
                        localStorage.removeItem("player_id")
                        localStorage.removeItem("survey")
                    }
                }
                if (!state.websocketTeam) {
                    if (state.playerId) {
                        if (state.playerId.team) {
                            startSocketTeam(state.playerId.key, state.playerId.team)
                        }

                    }
                }



            }).catch((e) => {
                state.loadedEventInfo = true;
                state.errorEventInfo = true;
            });
        },
        setPlayerId(state, playerId) {
            state.playerId = state.event_player.find(e => e.key == playerId)
        },

        setEventState(state, new_state) {
            state.event_state = new_state
        },
        setAddLocation(state, new_location) {
            state.event_location.push(new_location)
        },
        setRemoveLocation(state, id) {
            function removeObjectWithId(arr, id) {
                const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
                arr.splice(objWithIdIndex, 1);

                return arr;
            }

            state.event_location = removeObjectWithId(state.event_location, id)
        },
        setUpdateEvent(state, data, root) {
            state.id = data.id;
            state.name = data.name;
            state.datetime = data.datetime;
            state.url = data.url;
            state.language = data.language;
            // state.theme = data.theme;
            state.game_state = data.game_state;
            state.event_state = data.event_state;
            state.event_team = data.event_team.slice().sort(function (a, b) {
                return a.id - b.id || a.name.localeCompare(b.name);
            });
            state.event_team_id_sorted = data.event_team.slice().sort(function (a, b) {
                return a.id - b.id || a.name.localeCompare(b.name);
            });
            state.event_player = data.event_player.sort(function (a, b) {
                return a.id - b.id || a.name.localeCompare(b.name);
            });
            state.event_location = data.event_location.sort(function (a, b) {
                return a.id - b.id || a.name.localeCompare(b.name);
            });
            state.ingame_state = data.ingame_state;
            state.game_state = data.game_state;
            state.counter_game = data.counter_game;
            state.start_timer = data.start_timer;
            state.end_timer = data.end_timer;
            state.game_data = data.game_data;
            state.shared_content = data.shared_content;
            state.current_game = data.current_game;
            state.current_boost = data.current_boost;
            state.roulette_games = data.roulette_games;
            state.roulette = data.roulette;
            state.current_game_content = data.current_game_content;
            state.event_team_points_sorted = data.event_team.slice().sort(function (a, b) {
                return b.points - a.points || a.name.localeCompare(b.name);
            });
            state.event_team_points_game_sorted = data.event_team.slice().sort(function (a, b) {
                return b.points_current_game - a.points_current_game || a.name.localeCompare(b.name);
            });
            state.playerId = data.event_player.find(e => e.key == localStorage.player_id)
            if (!state.websocketTeam) {
                if (state.playerId) {
                    if (state.playerId.team) {
                        startSocketTeam(state.playerId.key, state.playerId.team)
                    }
                }
            }


        },
        setWebSocketTeam(state, data) {
            state.websocketTeam = data;
        },
        setWebSocketTest(state, data) {
            state.websocketTest = data;
        },
        setOnlinePlayers(state, data) {
            state.onlinePlayers = data
        },
        setNewTeam(state, data) {
            state.event_team.push(data)
        },
        editTeam(state, newTeam) {
            const oldTeamIndex = state.event_team.find(e => e.id == newTeam.id)
            console.log(oldTeamIndex)
            oldTeamIndex.name = newTeam.name
        },
        editTeamPoints(state, newTeam) {
            const oldTeamIndex = state.event_team.find(e => e.id == newTeam.id)
            console.log(oldTeamIndex)
            oldTeamIndex.points = newTeam.points
        },
        editPlayer(state, newPlayer) {
            const oldPlayerIndex = state.event_player.find(e => e.id == newPlayer.id)
            console.log(oldPlayerIndex, state.event_player, newPlayer.id)
            oldPlayerIndex.name = newPlayer.name
            oldPlayerIndex.team = newPlayer.team

        },
    },
    actions: {
        setUpdateEvent({ dispatch, commit, state, rootState }, data) {
            if (state.ingame_state == 2 && data.ingame_state == 1 || data.ingame_state == 3) {
                dispatch("eraseAnswers");
            }
            commit("setUpdateEvent", data)
        }
    }


}

export default storeEvent
<template>
    <div class="flex flex-col items-center justify-center mx-2 top-0 px-4">
        <h1 class="text-center w-full text-6xl capitalize mb-1 custom-color">
            {{ $store.state.storeEventData.current_game_content.name }}
        </h1>
        <p class="text-center mx-10 text-xl font-bold custom-color">
            {{ $store.state.storeEventData.current_game_content.description }}
        </p>
        <div
            class="flex flex-col gap-4 text-left w-10/12 justify-center items-center rounded-3xl bg-blue-dark bg-opacity-80"
        >
            <img
                :class="$store.state.storeEventData.gmView ? 'w-10/12 mx-auto' : 'w-full'"
                :src="require('@/assets/tutorials/' + $store.state.storeEventData.current_game.name_id + '.gif')"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { watch, ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        /*onMounted(() => {
            console.log(store.state.storeEventData.current_game_content.background);
            if (store.state.storeEventData.current_game.background) {
                document.documentElement.style.setProperty(
                    "--bg-game-image",
                    store.state.storeEventData.current_game_content.background
                        ? "url(" + store.state.storeEventData.current_game_content.background + ")"
                        : "url(" + store.state.storeEventData.current_game.background + ")"
                );
            }
        });*/

        return {};
    },
};
</script>

<style scoped></style>

<template>
    <div class="absolute w-full h-screen overflow-hidden z-10" v-if="$store.state.storeEventData.showAnimationPoint">
        <div class="fix" id="float" v-if="$store.getters.getShowPoints > 0">
            <p class="text-3xl text-green-500 font-extrabold">+ {{ $store.getters.getShowPoints }} points</p>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
export default {
    setup() {
        const showPoints = ref(true);

        watch(
            () => showPoints,
            () => {}
        );

        return {};
    },
};
</script>
<style lang="scss" scoped>
.fix {
    position: relative;
    left: 30%;
    top: 50%;
}

#float {
    -webkit-animation: floatBubble 6s infinite normal ease-out;
    animation: floatBubble 6s infinite normal ease-out;
}
@-webkit-keyframes floatBubble {
    0% {
        top: 35%;
        opacity: 1;
    }
    100% {
        top: 100%;
        opacity: 0;
    }
}
@keyframes floatBubble {
    0% {
        top: 100%;
        opacity: 1;
    }
    100% {
        top: 35%;
        opacity: 0;
    }
}
</style>

import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL


const axiosToken = ({ url = url, body = {}, method = method, authorization = false, headers_ = {} }) => {

    var headers = authorization ? { authorization: "Token " + localStorage.user } : {}
    headers = { ...headers, ...headers_ }
    try {
        if (method == "delete") {
            return axios.delete(API_URL + url, {
                headers: headers,
                data: body
            })
        }
        else if (method == "get") {
            return axios.get(API_URL + url, {
                headers: headers,
                params: body
            })
        }
        return axios({
            method: method,
            url: API_URL + url,
            headers: headers,
            data: body
        })
    }
    catch (error) {
        console.log(error)
    }
}

export { axiosToken }
<template>
    <div class="flex font-title" id="countdown">
        <svg width="200" height="200">
            <circle class="circle" cx="100" cy="100" r="80" />
        </svg>
        <span class="timer">{{ timer }}</span>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const timer = ref(30);
        const animationTime = ref("10s");
        const startTime = computed(() => store.state.storeEventData.start_timer);
        const end_timer = computed(() => store.state.storeEventData.end_timer);
        //const loadingTimer = computed(() => store.state.storeEventData.end_timer);
        let total_timer = 0;
        let current_timer = 0;

        function clearCountdown(interval) {
            clearTimeout(interval);
        }

        function countdown() {
            var countdownBegin = current_timer / 1000;
            var count = setInterval(function () {
                if (countdownBegin <= 0) {
                    timer.value = 0;
                    clearCountdown(count);
                } else {
                    --countdownBegin;
                    timer.value = countdownBegin;
                }
            }, 1000);
        }
        onMounted(() => {
            total_timer = new Date(end_timer.value).getTime() - new Date(startTime.value).getTime();
            current_timer = new Date(end_timer.value).getTime() - new Date().getTime();
            console.log(total_timer, current_timer);
            countdown();
        });

        return { timer, animationTime };
    },
};
</script>

<style lang="scss" scoped>
@keyframes circletimer {
    0% {
        stroke-dashoffset: 500;
        stroke-dasharray: 500;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-dasharray: 500;
    }
}

svg {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(-90deg);
    .circle {
        stroke: #eba300;
        stroke-width: 12;
        fill: transparent;
        stroke-dashoffset: 500;
        stroke-dasharray: 0;
        animation: v-bind(animationTime) circletimer linear;
    }
}

.timer {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 42px;
    font-weight: 700;
}
</style>

<template>
    <div class="flex flex-row w-full">
        <div
            class="flex flex-col font-title items-center mr-1 justify-center text-4xl w-2/12"
            :class="custom_color ? 'custom-color' : 'text-orange-intense'"
        >
            {{ position }}
        </div>
        <div
            class="flex flex-row w-full div-ranking gap-2"
            :class="
                team.id == $store.state.storeEventData.playerId.team && highlight
                    ? 'border-orange-intense'
                    : 'shadow-md'
            "
        >
            <div class="flex flex-col items-center relative justify-center ml-2 w-2/12">
                <div
                    class="shadow-md flex flex-col bg-white absolute -left-4 rounded-full border-4 border-solid items-center justify-center text-center align-middle w-12 h-12"
                    :class="'border-team-' + team.color_id"
                >
                    <img :src="team.avatar.image" class="w-10 h-10 fill-silver-light" />
                </div>
            </div>
            <div class="ml-3 xl:ml-0 flex flex-col justify-center text-start w-7/12">
                {{ team.color_id }}. {{ team.nameShow }}
            </div>

            <div class="flex flex-row gap-1 items-center justify-center w-1/12">
                <template v-if="points == 'Total'">
                    {{ team.points }}
                </template>
                <template v-else>
                    {{ team.points_current_game }}
                </template>
            </div>
            <div class="flex flex-row gap-1 items-center justify-center w-1/12">
                <img
                    :src="$store.state.storeEventData.theme.points_icon"
                    class="w-6 h-6 fill-orange-intense ml-1"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {
        team: { type: Object, default: {} },
        position: { type: Number, default: 0 },
        highlight: { type: Boolean, default: false },
        points: { type: String, default: "Total" },
        custom_color: { type: Boolean, default: false },
    },
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped>
.div-ranking {
    background: #0d1015;
    border: 1px solid #2d303c;
    border-radius: 10px;

    @apply pr-2;
}
.team-player {
}
</style>

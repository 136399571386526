<template></template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        onMounted(() => {
            nextTick(() => {
                document.documentElement.style.setProperty(
                    "--bg-right",
                    ("'" + store.state.storeEventData.theme.background_right + "'").replaceAll("'", "")
                );
                document.documentElement.style.setProperty(
                    "--main-font-color",
                    store.state.storeEventData.theme.main_font_color.replaceAll('"', "")
                );
                document.documentElement.style.setProperty(
                    "--main-shadow-color",
                    store.state.storeEventData.theme.main_shadow_color.replaceAll('"', "")
                );
                document.documentElement.style.setProperty(
                    "--font-color",
                    store.state.storeEventData.theme.font_color.replaceAll('"', "")
                );
                console.log("Out onMounted");
            });
        });
        return {};
    },
};
</script>

<style lang="scss"></style>

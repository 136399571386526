<template>
    <div class="flex flex-row w-full justify-center items-start gap-12">
        <div class="flex flex-col gap-4 items-center justify-center w-5/12">
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-lg text-orange-intense" :for="question_data.question"
                    >Question</label
                >
                <input
                    class="p-2 text-xl rounded-xl border-4"
                    type="text"
                    :class="question_data.question ? 'border-green-400' : 'border-red-500'"
                    v-model="question_data.question"
                />
            </div>
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-lg text-orange-intense" :for="question_data.min_slider">Min</label>
                <input
                    class="p-2 text-xl rounded-xl border-4"
                    type="text"
                    :class="
                        question_data.min_slider && Number(question_data.max_slider) > Number(question_data.min_slider)
                            ? 'border-green-400'
                            : 'border-red-500'
                    "
                    v-model="question_data.min_slider"
                />
            </div>
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-lg text-orange-intense" :for="question_data.max_slider">Max</label>
                <input
                    class="p-2 text-xl rounded-xl border-4"
                    type="text"
                    :class="
                        question_data.max_slider && Number(question_data.max_slider) > Number(question_data.min_slider)
                            ? 'border-green-400'
                            : 'border-red-500'
                    "
                    v-model="question_data.max_slider"
                />
            </div>
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-lg text-orange-intense" :for="question_data.interval"
                    >Interval</label
                >
                <input
                    class="p-2 text-xl rounded-xl border-4"
                    type="text"
                    :class="
                        question_data.interval &&
                        question_data.interval != 0 &&
                        Number(question_data.interval) <=
                            Number((Number(question_data.max_slider) - Number(question_data.min_slider)) / 20)
                            ? 'border-green-400'
                            : 'border-red-500'
                    "
                    v-model="question_data.interval"
                />
            </div>
        </div>
        <div class="flex flex-col gap-4 items-center justify-center w-5/12">
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-lg text-orange-intense" :for="question_data.correct"
                    >Correct</label
                >
                <input
                    class="p-2 text-xl rounded-xl border-4"
                    type="text"
                    :class="
                        question_data.correct &&
                        Number(question_data.correct) >= Number(question_data.min_slider) &&
                        Number(question_data.correct) <= Number(question_data.max_slider)
                            ? 'border-green-400'
                            : 'border-red-500'
                    "
                    v-model="question_data.correct"
                />
            </div>
            <div class="flex flex-row gap-6 w-full">
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-lg text-orange-intense" :for="question_data.answer_explanation"
                        >Correct Answer Explanation</label
                    >
                    <textarea
                        class="rounded-2xl p-4 text-xl"
                        v-model="question_data.answer_explanation"
                        id="description"
                        placeholder="(optional)"
                        cols="34"
                        rows="2"
                    />
                </div>
            </div>
            <div class="flex flex-col gap-1 w-full">
                <label class="w-full font-title text-orange-intense text-lg" :for="question_data.id">Image</label>
                <div class="grid grid-cols-2 gap-3 w-full">
                    <img
                        :key="question_data.id"
                        v-if="getImage(question_data.id)"
                        :src="getImage(question_data.id)"
                        alt=""
                        class="w-full object-cover h-44 border-4 rounded-3xl border-solid border-green-400"
                    />
                    <img
                        v-else
                        :key="1"
                        class="w-full h-44 object-cover rounded-3xl border-4 border-solid border-red-500"
                        :src="require('@/assets/images/default.png')"
                        alt=""
                    />
                    <Dropzone
                        :options="{
                            acceptedFiles: '.jpeg,.jpg,.png,.gif,.svg',
                            url: urlUpload,
                            method: 'put',
                            thumbnailWidth: 150,
                            maxFilesize: 50,
                            maxFiles: 1,
                            headers: {
                                'Cache-Control': '',
                                'Authorization': Token,
                            },
                        }"
                        id="uploadImage"
                        class="dropzone aspect-square h-[11.5rem] w-full rounded-3xl"
                        :check_class="true"
                        :success="(x) => setImage(question_data.id, x)"
                    >
                        <input type="hidden" v-model="question_data.id" name="id_image" />
                        <input type="hidden" v-model="$route.params.id" name="id_content" />
                        <div class="text-lg font-medium text-text-100">Drop image</div>
                    </Dropzone>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import { v4 as uuidv4 } from "uuid";

export default {
    props: {
        question_data: { type: Object, default: {} },
        content_images: { type: Array, default: [] },
    },
    components: {},
    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload = process.env.VUE_APP_API_URL + "gamecontent/content_game/header_image";
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        const setImage = (id, response) => {
            if (props.content_images.find((el) => (el.key = id))) {
                props.content_images.find((el) => (el.key = id)).img = response.img;
            } else {
                props.content_images.push(response);
            }
        };

        const getImage = (id) => {
            if (props.content_images.find((e) => e.key == id)) {
                return props.content_images.find((e) => e.key == id).img;
            } else {
                return "";
            }
        };
        watch(
            () => props.question_data,
            () => () => {
                if (props.question_data.length > 0) {
                    props.question_data = props.question_data;
                }
            }
        );
        onMounted(() => {
            /*if (!props.quiz_data > 0) {
                props.quiz_data.push(generateRow());
            }*/
            console.log("mounted");
            console.log(props.question_data);
            console.log(props.content_images);
        });

        return {
            urlUpload,
            Token,
            getImage,
            setImage,
        };
    },
};
</script>

<style scoped>
ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
}
img {
    image-rendering: pixelated;
}
</style>

<template>
    <div class="flex flex-col gap-4 text-center">
        <h1 class="font-title text-orange-intense text-7xl">{{ $t("survey.title") }}</h1>
        <h2 class="font-title text-orange-intense text-5xl">{{ $t("survey.subtitle") }}</h2>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped></style>

<template>
    <div class="flex flex-col items-center justify-center w-full mb-3">
        <div class="max-w-xs flex flex-col rounded-xl items-center bg-silver-light text-center w-10/12 relative">
            <img class="object-cover w-full h-24 rounded-xl" :src="element.image" alt="image" />
            <div
                class="div-title flex flex-row gap-4 items-center text-xl w-full justify-center absolute -bottom-1 rounded-b-xl"
            >
                <div v-if="element.title != ''" class="font-title-btn text-sm p-1">{{ element.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        element: { type: Object, default: {} },
    },
    components: {},

    setup(props) {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.button {
    cursor: pointer;

    @apply bg-silver-dark rounded-md;
}
.div-title {
    background-color: #26282b;
}
</style>

<template>
    <FormGameContent />
</template>

<script>
// @ is an alias to /src

import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref, onMounted, onUnmounted } from "vue";
import FormGameContent from "@/components/dashboard/content/GameContent.vue";
import { axiosToken } from "@/libs/http";

export default {
    name: "GameView",
    components: { FormGameContent },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const store = useStore();

        onMounted(() => {});

        return {};
    },
};
</script>
<style scoped></style>

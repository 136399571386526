<template>
    <span>
        <div
            class="home"
            v-if="$store.state.storeEventData.loadedEventInfo && !$store.state.storeEventData.errorEventInfo"
        >
            <Home />
            <IconReaction class="active:bg-none" />
        </div>
        <div v-if="$store.state.storeEventData.loadedEventInfo && $store.state.storeEventData.errorEventInfo">
            <h1>Game not found</h1>
        </div>
    </span>
</template>

<script>
// @ is an alias to /src

import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { computed, onBeforeMount, onMounted, watch } from "vue";
import { useStore } from "vuex";
import IconReaction from "@/components/misc/IconReaction.vue";
import { axiosToken } from "@/libs/http";
export default {
    name: "HomeView",
    components: {
        Home,
        IconReaction,
    },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        onBeforeMount(() => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const key = urlParams.get("key");
            const view = urlParams.get("view");
            if (key) {
                localStorage.player_id = key;
            }
            if (view) {
                store.commit("setGmView", true);
            }

            store.commit("loadStoreEventData", location.pathname);
        });

        watch(
            computed(() => store.state.storeEventData.url),
            () => {
                console.log("reactions add");
                fetchReactions();
            }
        );

        const fetchReactions = async () => {
            const reactionsData = await axiosToken({
                method: "get",
                url: "event/reaction/" + store.state.storeEventData.url,
            });
            store.commit("addReactions", reactionsData.data);
        };

        onMounted(() => {});

        return {};
    },
};
</script>

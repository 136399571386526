<template>
    <div class="flex flex-col text-center justify-start items-center h-full relative">
        <div v-if="playerId && !modifyPlayer" class="flex flex-col w-full h-full justify-start items-center">
            <h2 class="text-orange-intense">{{ $t("login.waiting") }}</h2>
            <h4>{{ $store.state.storeEventData.event_player.lenght }}&nbsp;{{ $t("login.players") }}</h4>
            <div class="grid grid-cols-3 maxheight flow-col w-11/12 overflow-auto">
                <div
                    v-for="player in $store.state.storeEventData.event_player"
                    :key="player"
                    class="border-2 border-orange-intense border-solid p-1 m-1 rounded-xl one-line h-6 text-center"
                >
                    {{ player.name }}
                </div>
            </div>
            <Reactions class="mt-auto" />
        </div>
        <div class="flex flex-col items-center justify-center" v-else>
            <img class="w-11/12 mt-4" :src="$store.state.storeEventData.theme.logo" alt="logo" />
            <h1 class="text-orange-intense">{{ $t("login.welcome") }}</h1>
            <h2>{{ $t("login.name") }}</h2>
            <input type="text" v-model="playerName" autocomplete="name" class="text-input w-10/12" />
            <h2>{{ $t("login.location") }}</h2>
            <div class="flex flex-col gap-2 w-full flex-wrap items-center mb-4 justify-center overflow-auto">
                <button
                    v-for="location in $store.state.storeEventData.event_location"
                    :key="location"
                    class="w-4/5 text-xl border-none"
                    :class="locationSelected.name == location.name ? 'enum-marked' : 'enum'"
                    v-on:click="setlocation(location)"
                >
                    {{ location.name }}
                </button>
            </div>
            <button class="btn w-8/12 button-ready text-2xl" @click="addUser" :disabled="btnWaiting">
                {{ $t("login.ready") }}
            </button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http.js";
import { notify } from "notiwind";
import Reactions from "@/components/misc/Reactions.vue";
import { startSocketEvent } from "@/libs/ws";
export default {
    props: {},
    components: { Reactions },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const locations = ref([]);
        const location_selected = ref(0);
        const playerName = ref("");
        const playerId = ref("");
        const mode = ref("");
        const modifyPlayer = ref(0);
        const btnWaiting = ref(false);

        const locationSelected = ref({
            name: null,
            id: null,
        });
        const setlocation = (location) => {
            locationSelected.value = location;
        };

        onMounted(() => {
            playerId.value = localStorage.player_id;
        });

        const addUser = () => {
            if (playerName.value == "") {
                return notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "El nombre no puede estar vacio",
                    },
                    2000
                );
            }
            if (locationSelected.value.id == null) {
                return notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "Debes selecionar una localizacion",
                    },
                    2000
                );
            }
            btnWaiting.value = true;
            axiosToken({
                method: "post",
                url: "event/player",
                body: {
                    event: store.state.storeEventData.id,
                    location: locationSelected.value.id,
                    name: playerName.value,
                },
            })
                .then(async (response) => {
                    if (response.data["key"]) {
                        localStorage.player_id = response.data["key"];
                        playerId.value = localStorage.player_id;

                        console.log("");
                        startSocketEvent(localStorage.player_id, store.state.storeEventData.url);
                        store.commit("setPlayerId", localStorage.player_id);
                        store.commit("loadStoreEventData", location.pathname);
                    }
                })
                .catch((e) => {
                    btnWaiting.value = false;
                });
        };

        return {
            mode,
            location_selected,
            addUser,
            playerName,
            playerId,
            modifyPlayer,
            setlocation,
            locationSelected,
            btnWaiting,
        };
    },
};
</script>

<style scoped>
.box-container {
    height: 97.5vh;
}
.one-line {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}

.maxheight {
    max-height: 60%;
}

.button-ready {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
    font-weight: 900;
}
</style>

<template>
    <div class="flex flex-col wheel fixed">
        <!--<button id="spin">Party Games</button>-->
        <div class="flex flex-col relative justify-center items-center">
            <div class="flex flex-col container-2">
                <div
                    class="flex flex-col absolute w-full h-full justify-center items-center"
                    v-for="(portion, index) in $store.getters.getGameInfoRoulette"
                    :key="index"
                    :class="'portion-' + (index + 1)"
                >
                    <span class="flex flex-col absolute top-1 bg-white point"></span>
                </div>
            </div>
            <div class="flex flex-col border-container container">
                <div
                    class="container-div"
                    v-for="(portion, index) in $store.getters.getGameInfoRoulette"
                    :key="index"
                    :class="'portion-' + (index + 1)"
                >
                    <div class="flex flex-col items-center p-3">
                        <img class="container-div-img w-9/12" :src="portion.icon" alt="" />
                        <!-- <span class="container-div-span mt-2">{{ portion.name }}</span> -->
                    </div>
                    <inline-svg
                        :src="require('@/assets/images/tros_ruleta.svg')"
                        class="w-10/12 fill-silver-light fixed mt-2 -z-10"
                    ></inline-svg>
                </div>
            </div>
        </div>
        <img
            id="arrow"
            class="arrow flex z-50 -right-16 flex-col fixed w-24 h-24"
            :src="require('@/assets/icons/trianglet_gran.svg')"
            alt=""
        />
    </div>
</template>

<script>
// @ is an alias to /src

import Home from "@/components/Home.vue";
import { useRouter } from "vue-router";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";

export default {
    name: "HomeView",
    components: {
        Home,
    },

    setup(props) {
        const router = useRouter();
        const store = useStore();
        let currentPostion = 0;
        let currentArrayPos = 0;

        onMounted(() => {
            if (store.getters.getGmView) {
                document.getElementsByClassName("border-container")[0].style.left = "-50vw";
                document.getElementsByClassName("container-2")[0].style.left = "-51vw";
            }

            let container = document.querySelector(".container");
            console.log(container);
            if (container != null) {
                container.style.transform = "rotate(90deg)";
                currentPostion = 90;
                currentArrayPos = 0;
            }
        });

        const spinWheel = () => {
            if (store.state.storeEventData.game_state != 1) {
                return;
            }
            const target = store.state.storeEventData.current_game_content.id;
            const targetPos = store.state.storeEventData.roulette.findIndex((e) => e == target);
            const spinDegre = (targetPos - currentArrayPos) * -30;
            currentArrayPos = targetPos;
            currentPostion = currentPostion + 360 * 5 + spinDegre;
            let container = document.querySelector(".container");
            let container2 = document.querySelector(".container-2");
            container.style.transform = "rotate(" + currentPostion + "deg)";
            container2.style.transform = "rotate(" + currentPostion + 15 + "deg)";
            addShaking();
            setTimeout(() => {
                removeShaking();
            }, 5000);

            console.log(target, store.state.storeEventData.roulette, targetPos, spinDegre, currentPostion);
        };

        const addShaking = () => {
            document.getElementById("arrow").classList.add("arrow-spinning");
        };
        const removeShaking = () => {
            document.getElementById("arrow").classList.remove("arrow-spinning");
        };

        watch(
            () => store.state.storeEventData.game_state == 1,
            () => spinWheel()
        );

        return { spinWheel };
    },
};
</script>
<style></style>
<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    background-color: #34495e;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media (min-width: 1024px) {
    body {
        background-color: lightblue;
    }
}
@media screen and (max-width: 1024px) {
    .container {
        width: 500px;
        height: 500px;
    }

    .container-div {
        width: 120px;
        left: 170px;

        @apply flex flex-col justify-center items-center align-middle;
    }
}

.container {
    @media (max-width: 1300px) {
        width: 600px;
        height: 600px;
    }
    width: 700px;
    height: 700px;
    background-color: #171c27;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    transition: 5s;
    transform: rotate(15deg);
}
.container-2 {
    @media (max-width: 1300px) {
        width: 640px;
        height: 640px;
    }
    width: 740px;
    height: 740px;
    background-color: #171c27;
    border-radius: 50%;
    position: absolute;
    transition: 5s;
    transform: rotate(15deg);
    border-radius: 50%;
    left: -38.5vw;
    z-index: -1;
}
.border-container {
    left: -37.5vw;
    z-index: -1;
}
.container-div {
    height: 50%;
    @media (max-width: 1300px) {
        left: 220px;
        width: 160px;
    }
    left: 258px;
    width: 185px;
    position: absolute;
    clip-path: polygon(100% 0, 50% 100%, 0 0);
    transform: translateX(-50%);
    transform-origin: bottom;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    font-family: sans-serif;
    color: #fff;

    @apply flex flex-col justify-start;
}
.container-div-img {
    @apply mt-1;
}

//COLOR VARIABLES
/*$blue_light: #0090ff;
$blue_dark: #004287;
$yellow_light: #ffe24d;
$yellow_dark: #cbaa00;
$red_light: #ff7070;
$red_dark: #c72e31;
$white_light: #fdffef;
$white_dark: #d9d9d9;
$pink_light: #ff3790;
$pink_dark: #aa0a4b;
$green_light: #48c50e;
$green_dark: #108700;

$list_blue: $blue_light 50.04%, $blue_dark 91.9%;
$list_2: $yellow_light 50.05%, $yellow_dark 93.32%;
$list_3: $red_light 50.04%, $red_dark 91.9%;
$list_4: $white_light 50.05%, $white_dark 93.32%;
$list_5: $pink_light 50.04%, $pink_dark 91.9%;
$list_6: $green_light 50.04%, $green_dark 91.9%;*/

.container-2 .portion-1 {
}
.container-2 .portion-2 {
    transform: rotate(30deg);
}
.container-2 .portion-3 {
    transform: rotate(60deg);
}
.container-2 .portion-4 {
    transform: rotate(90deg);
}
.container-2 .portion-5 {
    transform: rotate(120deg);
}
.container-2 .portion-6 {
    transform: rotate(150deg);
}
.container-2 .portion-7 {
    transform: rotate(180deg);
}
.container-2 .portion-8 {
    transform: rotate(210deg);
}
.container-2 .portion-9 {
    transform: rotate(240deg);
}
.container-2 .portion-10 {
    transform: rotate(270deg);
}
.container-2 .portion-11 {
    transform: rotate(300deg);
}
.container-2 .portion-12 {
    transform: rotate(330deg);
}
.container .portion-1 {
    background: linear-gradient(0deg, #0090ff 50.04%, #004287 91.9%);
    left: 50%;
}
.container .portion-2 {
    background: linear-gradient(0deg, #ffe24d 50.05%, #cbaa00 93.32%);
    transform: rotate(30deg);
}
.container .portion-3 {
    background: linear-gradient(0deg, #ff7070 50.04%, #c72e31 91.9%);
    transform: rotate(60deg);
}
.container .portion-4 {
    background: linear-gradient(0deg, #fdffef 50.05%, #d9d9d9 93.32%);
    transform: rotate(90deg);
}
.container .portion-5 {
    background: linear-gradient(0deg, #ff3790 50.04%, #aa0a4b 91.9%);
    transform: rotate(120deg);
}
.container .portion-6 {
    background: linear-gradient(0deg, #48c50e 50.04%, #108700 91.9%);
    transform: rotate(150deg);
}
.container .portion-7 {
    background: linear-gradient(0deg, #0090ff 50.04%, #004287 91.9%);
    transform: rotate(180deg);
}
.container .portion-8 {
    background: linear-gradient(0deg, #ffe24d 50.05%, #cbaa00 93.32%);
    transform: rotate(210deg);
}
.container .portion-9 {
    background: linear-gradient(0deg, #ff7070 50.04%, #c72e31 91.9%);
    transform: rotate(240deg);
}
.container .portion-10 {
    background: linear-gradient(0deg, #fdffef 50.05%, #d9d9d9 93.32%);
    transform: rotate(270deg);
}
.container .portion-11 {
    background: linear-gradient(0deg, #ff3790 50.04%, #aa0a4b 91.9%);
    transform: rotate(300deg);
}
.container .portion-12 {
    background: linear-gradient(0deg, #48c50e 50.04%, #108700 91.9%);
    transform: rotate(330deg);
}

.arrow {
    @media (max-width: 1300px) {
        margin-left: 310px;
    }

    top: 45%;
    left: 0;
    margin-left: 360px;
}

.arrow::before {
    content: "\1F817";
    font-size: 50px;
}

#spin {
    position: relative;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background-color: rgb(46, 46, 46);
    text-transform: uppercase;
    border: 8px solid #a2a2a2;
    font-weight: bold;
    font-size: 23px;
    color: #a2a2a2;
    width: 125px;
    height: 125px;
    font-family: sans-serif;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    letter-spacing: 1px;
}
.point {
    @apply absolute h-3 w-3 rounded-full bg-white z-50;
}

@keyframes slideInFromMiddle {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-30vw);
    }
}
.arrow-spinning {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: rotate(15deg);
    }

    30% {
        transform: rotate(-15deg);
    }
    40% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-15deg);
    }
    60% {
        transform: rotate(15deg);
    }
    70% {
        transform: rotate(-15deg);
    }
    80% {
        transform: rotate(15deg);
    }
    90% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(15deg);
    }
}
</style>

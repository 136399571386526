<template>
    <div class="flex flex-col gap-4 text-center items-center w-full justify-center h-full mt-4">
        <div
            @click="activateSetTeamName($event)"
            :class="editName ? 'hidden' : ''"
            class="flex flex-row items-center justify-center gap-2 w-full cursor-pointer"
        >
            <h1 class="w-4/5 text-orange-intense">
                {{ $store.getters.getTeamPlayer($store.state.storeEventData.playerId.team).color_id }}.
                {{ $store.getters.getTeamPlayer($store.state.storeEventData.playerId.team).nameShow }}
            </h1>
            <div class="rounded-lg h-10 w-10 items-center justify-center">
                <inline-svg
                    :src="require('@/assets/icons/pen-solid.svg')"
                    class="w-6 h-6 fill-orange-intense mt-2 -z-10"
                />
            </div>
        </div>

        <input
            :class="editName ? '' : 'hidden'"
            v-model="teamName"
            class="text-input-transparent w-10/12"
            type="text"
            id="team-name"
        />

        <div class="flex flex-row items-center justify-center flex-wrap w-full gap-2 h-full pb-16">
            <div
                class="w-4/12"
                :class="
                    teamPlayer.key != $store.state.storeEventData.playerId.key
                        ? 'div-team-player'
                        : 'div-team-player border-white border-2 border-solid'
                "
                v-for="teamPlayer in teamPlayers"
                :key="teamPlayer"
            >
                {{ teamPlayer.name }}
            </div>
        </div>
        <AvatarSelector />
        <button class="btn w-8/12 mb-6" @click="setTeamName">{{ $t("team.update") }}</button>
    </div>
</template>

<script>
import { watch, ref, onMounted, onUnmounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import Reactions from "@/components/misc/Reactions.vue";
import AvatarSelector from "@/components/misc/AvatarSelector.vue";
import { notify } from "notiwind";
export default {
    props: {},
    components: { Reactions, AvatarSelector },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const player = ref({});
        const watchTeamName = computed(() =>
            store.getters.getTeamPlayer(store.state.storeEventData.playerId.team).nameShow
                ? store.getters.getTeamPlayer(store.state.storeEventData.playerId.team).nameShow
                : ""
        );
        console.log(store.state.storeEventData.playerId.team);
        const teamName = ref("");
        const teamPlayers = ref([]);
        const editName = ref(false);

        const activateSetTeamName = (event) => {
            editName.value = true;
            // console.log(event.target.parentElement.parentElement.parentElement);
            setTimeout(() => {
                document.getElementById("team-name").focus();
            }, 100);
        };

        const setTeamName = () => {
            if (teamName.value == "") {
                return notify(
                    {
                        group: "error",
                        title: "Error",
                        text: "Name can not be empty",
                    },
                    2000
                );
            }

            const team = { ...store.getters.getTeamPlayer(store.state.storeEventData.playerId.team) };
            store.commit("setTeamName", { team: team, new_name: teamName.value });
            editName.value = false;
            axiosToken({
                method: "put",
                url: "event/team/" + team.id,
                body: {
                    nameShow: teamName.value,
                    avatar: store.state.storeEventData.avatarArray[store.state.storeEventData.selectedAvatar].id,
                },
            });
        };

        watch(watchTeamName, () => {
            teamName.value = watchTeamName.value;
        });

        onMounted(() => {
            const team = store.getters.getTeamPlayer(store.state.storeEventData.playerId.team);
            console.log(team.nameShow);
            teamPlayers.value = store.state.storeEventData.event_player.filter((p) => p.team == team.id);
            teamName.value = team.nameShow;
        });

        return { player, teamName, teamPlayers, editName, activateSetTeamName, setTeamName };
    },
};
</script>

<style scoped>
.maxheight {
    max-height: 50%;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import '@/assets/css/main.css'
import '@/assets/css/animate.min.css'
import '@/assets/sass/app.scss'
import { i18n } from './i18n'
import Notifications from 'notiwind'
import InlineSvg from 'vue-inline-svg';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import globalComponents from './global-components'

/* add icons to the library */
library.add(faPenToSquare)
library.add(faArrowRight)
library.add(faArrowLeft)
library.add(faPlus)




const app = createApp({
    extends: App,
    beforeMount() {
        // need to be moved
        // store.commit('loadStoreEventData', location.pathname);
    },
    /*beforeUnmount() {
        //doesn't work well
    },*/
}).use(store).use(router).use(i18n).use(Notifications)
    .component('font-awesome-icon', FontAwesomeIcon)


app.config.globalProperties.$filters = {
    formatDate(value) {
        const date = new Date(value)
        return moment(date).format('DD/MM/YYYY HH:mm')
    },
    formatDateDay(value) {
        const date = new Date(value)
        return moment(date).format('DD/MM/YYYY')
    }
    ,
    formatDateHour(value) {
        const date = new Date(value)
        return moment(date).format('HH:mm ')
    }
}

app.component('inline-svg', InlineSvg);
globalComponents(app)
app.mount('#app');
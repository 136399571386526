<template>
    <div class="flex flex-col items-end justify-center max-h-screen w-full absolute bottom-12 right-0">
        <h1 class="flex flex-col items-center w-6/12 custom-color">{{ $t("ranking.title") }}</h1>
        <div class="flex flex-col items-center justify-end gap-6 w-6/12">
            <div
                class="flex flex-row gap-2 w-11/12"
                v-for="(team, index) in $store.state.storeEventData.event_team_points_sorted.slice(0, 5)"
                :key="team"
            >
                <RankingCard :custom_color="true" :team="team" :position="index + 1" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, defineAsyncComponent, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import RankingCard from "@/components/cards/RankingCard.vue";

export default {
    props: {},
    components: { RankingCard },
    setup(props) {
        // const rankingTotal = computed(() =>
        //     store.state.storeEventData.event_team
        //         .sort(function (a, b) {
        //             return b.points - a.points || a.name.localeCompare(b.name);
        //         })
        //         .slice(0, 5)
        // );

        return {};
    },
};
</script>

<style scoped>
.div-ranking {
    background: #0d1015;
    border: 1px solid #2d303c;
    border-radius: 10px;

    @apply pr-2;
}
</style>

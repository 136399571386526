<template>
    <span class="flex flex-col items-center justify-start my-auto min-h-screen w-full">
        <div class="flex flex-col items-center justify-center w-full" v-if="!collapsed">
            <HeaderContent v-model="dataContent" @setCollapse="setCollapse()" />
        </div>
        <div v-else class="flex flex-col items-center justify-center w-full">
            <DataContent
                @setCollapse="setCollapse()"
                :game_selected="gameData.find((el) => el.id == $store.state.storeContentData.dataContent.game).name_id"
            />
        </div>
    </span>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import HeaderContent from "@/components/dashboard/forms/HeaderContent.vue";
import DataContent from "@/components/dashboard/forms/DataContent.vue";

export default {
    props: {},
    components: { HeaderContent, DataContent },
    setup(props) {
        const Token = "Token " + localStorage.user;
        const urlUpload = process.env.VUE_APP_API_URL + "gamecontent/content_game/header_image";
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const collapsed = ref(false);
        const languageSelect = ref([
            { name: "es", key: "es" },
            { name: "en", key: "en" },
            //{ name: "ca", key: "ca" },
        ]);
        const gameSelect = ref([]);
        const gameData = ref(null);
        const dataContent = ref({
            name: "",
            name_id: "",
            language: "en",
            description: "",
            game: null,
            data: [],
            selected_default: false,
            icon: null,
            image: null,
            background: null,
            validated: false,
            active: true,
            category: null,
        });

        const getGameSelect = async () => {
            await getGameData();
            gameData.value.forEach((game) => {
                gameSelect.value.push({ key: game.id, name: game.name_id });
            });
        };

        const getGameData = async () => {
            gameData.value = await axiosToken({
                method: "get",
                url: "gamecontent/game",
                authorization: true,
            });

            gameData.value = gameData.value.data;
            console.log(gameData);
        };

        const returnImg = (id) => {
            /*if (dataContent.value.find((e) => e.id == id).image) {
                return dataContent.value.find((e) => e.id == id).image;
            }
            for (let x = 0; x < props.all_data.images.length; x++) {
                if (props.all_data.images[x].name == id) {
                    return props.all_data.images[x].img;
                }
            }
            return "";*/
        };
        const setImage = (id, response) => {
            dataContent.value[id] = response.url;
        };

        const newGameContent = async () => {
            await axiosToken({
                method: "post",
                url: "gamecontent/content_game",
                body: dataContent.value,
                authorization: true,
            }).then((response) => {
                router.push({ name: "dashboardContentUpsert", params: { id: response.data.id } });
            });
        };

        const setCheckbox = (actual) => {
            dataContent.value.selected_default = !actual;
            console.log(dataContent.value.selected_default);
        };

        const loadImage = async (event, type) => {
            var files = event.target.files;
            if (type == "icon") {
                dataContent.value.icon = files[0];
            } else if (type == "image") {
                dataContent.value.image = files[0];
            } else if (type == "background") {
                dataContent.value.background = files[0];
            }

            console.log(dataContent.value);

            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    document.getElementById(type + "_img").src = fr.result;
                };
                fr.readAsDataURL(files[0]);
            } else {
            }
        };

        const inputClick = (input_id) => {
            document.getElementById(input_id).click();
        };

        const setCollapse = () => {
            console.log(dataContent.value);
            collapsed.value = !collapsed.value;
        };

        onMounted(async () => {
            if (route.params.id == 0) {
                await newGameContent();
            }
            getGameSelect();
        });

        return {
            Token,
            urlUpload,
            returnImg,
            dataContent,
            languageSelect,
            gameSelect,
            setImage,
            dataContent,
            setCheckbox,
            inputClick,
            loadImage,
            setCollapse,
            collapsed,
            gameData,
        };
    },
};
</script>

<style scoped>
input[type="checkbox"] {
    width: 30px; /*Desired width*/
    height: 30px; /*Desired height*/
    cursor: pointer;
}
input[type="checkbox"]:checked {
    color: white;
}
img {
    image-rendering: pixelated;
}
.font-title {
    font-size: 28px;
}
</style>

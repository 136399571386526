<template>
    <div class="flex flex-col text-left w-full items-center h-full main-background justify-center">
        <template v-if="$store.state.storeEventData.ingame_state <= 2">
            <div class="flex flex-col">
                <h1 class="custom-color text-5xl">
                    {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{
                        elementsStatic.length
                    }}
                </h1>
            </div>
            <div class="flex flex-col items-center justify-center bg-blue-dark rounded-3xl w-11/12 py-6 bg-opacity-80">
                <h2 class="text-4xl px-8">{{ elementsStatic[$store.state.storeEventData.counter_game].question }}</h2>
                <div class="flex flex-row">
                    <div
                        class="flex flex-row flex-wrap w-full items-center justify-center"
                        v-if="$store.state.storeEventData.ingame_state == 1"
                    >
                        <MainTimelineCard
                            class="w-4/12"
                            :element="element"
                            v-for="element in elementsStatic[$store.state.storeEventData.counter_game].timeline"
                            :key="element"
                        />
                    </div>
                    <div
                        class="flex flex-row flex-wrap w-full items-center justify-center"
                        v-if="$store.state.storeEventData.ingame_state == 2"
                    >
                        <span
                            class="flex flex-row w-4/12 items-center justify-center relative"
                            v-for="(element, index) in $store.state.storeEventData.game_data.correct_anse"
                            :key="element"
                        >
                            <MainTimelineCard
                                class="flex flex-col w-11/12"
                                :element="element"
                                :correct="$store.state.storeEventData.game_data.results_show[element.id]"
                            />
                            <inline-svg
                                v-if="!((index + 1) % 3 == 0)"
                                :src="require('@/assets/icons/angle-right-solid.svg')"
                                class="flex flex-col w-12 h-12 fill-orange-intense ml-1 absolute -right-6"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template v-else-if="$store.state.storeEventData.ingame_state == 3">
            <div class="flex flex-col items-center justify-center bg-blue-dark rounded-3xl w-11/12 py-6 bg-opacity-80">
                <div class="flex flex-row w-full">
                    <div class="flex flex-col justify-center w-6/12">
                        <MainRankingFinal></MainRankingFinal>
                    </div>
                    <div class="flex flex-col justify-center w-6/12">
                        <MainRankingFinal :points="'Game'"></MainRankingFinal>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed, onUpdated } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import MainTimelineCard from "@/components/cards/MainTimelineCard.vue";
import MainRankingFinal from "@/components/MainRankingFinal.vue";

export default {
    props: {},
    components: { MainTimelineCard, MainRankingFinal },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const elementsStatic = store.state.storeEventData.shared_content;
        onUpdated(() => {
            console.log(store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].timeline);
        });

        return { elementsStatic };
    },
};
</script>

<style lang="scss" scoped>
body {
    height: 100%;
    min-height: 100%;
}
.vertical-line {
    position: relative;
    background: linear-gradient(180deg, #ff5f6d 17.76%, #ffbd63 96.21%);
    border-radius: 10px;
}
ul {
    list-style-type: none;
    padding: 0;
}
.arrow-right {
    width: 0px;
    height: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid;
}
</style>

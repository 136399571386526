<template>
    <div class="flex flex-col text-center">
        <div class="flex flex-col" v-for="(question, index) in questions" :key="question">
            <div
                class="flex flex-col items-center justify-center"
                v-if="index == $store.state.storeEventData.counter_game"
            >
                <div class="flex flex-row w-full items-center justify-center">
                    <h1 class="flex flex-col text-orange-intense font-title text-center text-2xl w-full">
                        {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{
                            questions.length
                        }}
                    </h1>
                </div>
                <img class="object-cover w-11/12 rounded-3xl h-48 max-w-lg" :src="question.image" alt="image" />
                <h4 class="w-9/12 mb-52">{{ question.question }}</h4>
                <div class="flex flex-col items-center justify-center relative">
                    <div class="flex!= flex-col slider-container ml-16 absolute">
                        <input
                            type="range"
                            :min="question.min_slider"
                            :max="question.max_slider"
                            :step="question.interval"
                            v-model="slider"
                            class="slider"
                            :class="$store.state.storeEventData.ingame_state >= 2 ? '' : ''"
                            :disabled="$store.state.storeEventData.ingame_state >= 2"
                            @change="updateAnswer(slider)"
                            @input="showValue()"
                        />
                        <div class="flex flex-row">
                            <span
                                class="slider-value flex flex-row items-center justify-center"
                                :id="'slider-value-' + $store.state.storeEventData.counter_game"
                                :class="$store.state.storeEventData.ingame_state >= 2 ? '' : ''"
                            >
                                <span>{{ sliderValue ? sliderValue : question.min_slider }}</span>
                                <span
                                    v-if="$store.state.storeEventData.ingame_state == 2"
                                    class="flex flex-row absolute left-16 w-full text-sm"
                                    >Your&nbsp;guess</span
                                >
                            </span>
                        </div>
                        <span
                            class="flex flex-col slider-value bg-quiz-green absolute -mt-2 -top-20 items-center justify-center"
                            :id="'slider-value-result' + $store.state.storeEventData.counter_game"
                            :class="$store.state.storeEventData.ingame_state >= 2 ? '' : 'invisible'"
                            ><span
                                >{{
                                    $store.state.storeEventData.game_data.correct
                                        ? $store.state.storeEventData.game_data.correct
                                        : 0
                                }}
                            </span>
                            <span
                                v-if="$store.state.storeEventData.ingame_state == 2"
                                class="flex flex-row absolute right-12 mr-3 w-full text-sm"
                                >Correct</span
                            >
                        </span>
                    </div>
                    <div class="flex flex-row items-center justify-center gap-20">
                        <div
                            class="flex flex-col items-center justify-center"
                            :class="$store.state.storeEventData.ingame_state >= 2 ? 'invisible' : ''"
                        >
                            <inline-svg
                                :src="require('@/assets/icons/circle-minus-solid.svg')"
                                class="cursor-pointer w-4/12 fill-orange-intense"
                                v-on:click="sliderChange('down')"
                            />
                        </div>
                        <div
                            class="flex flex-col items-center justify-center"
                            :class="$store.state.storeEventData.ingame_state >= 2 ? 'invisible' : ''"
                        >
                            <inline-svg
                                :src="require('@/assets/icons/circle-plus-solid.svg')"
                                class="cursor-pointer w-4/12 fill-orange-intense"
                                v-on:click="sliderChange('up')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, onUpdated, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const sliderStore = computed(() => store.state.storeGamesData.fairPriceData.answer);
        const maxSlider = computed(
            () => store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].max_slider
        );
        const minSlider = computed(
            () => store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].min_slider
        );
        const interval = computed(
            () => store.state.storeEventData.shared_content[store.state.storeEventData.counter_game].interval
        );

        const slider = ref(parseFloat(minSlider.value));
        let sliderValue = null;
        let correctSlider = null;
        var last_store_counter = 0;
        var last_ingame_state = 0;
        onMounted(() => {
            console.log("onMounted");
            sliderValue = document.querySelector("#slider-value-" + store.state.storeEventData.counter_game);
            correctSlider = document.querySelector("#slider-value-result" + store.state.storeEventData.counter_game);
            last_store_counter = store.state.storeEventData.counter_game;
            last_ingame_state = store.state.storeEventData.ingame_state;
            if (store.state.storeEventData.ingame_state == 2) {
                var answer = store.state.storeEventData.game_data.team_data.filter(
                    (t) => t.id == store.state.storeEventData.playerId.team
                )[0].answer;
                store.state.storeEventData.ingame_state == 1
                    ? (slider.value = parseFloat(minSlider.value))
                    : (slider.value = parseFloat(answer));
                showValue(slider.value);
                correctSlider = document.querySelector(
                    "#slider-value-result" + store.state.storeEventData.counter_game
                );
                sliderValue = document.querySelector("#slider-value-" + store.state.storeEventData.counter_game);
            }
            if (store.state.storeEventData.ingame_state >= 2) {
                showCorrectValue();
            }
        });

        /*onUpdated(() => {
            console.log("onUpdated");
            console.log(last_store_counter, store.state.storeEventData.counter_game);
            if (last_store_counter != store.state.storeEventData.counter_game) {
                last_store_counter = store.state.storeEventData.counter_game;
                if (store.state.storeEventData.ingame_state != 3) {
                    slider.value = minSlider.value;
                    correctSlider = document.querySelector(
                        "#slider-value-result" + store.state.storeEventData.counter_game
                    );
                    sliderValue = document.querySelector("#slider-value-" + store.state.storeEventData.counter_game);
                }
            }
            if (store.state.storeEventData.ingame_state >= 2) {
                showCorrectValue();
            }
        });*/

        watch(
            () => sliderStore.value,
            () => {
                slider.value = sliderStore.value;
                showValue();
            }
        );

        function showValue() {
            sliderValue.firstChild.innerHTML = parseFloat(slider.value).toFixed(0);
            var range_value = maxSlider.value - minSlider.value;
            var range_selected = maxSlider.value - slider.value;
            var sliderPosition = 1 - range_selected / range_value;
            if (slider.value === minSlider.value) {
                sliderValue.style.left = sliderPosition * 100 - 100 * 0.08 + "%";
            } else if (slider.value >= maxSlider.value) {
                sliderValue.style.left = sliderPosition * 100 + -100 * 0.08 + "%";
            } else {
                sliderValue.style.left = sliderPosition * 100 - 100 * 0.08 + "%";
            }
        }
        function showCorrectValue() {
            var range_value = maxSlider.value - minSlider.value;
            var range_selected = maxSlider.value - store.state.storeEventData.game_data.correct;
            var sliderPosition = 1 - range_selected / range_value;
            if (store.state.storeEventData.game_data.correct === minSlider.value) {
                correctSlider.style.left = sliderPosition * 100 - 100 * 0.085 + "%";
            } else if (store.state.storeEventData.game_data.correct >= maxSlider.value) {
                correctSlider.style.left = sliderPosition * 100 + -100 * 0.085 + "%";
            } else {
                correctSlider.style.left = sliderPosition * 100 - 100 * 0.085 + "%";
            }
        }
        const questions = ref(store.state.storeEventData.shared_content);

        const sliderChange = (type) => {
            slider.value = parseFloat(slider.value);
            if (store.state.storeEventData.ingame_state == 1) {
                if (type == "up") {
                    console.log(slider.value + interval.value);
                    if (slider.value + interval.value < maxSlider.value) {
                        slider.value += interval.value;
                    }
                } else if (type == "down") {
                    if (slider.value - interval.value > minSlider.value) {
                        slider.value -= interval.value;
                    }
                }
                showValue();
                updateAnswer(slider.value);
            }
        };

        const updateAnswer = (answer) => {
            store.dispatch("sendTeamData", answer);
            // store.commit("setFairPriceAnswer", { answer: answer });
        };
        return { questions, updateAnswer, slider, showValue, sliderValue, sliderStore, sliderChange, showCorrectValue };
    },
};
</script>
<style lang="scss" scoped>
$bg: #eee;
$slider-bg: #fff;
$slider-thumb: #ffa724;

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 25px;
    background: $slider-bg;
    outline: none;
    border-radius: 20px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(0deg);
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 12px;
        height: 40px;
        background: $slider-thumb;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.1s ease-in-out;
    }
    &::-moz-range-thumb {
        width: 12px;
        height: 40px;
        background: $slider-thumb;
        border-radius: 8px;
        cursor: pointer;
        border: none;
        transition: 0.1s ease-in-out;
    }
    &::-moz-range-track {
        background: $slider-bg;
    }
    &:active {
        &::-webkit-slider-thumb {
            background: darken($slider-thumb, 10%);
        }
        &::-moz-range-thumb {
            background: darken($slider-thumb, 10%);
        }
        + #slider-value {
            opacity: 1;
            background: darken($slider-thumb, 10%);
        }
    }
}
.slider-container {
    position: relative;
    width: 100%;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(270deg);
    -webkit-appearance: none;
    & .slider-value {
        position: relative;
        display: block;
        transform: scale(1, 1) translateY(50%) translateX(20%) rotate(90deg);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 60px;
        background-color: $slider-thumb;
        color: #fff;
        text-align: center;
        font-size: 1.5em;
        font-weight: bolder;
        opacity: 1;
        top: -30px;
        left: -32px;
        transition: 0.15s top ease-in-out, 0.15s opacity ease-in-out 0.05s, 0.15s scale ease-in-out;
    }
}
</style>

<template>
    <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
        appear
        mode="out-in"
        :key="$store.state.storeEventData.ingame_state"
    >
        <span>
            <UserCard />
            <span class="absolute top-2 right-2 z-50"
                ><Timer2
                    :timeLeft="$store.getters.getTimer"
                    :initTime="$store.getters.getTotalTimer"
                    :lastTime="$store.state.storeEventData.start_timer"
                    :type="'simple'"
            /></span>
            <component id="game-manager" :is="comp"></component>
        </span>
    </transition>
</template>

<script>
const components = ["Quiz", "FairPrice", "Timeline"];
const gameIndex = ["quiz", "fair_price", "timeline"];
import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Quiz from "@/components/games/Quiz.vue";
import FairPrice from "@/components/games/FairPrice.vue";
import Timeline from "@/components/games/Timeline.vue";
import Timer2 from "@/components/Timer2.vue";
import UserCard from "@/components/cards/UserCard.vue";

export default {
    props: {},
    components: { Quiz, FairPrice, Timeline, Timer2, UserCard },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const componentSelected = ref(gameIndex.indexOf(store.state.storeEventData.current_game.name_id));
        const comp = components[componentSelected.value];

        /*
        FULLSCREEN CODE
        
        onMounted(() => {
            // element which needs to enter full-screen mode
            var element = document.querySelector("#game-manager");

            // make the element go to full-screen mode
            element
                .requestFullscreen()
                .then(function () {
                    // element has entered fullscreen mode successfully
                })
                .catch(function (error) {
                    // element could not enter fullscreen mode
                });
        });*/
        return { componentSelected, comp };
    },
};
</script>

<style scoped></style>

const en = {
    general: {
        reactions: "Reactions",
        player: "Player:",
        team: "Team:",
    },
    login: {
        welcome: "Welcome!",
        name: "Enter your name",
        location: "Select your location",
        ready: "Ready",
        waiting: "WAITING FOR PLAYERS",
        players: "Players"
    },
    team: {
        update: "UPDATE",
        avatar: "Avatar",
    },
    ranking: {
        title: "General Ranking",
        title_partial: "Challenge Ranking",
    },
    game: {
        question: "Question",

    },

    timeline: {
        description: "Sort out the cards following the right order. The closer you get to the correct order the more points you will get.",
        guessed: "Guessed",
    },
    fair_price: {
        description: "Try to guess the right amount for a few different questions. The closer you get to the right amount the more points you will get.",
    },
    quiz: {
        description: "Answer a few questions about different topics. There's only one correct answer!",
    },
    survey: {
        title: "Survey time",
        subtitle: "Make the survey",
        rating_game: "Game",
        rating_fun: "Fun",
        rating_gm: "Game Master",
        repeat: "Would you repeat it?",
        yes: "Yes",
        no: "No",
        comments: "Comments",
        send: "Send",
        completed: "Survey completed, thanks for your opinion.",
    },
    test: {
        title_ok: "TEST COMPLETED",
        subtitle_ok: "Congratulations! If you are able to read this text it means your company network is fully compatible with our online escape room competitions. Thanks for doing the test!",
        title_error: "TEST FAILED",
        subtitle_error: "Sorry, our game platform is not 100%% compatible with your company network. Please get in touch with the organizer of the activity.",
    }
}

module.exports = {
    en
}

<template>
    <div class="flex flex-col text-center overflow-auto">
        <div class="flex flex-col" v-for="(question, indexQuestion) in questions" :key="question">
            <div class="flex flex-col items-center" v-if="indexQuestion == $store.state.storeEventData.counter_game">
                <div class="flex flex-row w-full items-center justify-center">
                    <h1 class="flex flex-col text-orange-intense font-title text-center w-full">
                        {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{
                            questions.length
                        }}
                    </h1>
                </div>
                <img class="object-cover w-11/12 rounded-3xl h-48 max-w-lg" :src="question.image" alt="image" />
                <h3 class="w-9/12 mb-1">{{ question.question }}</h3>
                <hr class="w-11/12 bg-orange-intense border-none h-1" />
                <div class="flex flex-col w-11/12">
                    <div
                        class="flex flex-row items-center justify-center py-2 rounded-2xl cursor-pointer game-btn"
                        v-for="(answer, index) in question.answers"
                        :class="
                            (answer.answer == $store.state.storeEventData.game_data.correct_anse &&
                                $store.state.storeEventData.ingame_state == 2) ||
                            answer.id == $store.state.storeEventData.game_data.correct_anse ||
                            ($store.state.storeEventData.ingame_state == 1 &&
                                answer.id == $store.state.storeGamesData.quizData.answer)
                                ? question_style[index].bgcolor + ' border-white text-white'
                                : answer.id == $store.state.storeGamesData.quizData.answer
                                ? question_style[index].bgcolor + ' border-white text-white opacity-50'
                                : $store.state.storeEventData.ingame_state == 2
                                ? 'opacity-20 ' + question_style[index].bordercolor
                                : question_style[index].bordercolor
                        "
                        :key="answer"
                        @click="updateAnswer(answer.id)"
                    >
                        <div class="w-2/12">
                            <div
                                :class="
                                    (answer.answer == $store.state.storeEventData.game_data.correct_anse &&
                                        $store.state.storeEventData.ingame_state == 2) ||
                                    answer.id == $store.state.storeGamesData.quizData.answer
                                        ? ' border-white text-white' + question_style[index].bgcolor
                                        : 'border-white' + ' bg-gray-dark '
                                "
                                class="flex flex-col w-6 h-6 rounded-full border-2 border-solid p-1 items-center justify-center"
                            >
                                <span
                                    class="flex flex-col items-center justify-center"
                                    v-if="
                                        $store.state.storeEventData.ingame_state == 2 &&
                                        answer.answer == $store.state.storeEventData.game_data.correct_anse
                                    "
                                >
                                    <inline-svg :src="require('@/assets/icons/checkmark.svg')" class="w-6 h-6" />
                                </span>
                                <span v-else>{{ question_style[index].letter }}</span>
                            </div>
                        </div>
                        <div class="w-10/12 text-left flex flex-row">
                            <p>
                                {{ answer.answer }}
                            </p>
                            <p v-if="$store.state.storeEventData.ingame_state == 2" class="ml-auto mr-2">
                                {{ $store.getters.getQuizResult(answer.id) }} %
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {}, // questions.value[question].answer = answer;
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        /*const questions = ref(require("../../assets/dummydata/games.json"));
        questions.value = questions.value.quiz;*/
        const question_style = [
            { letter: "A", bgcolor: "bg-quiz-red", bordercolor: "border-quiz-red" },
            { letter: "B", bgcolor: "bg-quiz-green", bordercolor: "border-quiz-green" },
            { letter: "C", bgcolor: "bg-quiz-blue", bordercolor: "border-quiz-blue" },
            { letter: "D", bgcolor: "bg-quiz-yellow", bordercolor: "border-quiz-yellow" },
            { letter: "E", bgcolor: "bg-quiz-purple", bordercolor: "border-quiz-purple" },
            { letter: "F", bgcolor: "bg-quiz-orange", bordercolor: "border-quiz-orange" },
        ];
        const questions = ref(store.state.storeEventData.shared_content);

        const updateAnswer = (answer) => {
            console.log(answer);
            if (store.state.storeEventData.ingame_state == 1) {
                // questions.value[question].answer = answer;
                //store.commit("setQuizAnswer", { id: questions.value[question].id, answer: answer });
                store.dispatch("sendTeamData", answer);
            }
        };

        return { questions, updateAnswer, question_style };
    },
};
</script>

<style scoped>
.font-title {
    font-size: 25px;
}
</style>

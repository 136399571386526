const timelineData = {
    answer: []
}

const timelineMutations = {
    setTimelineAnswer(state, data) {
        state.timelineData.answer = data.answer;
    },
    eraseTimelineAnswer(state) {
        state.timelineData.answer = [];
    }
}

const timelineResults = ({ commit, state, rootState }, data) => {
    commit("timelineSetResults", data.val())
}

export { timelineData, timelineResults, timelineMutations }
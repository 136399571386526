<template>
    <div class="flex flex-col items-center justify-center my-auto h-screen">
        <h1 class="font-title text-orange-intense" v-if="$route.params.url == '0'">Create new event</h1>
        <h1 class="font-title text-orange-intense" v-else>Update event {{ eventData.name }}</h1>
        <div class="flex flex-col w-3/12 justify-center items-center gap-6">
            <div class="flex flex-col gap-4 w-full">
                <label class="w-3/12 font-title text-orange-intense" :for="event.name">Name </label>
                <input class="p-2 text-xl rounded-xl" type="text" v-model="event.name" />
            </div>
            <div class="flex flex-col gap-4 w-full">
                <label class="w-3/12 font-title text-orange-intense" :for="event.date_time">Date </label>
                <input class="p-2 text-xl rounded-xl" type="datetime-local" v-model="event.date_time" />
            </div>
            <div class="flex flex-col gap-4 w-full">
                <label class="w-3/12 font-title text-orange-intense" :for="event.language">Language </label>
                <select class="p-2 text-xl rounded-xl" v-model="event.language">
                    <option
                        v-for="languageElement in languageSelect"
                        :key="languageElement"
                        :value="languageElement.key"
                    >
                        {{ languageElement.name }}
                    </option>
                </select>
            </div>
            <div class="flex flex-col gap-4 w-full">
                <label class="w-3/12 font-title text-orange-intense" :for="event.theme_id">Theme </label>
                <select class="p-2 text-xl rounded-xl" v-model="event.theme_id">
                    <option v-for="themeElement in themeSelect" :key="themeElement" :value="themeElement.key">
                        {{ themeElement.name }}
                    </option>
                </select>
            </div>
            <div class="flex flex-row gap-2 items-center w-full accent-orange-intense">
                <input type="checkbox" v-model="event.real_event" :checked="event.real_event" />
                <label class="w-full font-title text-orange-intense" :for="event.real_event">Real Event</label>
            </div>
            <div class="flex flex-row w-full items-center justify-center gap-6">
                <button
                    class="btn-outlined hover:bg-orange-intense hover:border-white w-4/12"
                    @click="$router.push({ name: 'dashboardNOurl' })"
                >
                    Back
                </button>
                <button class="btn w-4/12 hover:border-white" @click="saveEvent">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";

export default {
    props: {
        data: { type: Object, default: {} },
        url: { type: String, default: "" },
    },
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const languageSelect = ref([
            { name: "es", key: "es" },
            { name: "en", key: "en" },
            //{ name: "ca", key: "ca" },
        ]);
        const themeSelect = ref([]);
        const eventData = ref({});
        const themeData = ref([]);

        const getEventData = async () => {
            eventData.value = await axiosToken({
                method: "get",
                url: "event/event/" + route.params.url,
                authorization: true,
            });
            eventData.value = eventData.value.data;
        };

        const event = ref({
            name: "",
            date_time: new Date().toISOString().substring(0, 16),
            language: "",
            theme_id: 0,
            real_event: 0,
        });

        onMounted(async () => {
            if (route.params.url != "0") {
                await getEventData();
                event.value.name = eventData.value.name;
                event.value.date_time = new Date(eventData.value.date_time).toISOString().substring(0, 16);
                event.value.language = eventData.value.language;
                event.value.theme_id = eventData.value.theme.id;
                event.value.real_event = eventData.value.real_event;
            }
            getThemeSelect();
        });

        const getThemeSelect = async () => {
            await getThemeData();
            themeData.value.forEach((theme) => {
                themeSelect.value.push({ key: theme.id, name: theme.name });
            });
        };

        const getThemeData = async () => {
            themeData.value = await axiosToken({
                method: "get",
                url: "event/theme",
                authorization: true,
            });

            themeData.value = themeData.value.data;
        };

        const saveEvent = () => {
            console.log("saveEvent");
            axiosToken({
                method: "post",
                url: "event/event",
                body: {
                    event_data: {
                        name: event.value.name,
                        language: event.value.language,
                        date_time: event.value.date_time,
                        theme: themeData.value.find((theme) => theme.id == event.value.theme_id),
                        real_event: event.value.real_event,
                    },
                    url: route.params.url,
                    theme_id: event.value.theme_id,
                },
                authorization: true,
            }).then((response) => {
                console.log(response);
                if (response.status == 201) {
                    router.push({ name: "dashboardNOurl" });
                }
            });
        };

        return { languageSelect, themeSelect, event, eventData, saveEvent };
    },
};
</script>

<style scoped>
input[type="checkbox"] {
    width: 30px; /*Desired width*/
    height: 30px; /*Desired height*/
    cursor: pointer;
}
input[type="checkbox"]:checked {
    color: white;
}
</style>

<template>
    <div class="flex flex-col main-background h-screen items-center justify-center">
        <img class="h-60 mb-16" src="../assets/images/Logo.png" alt="" />
        <div class="dashboard-card p-12 flex flex-col justify-center items-center">
            <p class="flex flex-col w-full text-center mb-1">Email / UserName</p>
            <input class="flex flex-col w-full dashboard-input" type="text" v-model="user.email" />
            <p class="text-center mb-1 w-full">Password</p>
            <input class="dashboard-input w-full" type="password" v-model="user.password" />
            <button v-on:click="login()" class="dashboard-btn mt-12 p-2">Login</button>
        </div>
    </div>
</template>

<script>
const components = ["Scoreboard", "Quiz", "FairPrice", "Timeline"];

import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const store = useStore();
        const user = ref({
            email: "",
            password: "",
        });
        const login = () => {
            store.dispatch("login", user.value);
        };
        return { user, login };
    },
};
</script>

<style scoped>
.main-background {
    background: linear-gradient(to bottom, #132457, #252b3b);
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

<template>
    <div class="flex flex-col text-center relative">
        <div class="flex flex-row items-center justify-center w-full">
            <h1 class="flex flex-col text-orange-intense font-title text-center w-full">
                {{ $t("game.question") }}&nbsp;{{ $store.state.storeEventData.counter_game + 1 }}/{{ elements.length }}
            </h1>
        </div>
        <div class="flex flex-row items-center h-full justify-center">
            <div class="flex flex-col h-full w-2/12 items-center relative">
                <div id="vertical-line" class="flex flex-row w-2 h-full vertical-line absolute"></div>
                <ul class="flex flex-col items-center list-group-item justify-center z-10 w-8">
                    <li
                        v-for="(element, index) in elementsStatic[$store.state.storeEventData.counter_game].timeline"
                        :key="element"
                        class="items-center justify-items-center w-full"
                    >
                        <div
                            :class="'flex flex-col border-2 rounded-md w-full mb-2 relative justify-center items-center'"
                        >
                            <div
                                :class="
                                    'border-' +
                                    colors[index] +
                                    ' flex flex-col items-center justify-center absolute mt-3 h-4 w-4 bg-white rounded-full border-solid border-4'
                                "
                            >
                                <span :class="'border-l-' + colors[index] + ' z-20 ml-16 arrow-right'"></span>
                            </div>
                            <div
                                class="flex flex-col items-center text-center w-full bg-silver-light opacity-0 relative mb-1"
                            >
                                <img class="object-fill w-full rounded-xl h-24" :src="element.image" alt="image" />
                                <div
                                    class="div-title flex flex-row gap-4 items-center text-xl w-full justify-center absolute -bottom-1 rounded-b-xl"
                                >
                                    <div class="font-title-btn text-sm">.</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <template
                v-if="
                    $store.state.storeGamesData.timelineData.answer.length != 0 &&
                    $store.state.storeEventData.ingame_state == 1
                "
            >
                <Draggable
                    :list="$store.state.storeGamesData.timelineData.answer"
                    item-key="id"
                    ghost-class="ghost"
                    class="w-10/12 list-group"
                    id="draggable-items"
                    @change="finish"
                >
                    <template #item="{ element }">
                        <div class="flex flex-col items-center list-group-item">
                            <TimelineCard :element="element"></TimelineCard>
                        </div>
                    </template>
                </Draggable>
            </template>
            <div
                class="flex flex-col items-center w-10/12"
                v-if="
                    $store.state.storeGamesData.timelineData.answer.length != 0 &&
                    $store.state.storeEventData.ingame_state >= 2
                "
            >
                <div
                    class="flex flex-col w-full relative items-center font-title justify-center"
                    v-for="(element, index) in $store.state.storeGamesData.timelineData.answer"
                    :key="element"
                >
                    <span
                        class="flex flex-col absolute z-10 text-4xl rounded-full h-16 w-16 mb-8 items-center justify-center text-quiz-green border-quiz-green"
                        v-if="
                            $store.state.storeEventData.game_data.points.filter(
                                (e) => e.team_id == $store.state.storeEventData.playerId.team
                            )[0].correct_order[index] == 2
                        "
                    >
                        <span>+10</span>
                    </span>
                    <span
                        class="flex flex-col absolute z-10 text-4xl rounded-full h-16 w-16 mb-8 items-center justify-center text-quiz-yellow border-quiz-yellow"
                        v-if="
                            $store.state.storeEventData.game_data.points.filter(
                                (e) => e.team_id == $store.state.storeEventData.playerId.team
                            )[0].correct_order[index] == 1
                        "
                    >
                        <span>+5</span>
                    </span>
                    <span
                        class="flex flex-col absolute z-10 text-4xl rounded-full h-16 w-16 mb-8 items-center justify-center text-quiz-red border-quiz-red"
                        v-if="
                            $store.state.storeEventData.game_data.points.filter(
                                (e) => e.team_id == $store.state.storeEventData.playerId.team
                            )[0].correct_order[index] == 0
                        "
                    >
                        <span>0</span>
                    </span>

                    <TimelineCard class="opacity-20" :element="element"></TimelineCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Draggable from "vuedraggable";
import TimelineCard from "@/components/cards/TimelineCard.vue";

export default {
    props: {},
    components: { Draggable, TimelineCard },
    beforeCreate() {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const elements = ref(store.state.storeEventData.shared_content);
        const elementsStatic = ref(store.state.storeEventData.shared_content);
        const colors = ["red-500", "red-400", "red-300", "orange-400", "orange-300", "yellow-400"];

        const saveOrder = () => {
            console.log(saveOrder);
            //store.commit("setTimeline", {  });
        };

        const finish = (item) => {
            store.dispatch("sendTeamData", store.state.storeGamesData.timelineData.answer);
        };

        /*onMounted(() => {
            const vertical_line = document.getElementById("vertical-line");
            const draggable_items = document.getElementById("draggable-items");

            setTimeout(() => {
                vertical_line.offsetHeight = draggable_items.offsetHeight;
            }, 100);
        });*/

        return { elements, saveOrder, elementsStatic, colors, finish };
    },
};
</script>

<style lang="scss" scoped>
body {
    height: 100%;
    min-height: 100%;
}
.vertical-line {
    position: relative;
    background: linear-gradient(180deg, #ff5f6d 17.76%, #ffbd63 96.21%);
    border-radius: 10px;
}
ul {
    list-style-type: none;
    padding: 0;
}
.arrow-right {
    width: 0px;
    height: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid;
}
.font-title {
    font-size: 25px;
}
</style>

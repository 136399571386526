const storeUser = {
    state() {

        return {
            name: '',
            mode: '',
        }
    },

    mutations: {
        setName(state, data) {
            console.log(data)
            state.name = data.value;
        },
        loadStoreUserData(state) {
            state.name = localStorage.name ? localStorage.name : '';
            state.mode = localStorage.mode ? localStorage.mode : '';
        }
    },
    actions: {

    }


}

export default storeUser
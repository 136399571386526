<template>
    <span class="flex flex-col items-center justify-start w-full">
        <h1 class="font-title text-orange-intense">Game Content Header</h1>
        <div class="flex flex-row w-11/12 justify-center items-start gap-12">
            <div class="flex flex-col gap-6 items-center w-5/12">
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense" :for="dataContent.name_id">Name Id</label>
                    <input class="p-2 text-xl rounded-xl" type="text" v-model="dataContent.name_id" />
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense" :for="dataContent.name">Name </label>
                    <input class="p-2 text-xl rounded-xl" type="text" v-model="dataContent.name" />
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense" :for="dataContent.language">Language </label>
                    <select class="p-2 text-xl rounded-xl" v-model="dataContent.language">
                        <option
                            v-for="languageElement in languageSelect"
                            :key="languageElement"
                            :value="languageElement.key"
                        >
                            {{ languageElement.name }}
                        </option>
                    </select>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense" :for="dataContent.game">Game</label>
                    <select
                        class="p-2 text-xl rounded-xl"
                        v-model="dataContent.game"
                        :disabled="
                            JSON.stringify(dataContent.data) !== '[]' && JSON.stringify(dataContent.data) !== '{}'
                        "
                        @change="$store.commit('setContentGame', dataContent.game)"
                    >
                        <option v-for="gameElement in gameSelect" :key="gameElement" :value="gameElement.key">
                            {{ gameElement.name }}
                        </option>
                    </select>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <label class="w-full font-title text-orange-intense" :for="dataContent.category">Category</label>
                    <select class="p-2 text-xl rounded-xl" v-model="dataContent.category">
                        <option
                            v-for="categoryElement in categorySelect"
                            :key="categoryElement"
                            :value="categoryElement.key"
                        >
                            {{ categoryElement.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="flex flex-col gap-6 items-center justify-center w-5/12">
                <label class="w-full font-title text-orange-intense" :for="dataContent.icon">Icon</label>
                <div class="flex flex-row w-full items-center justify-start gap-6">
                    <input
                        @change="loadImage($event, 'icon')"
                        type="file"
                        id="icon"
                        name="icon"
                        accept="image/png, image/jpeg, image/jpg"
                    />
                    <div class="flex flex-col h-32">
                        <img
                            v-if="dataContent.icon"
                            class="border-dashed border-2 border-white cursor-pointer"
                            id="icon_img"
                            :src="dataContent.icon"
                            alt=""
                            @click="inputClick('icon')"
                        />
                        <div
                            v-else
                            class="border-dashed border-2 border-white w-32 h-32 cursor-pointer"
                            @click="inputClick('icon')"
                        ></div>
                    </div>
                </div>
                <!--<label class="w-full font-title text-orange-intense" :for="dataContent.image">Image</label>
                <div class="flex flex-row w-full items-center justify-start gap-6">
                    <input
                        @change="loadImage($event, 'image')"
                        type="file"
                        id="image"
                        name="image"
                        accept="image/png, image/jpeg, image/jpg"
                    />
                    <img
                        v-if="dataContent.image"
                        class="border-dashed border-2 border-white h-32 w-3/12 cursor-pointer"
                        id="image_img"
                        :src="dataContent.image"
                        alt=""
                        @click="inputClick('image')"
                    />
                    <div
                        v-else
                        class="border-dashed border-2 border-white h-32 w-3/12 cursor-pointer"
                        @click="inputClick('image')"
                    ></div>
                </div> -->
                <label class="w-full font-title text-orange-intense" :for="dataContent.background">Background</label>
                <div class="flex flex-row w-full items-center justify-start gap-6">
                    <input
                        @change="loadImage($event, 'background')"
                        type="file"
                        id="background"
                        name="background"
                        accept="image/png, image/jpeg, image/jpg"
                    />
                    <img
                        v-if="dataContent.background"
                        class="border-dashed border-2 border-white h-32 w-6/12 cursor-pointer"
                        id="background_img"
                        :src="dataContent.background"
                        alt=""
                        @click="inputClick('background')"
                    />
                    <div
                        v-else
                        class="border-dashed border-2 border-white h-32 w-6/12 cursor-pointer"
                        @click="inputClick('background')"
                    ></div>
                </div>
                <div class="flex flex-row gap-6 w-full">
                    <div class="flex flex-col gap-4 w-full">
                        <label class="w-full font-title text-orange-intense" :for="dataContent.description"
                            >Description</label
                        >
                        <textarea
                            class="rounded-3xl p-4"
                            v-model="dataContent.description"
                            id="description"
                            cols="34"
                            rows="2"
                        />
                    </div>
                </div>
                <div class="flex flex-row gap-2 relative w-full items-center accent-orange-intense">
                    <div class="flex flex-row gap-2 items-center w-6/12">
                        <input
                            type="checkbox"
                            v-model="dataContent.selected_default"
                            :checked="dataContent.selected_default"
                        />
                        <label class="w-full font-title text-lg text-orange-intense" :for="dataContent.selected_default"
                            >Default game roulette</label
                        >
                    </div>
                    <div class="flex flex-row gap-2 items-center w-6/12">
                        <input type="checkbox" v-model="dataContent.active" :checked="dataContent.active" />
                        <label class="w-full font-title text-lg text-orange-intense" :for="dataContent.active"
                            >Active</label
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full items-center justify-center gap-6 mt-8 absolute bottom-16">
            <button
                class="flex flex-row items-center justify-center btn-outlined hover:border-white w-4/12 xl:w-2/12"
                @click="$router.push({ name: 'dashboardNOurl' })"
            >
                <inline-svg
                    :src="require('@/assets/icons/left-long-solid.svg')"
                    class="w-6 h-6 mr-2 -z-10 fill-orange-intense"
                />
                Back
            </button>
            <button
                class="flex flex-row items-center justify-center btn w-4/12 xl:w-2/12 hover:border-white"
                @click="updateGameContent()"
            >
                Save
                <inline-svg :src="require('@/assets/icons/floppy-disk-solid.svg')" class="w-6 h-6 fill-white ml-2" />
            </button>
            <button
                class="flex flex-row items-center justify-center btn w-4/12 xl:w-2/12 hover:border-white bg-quiz-green"
                @click="updateGameContent(false), $emit('setCollapse')"
                :disabled="$store.state.storeContentData.dataContent.name_id.length == 0"
            >
                Add Content
                <inline-svg :src="require('@/assets/icons/plus-solid.svg')" class="w-6 h-6 fill-white ml-2" />
            </button>
        </div>
    </span>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { axiosToken } from "@/libs/http";
import { notify } from "notiwind";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const languageSelect = ref([
            { name: "es", key: "es" },
            { name: "en", key: "en" },
            //{ name: "ca", key: "ca" },
        ]);
        const pic = ref(require("@/assets/images/default.png"));
        const gameSelect = ref([]);
        const gameData = ref(null);
        const categorySelect = ref([]);
        const categoryData = ref(null);
        const dataContent = ref([]);

        const getCategorySelect = async () => {
            await getCategoryData();
            categoryData.value.forEach((category) => {
                categorySelect.value.push({
                    key: category.id,
                    name: category.name,
                });
            });
        };

        const getCategoryData = async () => {
            categoryData.value = await axiosToken({
                method: "get",
                url: "gamecontent/category",
                authorization: true,
            });

            categoryData.value = categoryData.value.data;
        };

        const getGameSelect = async () => {
            await getGameData();
            gameData.value.forEach((game) => {
                gameSelect.value.push({ key: game.id, name: game.name_show ? game.name_show : game.name_id });
            });
        };

        const getGameData = async () => {
            gameData.value = await axiosToken({
                method: "get",
                url: "gamecontent/game",
                authorization: true,
            });

            gameData.value = gameData.value.data;
        };

        const getGameContentData = () => {
            store.commit("loadContentData", route.params.id);
            dataContent.value = store.state.storeContentData.dataContent;
        };

        const createFormToSave = () => {
            console.log("createFormToSave");
            console.log(dataContent.value);
            const contentToSave = new FormData();
            contentToSave.append("name", dataContent.value.name);
            contentToSave.append("name_id", dataContent.value.name_id);
            contentToSave.append("language", dataContent.value.language);
            contentToSave.append("description", dataContent.value.description);
            contentToSave.append("game", dataContent.value.game);
            contentToSave.append("category", dataContent.value.category);
            contentToSave.append("selected_default", dataContent.value.selected_default);
            contentToSave.append("active", dataContent.value.active);
            if (
                typeof dataContent.value.icon === "object" &&
                dataContent.value.icon != null &&
                dataContent.value.icon != ""
            ) {
                contentToSave.append("icon", dataContent.value.icon);
            }
            if (
                typeof dataContent.value.image === "object" &&
                dataContent.value.image != null &&
                dataContent.value.image != ""
            ) {
                contentToSave.append("image", dataContent.value.image);
            }
            if (
                typeof dataContent.value.background === "object" &&
                dataContent.value.background != null &&
                dataContent.value.background != ""
            ) {
                contentToSave.append("background", dataContent.value.background);
            }
            console.log(contentToSave);

            return contentToSave;
        };

        const updateGameContent = async (showAlert = true) => {
            await axiosToken({
                method: "put",
                url: "gamecontent/content_game/" + route.params.id,
                body: createFormToSave(),
                authorization: true,
            }).then((response) => {
                if (response.data) {
                    store.commit("loadContentData", response.data.id);
                    if (showAlert) {
                        notify(
                            {
                                group: "success",
                                title: "Success",
                                text: "Content updated",
                            },
                            4000
                        );
                    }
                } else {
                    if (showAlert) {
                        notify(
                            {
                                group: "error",
                                title: "Error",
                                text: "There was a updating the content!!",
                            },
                            4000
                        );
                    }
                }
                console.log(response);
            });
        };

        const setCheckbox = (actual) => {
            dataContent.value.selected_default = !actual;
            console.log(dataContent.value.selected_default);
        };

        const loadImage = async (event, type) => {
            var files = event.target.files;
            if (type == "icon") {
                dataContent.value.icon = files[0];
            } else if (type == "image") {
                dataContent.value.image = files[0];
            } else if (type == "background") {
                dataContent.value.background = files[0];
            }

            console.log(dataContent.value);

            if (FileReader && files && files.length) {
                var fr = new FileReader();
                fr.onload = function () {
                    document.getElementById(type + "_img").src = fr.result;
                };
                fr.readAsDataURL(files[0]);
            } else {
            }
        };

        const inputClick = (input_id) => {
            document.getElementById(input_id).click();
        };

        onMounted(() => {
            getGameContentData();
            getGameSelect();
            getCategorySelect();
        });

        return {
            dataContent,
            languageSelect,
            gameSelect,
            updateGameContent,
            setCheckbox,
            inputClick,
            loadImage,
            categorySelect,
        };
    },
};
</script>

<style scoped>
input[type="checkbox"] {
    width: 30px; /*Desired width*/
    height: 30px; /*Desired height*/
    cursor: pointer;
}
input[type="checkbox"]:checked {
    color: white;
}
img {
    image-rendering: pixelated;
}
.font-title {
    font-size: 28px;
}
</style>

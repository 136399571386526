<template>
    <div v-if="!survey" class="flex flex-col gap-4 mx-4 items-center justify-center h-screen">
        <h1 class="mb-0 mt-2 font-title custom-color">{{ $t("survey.rating_game") }}</h1>
        <StarRating name="game" />
        <h1 class="mb-0 mt-2 font-title custom-color">{{ $t("survey.rating_fun") }}</h1>
        <StarRating name="fun" />
        <h1 class="mb-0 mt-2 font-title custom-color">{{ $t("survey.rating_gm") }}</h1>
        <StarRating name="gm" />
        <h1 class="mb-0 mt-2 font-title custom-color">{{ $t("survey.repeat") }}</h1>
        <div class="flex flex-row w-full items-center justify-center gap-4">
            <button :class="repeat == 1 ? 'enum-marked' : 'enum'" class="w-3/12" @click="setRepeat(1)">
                {{ $t("survey.yes") }}
            </button>
            <button :class="repeat == 2 ? 'enum-marked' : 'enum'" class="w-3/12" @click="setRepeat(2)">
                {{ $t("survey.no") }}
            </button>
        </div>
        <h1 class="mb-0 mt-2 font-title custom-color">{{ $t("survey.comments") }}</h1>
        <textarea
            class="rounded-3xl p-4"
            v-model="comments"
            @change="setComments"
            id="comments"
            cols="34"
            rows="8"
        ></textarea>
        <button class="btn w-6/12 mb-6" @click="sendSurvey">{{ $t("survey.send") }}</button>
    </div>
    <div
        v-else
        class="flex flex-col gap-4 mx-4 items-center text-center text-orange-intense font-title justify-center h-screen"
    >
        <h3>{{ $t("survey.completed") }}</h3>
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import StarRating from "@/components/StarRating.vue";
import { axiosToken } from "@/libs/http.js";
import { notify } from "notiwind";

export default {
    props: {},
    components: { StarRating },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const repeat = ref(0);
        const comments = ref("");
        const survey = localStorage.survey ? ref(localStorage.survey) : ref(false);

        const setRepeat = (option) => {
            repeat.value = option;
            store.commit("setRating", { name: "repeat", value: option });
        };

        const setComments = () => {
            store.commit("setRating", { name: "comments", value: comments.value });
        };

        const sendSurvey = () => {
            axiosToken({
                method: "post",
                url: "event/survey",
                body: {
                    event: store.state.storeEventData.id,
                    rating_game: store.state.storeEventData.rating.game,
                    rating_fun: store.state.storeEventData.rating.fun,
                    rating_gm: store.state.storeEventData.rating.gm,
                    repeat: repeat.value,
                    comments: comments.value,
                    player: store.state.storeEventData.event_player[0].id,
                },
            }).then((response) => {
                console.log(response.data);
                if (response.data) {
                    notify(
                        {
                            group: "success",
                            title: "Success",
                            text: "Thank you for your review!!",
                        },
                        4000
                    );
                    localStorage.survey = true;
                    survey.value = true;
                } else {
                    notify(
                        {
                            group: "error",
                            title: "Error",
                            text: "There was a problem sending your review!!",
                        },
                        4000
                    );
                }
            });
        };

        return { setRepeat, repeat, comments, sendSurvey, setComments, survey };
    },
};
</script>

<style scoped>
.font-title {
    font-size: 26px;
}
</style>

<template>
    <div class="container flex flex-col items-center justify-center h-screen gap-6 relative">
        <div class="flex flex-col gap-4 w-1/12 absolute top-4 left-8">
            <select class="p-2 text-xl rounded-xl" v-model="language" @change="updateLanguage">
                <option v-for="languageElement in languageSelect" :key="languageElement" :value="languageElement.key">
                    {{ languageElement.name }}
                </option>
            </select>
        </div>
        <span
            v-if="loaded && $store.state.storeEventData.websocketTest"
            class="flex flex-col gap-6 w-10/12 text-quiz-green font-title items-center justify-center text-center"
        >
            <div>{{ $t("test.title_ok") }}</div>
            <div>{{ $t("test.subtitle_ok") }}</div>
        </span>
        <span v-else class="w-10/12 text-quiz-red font-title items-center justify-center text-center">
            <div>{{ $t("test.title_error") }}</div>
            <div>{{ $t("test.subtitle_error") }}</div>
        </span>
        <img class="h-20" :src="imgUrl" alt="" v-if="loaded && $store.state.storeEventData.websocketTest" />
        <img class="h-20" src="@/assets/icons/icons8-cancel-48.png" alt="" v-else />
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import StarRating from "@/components/StarRating.vue";
import { axiosToken } from "@/libs/http.js";
import { notify } from "notiwind";
import { startSocketTest } from "@/libs/ws";
import { useI18n } from "vue-i18n";

export default {
    props: {},
    components: { StarRating },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const language = ref((navigator.language || navigator.userLanguage).slice(0, 2) != "es" ? "en" : "es");
        const languageSelect = ref([
            { name: "es", key: "es" },
            { name: "en", key: "en" },
            { name: "ca", key: "ca" },
        ]);
        const { t, locale, fallbackLocale, messages } = useI18n();
        const loaded = ref(false);
        const imgUrl = ref("https://staticdjango.fra1.cdn.digitaloceanspaces.com/icons8-ok-48.png");

        function imageExists(image_url) {
            var http = new XMLHttpRequest();

            http.open("HEAD", image_url, false);
            http.send();

            loaded.value = http.status == 200;
        }

        const updateLanguage = () => {
            locale.value = language.value;
        };

        onMounted(() => {
            updateLanguage();
            startSocketTest();
            imageExists(imgUrl.value);
        });

        return { imageExists, loaded, imgUrl, languageSelect, language, updateLanguage };
    },
};
</script>

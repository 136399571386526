<template>
    <Test />
</template>

<script>
// @ is an alias to /src

import Test from "@/components/Test.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";

export default {
    name: "TestView",
    components: {
        Test,
    },
    setup(props) {
        const router = useRouter();

        onMounted(() => {});

        return {};
    },
};
</script>

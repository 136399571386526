<template>
    <div class="px-8 overflow-auto text-orange-intense">
        <h1 class="capitalize text-center">{{ $store.state.storeEventData.current_game_content.name }}</h1>
        <hr class="border-orange-intense" />
        <div class="flex flex-col gap-4 text-justify w-full">
            {{ $store.state.storeEventData.current_game_content.description }}
        </div>
        <h2 class="text-center">How it works</h2>
        <div class="flex flex-col gap-4 text-justify w-full">
            {{ $t($store.state.storeEventData.current_game.name_id + ".description") }}
            {{ $store.state.storeEventData.current_game.name_id }}
        </div>
        <h4 class="mt-16 text-center text-white">Waiting for the game to start...</h4>
    </div>
</template>

<script>
import { watch, ref, onMounted, onUnmounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
    props: {},
    components: {},
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();

        return {};
    },
};
</script>

<style scoped></style>

<template>
    <div class="flex flex-row main-background min-h-screen">
        <div class="flex flex-col w-1/4 min-h-screen">
            <LeftBar />
        </div>
        <div class="flex flex-col w-2/4">
            <TeamsCard />
        </div>
        <div class="flex flex-col w-1/4"><RightBar /></div>
    </div>
</template>

<script>
const components = ["Scoreboard", "Quiz", "FairPrice", "Timeline"];

import { ref, onMounted, onUnmounted, defineAsyncComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import LeftBar from "@/components/dashboard/LeftBar.vue";
import TeamsCard from "@/components/dashboard/TeamsCard.vue";
import RightBar from "@/components/dashboard/RightBar.vue";

export default {
    props: {},
    components: { LeftBar, TeamsCard, RightBar },
    setup(props) {
        return {};
    },
};
</script>

<style scoped>
.main-background {
    background: linear-gradient(to bottom, #132457, #252b3b);
    background-repeat: no-repeat;
    background-size: cover;
}
</style>

import { quizData, quizResults, quizMutations } from './games/quiz.js'
import { fairPriceData, fairPriceResults, fairPriceMutations } from './games/fairPrice.js'
import { timelineData, timelineResults, timelineMutations } from './games/timeline.js'

const url = window.location.pathname

const storeGame = {
    state() {

        // set all data from all phases
        return {
            quizData,
            fairPriceData,
            timelineData,
        }
    },

    mutations: {
        ...quizMutations,
        ...fairPriceMutations,
        ...timelineMutations,
    },
    actions: {
        sendTeamData({ commit, rootState }, data) {
            console.log(data)
            rootState.storeEventData.websocketTeam.send(
                JSON.stringify({
                    "action": "answer",
                    "answer": {
                        "answer": data
                    }
                })
            )
        },
        eraseAnswers({ commit, rootState }) {
            commit('eraseQuizAnswer');
            commit('eraseFairPriceAnswer');
            //commit('eraseTimelineAnswer');
        }
    }


}

export default storeGame
<template>
    <div class="w-full flex flex-col bottom-0">
        <h3 class="text-center mb-0">{{ $t("general.reactions") }}</h3>
        <hr class="w-11/12" />
        <div class="grid grid-cols-4 justify-items-center overflow-auto max-height-50">
            <div
                v-for="reaction in $store.state.storeEventData.reactions"
                :key="reaction"
                class="cursor-pointer active:bg-transparent max-h-screen"
                v-on:click="sendReaction(reaction.id)"
            >
                <img class="reaction" :src="reaction.image" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { axiosToken } from "@/libs/http";
import { useStore } from "vuex";
export default {
    setup() {
        const reactions = ref([]);
        const store = useStore();
        let sendEmoji = true;
        onMounted(() => {});

        const sendReaction = async (id) => {
            if (sendEmoji == false) {
                return;
            }
            sendEmoji = false;
            setTimeout(function () {
                sendEmoji = true;
            }, 1000);

            store.state.storeEventData.websocket.send(
                JSON.stringify({
                    action: "reaction",
                    reaction: id,
                })
            );
        };

        return {
            reactions,
            sendReaction,
        };
    },
};
</script>

<style lang="scss" scoped>
div {
    outline: 0;
}

.max-height-100 {
    max-height: 110px;
}
</style>

const es = {
    general: {
        reactions: "Reacciones",
        player: "Jugador:",
        team: "Equipo:",
    },
    login: {
        welcome: "Bienvenido/a",
        name: "Escribe tu nombre",
        location: "Selecciona tu ubicación",
        ready: "Listo",
        waiting: "ESPERANDO A LOS JUGADORES",
        players: "Jugadores"
    },
    team: {
        update: "Aplicar cambios",
        avatar: "Avatar",
    },
    ranking: {
        title: "Ranking general",
        title_partial: "Ranking del reto",
    },
    game: {
        question: "Pregunta",
    },
    timeline: {
        description: "Ordena las fichas correctamente. Conseguirás puntos según cuanto que te acerques al orden correcto.",
        guessed: "Aciertos",
    },
    fair_price: {
        description: "Adivina el número exacto para las siguientes preguntas. Conseguirás más puntos cuanto más te acerques.",
    },
    quiz: {
        description: "Responde estas preguntas de distintos temas. Solo hay una respuesta correcta.",
    },
    survey: {
        title: "Responde la encuesta",
        subtitle: "Ayúdanos a mejorar la experiencia dejando tu feedback.",
        rating_game: "Juego",
        rating_fun: "Diversión",
        rating_gm: "Presentador/a",
        repeat: "¿Repetirías la experiencia?",
        yes: "Si",
        no: "No",
        comments: "Comentarios",
        send: "Enviar",
        completed: "Encuesta enviada, muchas gracias por tu opinión",
    },
    test: {
        title_ok: "TEST COMPLETADO",
        subtitle_ok: "¡Enhorabuena! Si puedes leer este texto significa que la red de tu empresa es compatible con las competiciones de escape room online. Muchas gracias por hacer el test.",
        title_error: "TEST FALLIDO",
        subtitle_error: "Lo sentimos, la plataforma de juego no es 100% compatible con la red de tu empresa. Ponte en contacto con el organizador de la actividad lo antes posible.",
    }
}
module.exports = {
    es
}
import { axiosToken } from "@/libs/http";
import store from ".";
import axios from 'axios'
import { notify } from "notiwind";
import router from "@/router";
const API_URL = process.env.VUE_APP_API_URL


const user = localStorage.getItem('user');
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };


const storeEvent = {
    state() {

        return {
            ...initialState,
        }
    },

    mutations: {
        loginSuccess(state, user) {
            console.log(user)
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },

    },
    actions: {
        login({ commit }, user) {
            commit('loginClean')
            axios
                .post(API_URL + 'api-token-auth/', {
                    username: user.email,
                    password: user.password
                })
                .then(response => {
                    console.log(response)
                    if (response.data.token) {
                        console.log("save")
                        localStorage.setItem('user', response.data.token)
                    }
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const redirect = urlParams.get('redirect')

                    commit('loginSuccess', response.data.token)
                    if (redirect) {
                        router.push(redirect)

                    }
                    else {
                        router.push("/dashboard")

                    }

                    return Promise.resolve(response.data.key)
                }).catch((e) => {
                    console.log("in", e)
                    notify(
                        {
                            group: "error",
                            title: "Error",
                            text: "Wrong user / password",
                        },
                        4000
                    );
                    return commit('loginFailure')

                })
        },
    }


}

export default storeEvent
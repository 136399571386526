import { axiosToken } from "@/libs/http";

const storeContent = {
    state() {

        return {
            dataContent: {
                content_images: "",
                name: "",
                name_id: "",
                language: "en",
                description: "",
                game: 1,
                data: [],
                selected_default: false,
                icon: "",
                image: "",
                background: "",
                validated: false,
                active: true,
                category: 1,
            }
        }
    },

    mutations: {
        setContentGame(state, data) {
            state.dataContent.game = data;
        },
        setDataContent(state, data) {
            state.dataContent = data;
        },
        async loadContentData(state, data) {

            if (data) {
                await axiosToken({
                    method: "get",
                    url: "gamecontent/content_game/" + data,
                    authorization: true,
                }).then((response) => {
                    console.log("response")
                    console.log(response.data)
                    response = response.data;

                    state.dataContent.content_images = response.content_images;
                    state.dataContent.name = response.name;
                    state.dataContent.name_id = response.name_id;
                    state.dataContent.language = response.language;
                    state.dataContent.description = response.description;
                    state.dataContent.game = response.game;
                    state.dataContent.data = response.data;
                    state.dataContent.selected_default = response.selected_default;
                    state.dataContent.icon = response.icon;
                    state.dataContent.image = response.image;
                    state.dataContent.background = response.background;
                    state.dataContent.validated = response.validated;
                    state.dataContent.active = response.active;
                });

            }
        },
        clearDataContent(state) {
            state.dataContent = {
                name: "",
                name_id: "",
                language: "en",
                description: "",
                game: 1,
                data: [],
                selected_default: false,
                icon: "",
                image: "",
                background: "",
                active: true,
                category: 1,
            }
        }
    },
    actions: {

    }


}

export default storeContent